import { Radio, TimePicker } from "antd";
import moment from "moment";
import * as React from "react";
import { Sub3 } from "../../../../../components/Text";
import { TrafficBoxTimeSlot } from "../../../../../graphql/types/trafficBox";
import TrafficTimeSlotParam from "./TrafficTimeSlotParam";
import "./timeslot.scss";

const TrafficTimeSlotEdit: React.FC<{
  lockChange?: boolean;
  index: number;
  defaultValue: [TrafficBoxTimeSlot, TrafficBoxTimeSlot[]];
  onChange?: (newTimeSlot: TrafficBoxTimeSlot) => void;
  className?: string;
}> = ({ index, onChange, defaultValue, className, lockChange = false }) => {
  const [editTimeSlot, setEditTimeSlot] = React.useState<TrafficBoxTimeSlot>(
    defaultValue[0]
  );
  const params = React.useMemo(() => {
    return {
      start: moment(
        `${editTimeSlot.hour.toString().padStart(2, "0")}:${editTimeSlot.minute
          .toString()
          .padStart(2, "0")}`,
        "HH:mm"
      ),
      end:
        defaultValue[1].length === index + 1
          ? moment("23:59", "HH:mm")
          : index === 0
            ? moment(
              `${defaultValue[1][index].hour
                .toString()
                .padStart(2, "0")}:${defaultValue[1][index].minute
                  .toString()
                  .padStart(2, "0")}`,
              "HH:mm"
            )
            : moment(
              `${defaultValue[1][index + 1].hour
                .toString()
                .padStart(2, "0")}:${defaultValue[1][index + 1].minute
                  .toString()
                  .padStart(2, "0")}`,
              "HH:mm"
            ),
      green1: editTimeSlot.green1,
      green2: editTimeSlot.green2,
      yellow1: editTimeSlot.yellow1,
      yellow2: editTimeSlot.yellow2,
      clearSessionTime: editTimeSlot.clearSessionTime,
    };
  }, [editTimeSlot, defaultValue, index]);

  React.useEffect(() => {
    if (onChange) {
      onChange(editTimeSlot);
    }
  }, [onChange, editTimeSlot]);
  return (
    <>
      <div
        className={`w-full flex flex-col drop-shadow-[0_0.25rem_1rem_rgba(126, 129, 138, 0.1] ${className}`}
      >
        {lockChange === true ? (
          <div className="w-full h-full absolute top-0 left-0 z-[1000] bg-neutral-D70 opacity-[0.5]"></div>
        ) : null}
        <div className="grow pb-[1rem] flex flex-row justify-between">
          <div className="mr-[1.875rem]">
            <TimePicker
              defaultValue={params.start}
              clearIcon={<></>}
              suffixIcon={<></>}
              format="HH:mm"
              className="w-[74px] font-['Inter']"
              onChange={(v) => {
                if (v) {
                  setEditTimeSlot((p) => {
                    if (p != null) {
                      return { ...p, hour: v.hour(), minute: v.minute() };
                    } else {
                      return p;
                    }
                  });
                }
              }}
            />
            <Sub3 className="inline">{` - ${params.end.format("HH:mm")}`}</Sub3>
          </div>
          <Radio.Group
            onChange={(e) => {
              if (e.target.value === "YELLOW_ONLY") {
                setEditTimeSlot((p) => {
                  if (p != null) {
                    return { ...p, mode: "YELLOW_ONLY" };
                  } else {
                    return p;
                  }
                });
              } else {
                setEditTimeSlot((p) => {
                  if (p != null) {
                    return { ...p, mode: "INITIALIZE" };
                  } else {
                    return p;
                  }
                });
              }
            }}
            defaultValue={
              editTimeSlot?.mode === "YELLOW_ONLY" ? "YELLOW_ONLY" : "AUTO"
            }
          >
            <Radio value="AUTO">Tự động</Radio>
            <Radio value="YELLOW_ONLY">Chớp vàng</Radio>
          </Radio.Group>
        </div>
        {editTimeSlot.mode === "INITIALIZE" ? (
          <div className="grid grid-cols-3 grid-rows-2 flex-wrap gap-x-[0.5rem] gap-y-[0.625rem]">
            <TrafficTimeSlotParam
              editable
              color="GREEN"
              title="Xanh tuyến 1"
              count={params.green1 || 0}
              onChange={(v) => {
                setEditTimeSlot((p) => {
                  if (p != null) {
                    return { ...p, green1: v };
                  } else {
                    return p;
                  }
                });
              }}
            />
            <TrafficTimeSlotParam
              editable
              color="GREEN"
              title="Xanh tuyến 2"
              count={params.green2 || 0}
              onChange={(v) => {
                setEditTimeSlot((p) => {
                  if (p != null) {
                    return { ...p, green2: v };
                  } else {
                    return p;
                  }
                });
              }}
            />
            <TrafficTimeSlotParam
              editable
              color="RED"
              title="Giải tỏa"
              count={params.clearSessionTime || 0}
              onChange={(v) => {
                setEditTimeSlot((p) => {
                  if (p != null) {
                    return { ...p, clearSessionTime: v };
                  } else {
                    return p;
                  }
                });
              }}
            />
            <TrafficTimeSlotParam
              editable
              color="YELLOW"
              title="Vàng tuyến 1"
              count={params.yellow1 || 0}
              onChange={(v) => {
                setEditTimeSlot((p) => {
                  if (p != null) {
                    return { ...p, yellow1: v };
                  } else {
                    return p;
                  }
                });
              }}
            />
            <TrafficTimeSlotParam
              editable
              color="YELLOW"
              title="Vàng tuyến 2"
              count={params.yellow2 || 0}
              onChange={(v) => {
                setEditTimeSlot((p) => {
                  if (p != null) {
                    return { ...p, yellow2: v };
                  } else {
                    return p;
                  }
                });
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TrafficTimeSlotEdit;
