import * as React from 'react';
import { CheckedFillSquare } from '../Icons';
import './input.scss';

const TwCheckbox: React.FunctionComponent<React.InputHTMLAttributes<HTMLInputElement>> = ({ className, ...props }) => {
    return <div className='relative w-[16px] h-[16px]'>
        <input type="checkbox" className={`appearance-none w-[16px] h-[16px] border border-neutral-L70 rounded-[4px] overflow-hidden 
  hover:border-neutral-D70 cursor-pointer z-10 relative  ${className}`} {...props} />
        <div className='check-div hidden w-[16px] h-[16px] rounded-[4px] absolute top-0 left-0 bottom-0 right-0'>
            <CheckedFillSquare />
        </div>
    </div>;
};

export default TwCheckbox;
