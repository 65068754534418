import { Menu } from "antd";
import * as React from "react";

const OptionMenu: React.FC<{ onMenuSelect?: (option: string) => void }> = ({
  onMenuSelect,
}) => {
  return (
    <Menu
      onClick={({ key }) => onMenuSelect && onMenuSelect(key)}
      theme="dark"
      style={{
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "17px",
      }}
      className="!py-[0.5rem] !bg-neutral-D70 !rounded-[0.25rem]"
    >
      <Menu.Item key={"MODIFY"}>Chỉnh sửa</Menu.Item>
      <Menu.Item key={"DELETE"}>Xóa thời đoạn</Menu.Item>
      <Menu.Item key={"ADD"}>Thêm thời đoạn</Menu.Item>
    </Menu>
  );
};

export default OptionMenu;
