import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="158"
      height="32"
      fill="none"
      viewBox="0 0 158 32"
    >
      <path
        fill="url(#paint0_linear_28_156)"
        d="M36.163 0c-8.876 0-16.071 7.163-16.071 15.999s7.195 15.998 16.071 15.998c8.876 0 16.072-7.162 16.072-15.998C52.235 7.163 45.039 0 36.163 0zm0 26c-5.548 0-10.044-4.476-10.044-9.999 0-5.523 4.496-9.998 10.044-9.998S46.208 10.479 46.208 16c0 5.523-4.497 10-10.045 10z"
      ></path>
      <path
        fill="#13E2E2"
        d="M26.119 16.001a9.958 9.958 0 01-3.918 7.92A10.038 10.038 0 0116.07 26c-5.548 0-10.044-4.477-10.044-10s4.497-9.998 10.045-9.998c2.307 0 4.43.776 6.129 2.08A9.95 9.95 0 0126.119 16c0-3.225 1.537-6.093 3.918-7.921a16.056 16.056 0 00-3.918-4.564A16.047 16.047 0 0016.072 0C7.195 0 0 7.163 0 15.999s7.195 15.998 16.072 15.998c3.801 0 7.294-1.318 10.044-3.513a16.137 16.137 0 003.918-4.564 9.964 9.964 0 01-3.915-7.919z"
      ></path>
      <path
        fill="#fff"
        d="M67.156 23.65c4.569 0 7.44-2.72 7.663-6.722h-4.49c-.28 1.86-1.486 2.902-3.117 2.902-2.223 0-3.664-1.893-3.664-5.225 0-3.287 1.452-5.168 3.664-5.168 1.742 0 2.86 1.167 3.117 2.901h4.49c-.2-4.023-3.206-6.664-7.685-6.664-5.206 0-8.424 3.66-8.424 8.999 0 5.293 3.162 8.976 8.446 8.976zM77.867 23.31h4.758V5.9h-4.758v17.41zM95.564 5.9h-3.228V1.73h-4.76V5.9h-2.345v3.627h2.346v9.068c-.023 3.411 2.267 5.1 5.72 4.953a9.132 9.132 0 002.58-.454l-.749-3.593c-.234.046-.737.159-1.184.159-.95 0-1.608-.363-1.608-1.7V9.527h3.228V5.901zM98.664 23.31h4.759V5.9h-4.759v17.41zM121.512 10.865c-.424-3.208-2.971-5.191-7.306-5.191-4.39 0-7.284 2.063-7.272 5.417-.012 2.607 1.608 4.296 4.96 4.976l2.971.6c1.497.307 2.178.862 2.201 1.735-.023 1.031-1.129 1.768-2.793 1.768-1.698 0-2.826-.737-3.117-2.154l-4.68.25c.446 3.332 3.239 5.383 7.786 5.383 4.446 0 7.63-2.3 7.641-5.735-.011-2.516-1.631-4.023-4.96-4.715l-3.106-.634c-1.597-.351-2.189-.907-2.178-1.746-.011-1.042 1.151-1.722 2.626-1.722 1.652 0 2.636.918 2.87 2.04l4.357-.272zM127.838 29.838c3.541 0 5.418-1.836 6.39-4.647l6.669-19.268-5.038-.022-3.586 12.784h-.179l-3.552-12.784h-5.005l6.155 17.907-.279.737c-.626 1.632-1.821 1.711-3.496 1.19l-1.073 3.604c.681.295 1.787.499 2.994.499zM157.609 10.865c-.425-3.208-2.972-5.191-7.306-5.191-4.39 0-7.284 2.063-7.272 5.417-.012 2.607 1.608 4.296 4.96 4.976l2.971.6c1.497.307 2.178.862 2.201 1.735-.023 1.031-1.129 1.768-2.793 1.768-1.698 0-2.826-.737-3.117-2.154l-4.68.25c.446 3.332 3.239 5.383 7.786 5.383 4.446 0 7.63-2.3 7.641-5.735-.011-2.516-1.631-4.023-4.96-4.715l-3.106-.634c-1.597-.351-2.189-.907-2.178-1.746-.011-1.042 1.151-1.722 2.625-1.722 1.653 0 2.637.918 2.871 2.04l4.357-.272z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_28_156"
          x1="20.092"
          x2="52.235"
          y1="15.837"
          y2="15.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#103DDA"></stop>
          <stop offset="1" stopColor="#13E2E2"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logo;
