import * as React from "react";

export function useCalcTableScrollHeight() {
  const tableRef = React.useRef<any>(null);
  const scrollHeight = React.useMemo(() => {
    const h = tableRef.current?.getBoundingClientRect()?.height || 0;
    const headerH =
      tableRef.current?.querySelector("thead").getBoundingClientRect()
        ?.height || 0;
    return h - headerH;
  }, [tableRef.current]);

  return { tableRef, scrollHeight };
}
