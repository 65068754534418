import { useLazyQuery } from "@apollo/client";
import { Table } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ControlContext } from "../../../context/ControlContext";
import {
  adBoxGroupAllData,
  adBoxGroupAllVars,
  AD_BOX_GROUP_ALL,
} from "../../../graphql/schema/adBoxGroup";
import { AdBox } from "../../../graphql/types/adBox";
import { AdBoxGroup } from "../../../graphql/types/adBoxGroup";
import { useCalcTableScrollHeight } from "../../../hooks/useCalcTableScrollHeight";
import { usePagination } from "../../../hooks/usePagination";
import { usePreventExceed } from "../../../hooks/usePreventExceed";
import { mapKey } from "../../../utils/mapKey";

const columns = [
  {
    title: "Tên nhóm bảng quảng cáo",
    dataIndex: "name",
  },
  {
    title: "Số serial",
    dataIndex: "serialNumber",
  },
  {
    title: "Địa chỉ",
    dataIndex: "province",
  },
];
interface IAdsTabProps {}

const AdsTab: React.FunctionComponent<IAdsTabProps> = (props) => {
  const [total, setTotal] = React.useState<number>(0);
  const { paging, PaginationElement } = usePagination(total);
  const navigate = useNavigate();
  const [tableData, setTableData] = React.useState<AdBox[]>([]);
  const handleClickName = (record: AdBox) => {
    navigate(record.id!.toString());
  };
  const { setBreadcrumbText } = React.useContext(ControlContext)!;
  React.useEffect(() => {
    setBreadcrumbText(undefined);
  }, []);
  const [adBoxGroupAllDataLazy] = useLazyQuery<
    adBoxGroupAllData,
    adBoxGroupAllVars
  >(AD_BOX_GROUP_ALL, {
    variables: {
      paging,
    },
    onCompleted(data) {
      setTableData(data.adBoxGroupAll);
      setTotal(data.adBoxGroupAllCount || 0);
    },
  });
  usePreventExceed(adBoxGroupAllDataLazy, [paging]);
  const handleOnRow = (record: AdBoxGroup) => ({
    onClick: () => handleClickName(record),
  });
  const { scrollHeight, tableRef } = useCalcTableScrollHeight();
  return (
    <>
      <Table
        ref={tableRef}
        scroll={{
          x: true,
          y: scrollHeight,
        }}
        className="flex-auto"
        columns={columns}
        dataSource={mapKey(tableData)}
        onRow={handleOnRow}
        pagination={false}
      />
      <>
        <PaginationElement />
      </>
    </>
  );
};

export default AdsTab;
