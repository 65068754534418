import * as React from 'react';
import { IIconProps } from './IIconProps';

const Trash: React.FunctionComponent<IIconProps> = ({ size = 'sm', color = "currentColor" }) => {
    return <>
        {
            size === 'sm' && <></>
        }
        {
            size === 'md' && <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 6.5H21Z" fill="#35363B" />
                <path d="M3 6.5H21" stroke="#35363B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.57143 4.8C8.57143 4.32261 8.75204 3.86477 9.07353 3.52721C9.39502 3.18964 9.83106 3 10.2857 3H13.7143C14.1689 3 14.605 3.18964 14.9265 3.52721C15.248 3.86477 15.4286 4.32261 15.4286 4.8M18 6.6V19.2C18 19.6774 17.8194 20.1352 17.4979 20.4728C17.1764 20.8104 16.7404 21 16.2857 21H7.71429C7.25963 21 6.82359 20.8104 6.5021 20.4728C6.18061 20.1352 6 19.6774 6 19.2V6.6H18Z" stroke="#35363B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 11V17" stroke="#35363B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 11V17" stroke="#35363B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
        {
            size === 'lg' && <></>
        }
    </>
};

export default Trash;
