import {
  BulbOutlined,
  ContainerOutlined,
  LogoutOutlined,
  VideoCameraAddOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Menu } from "antd";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Neutral } from "../../../constants/colors";
import { deauth } from "../../../graphql/auth";
import {
  getCurrentUserData,
  getCurrentUserVars,
  GET_CURRENT_USER,
} from "../../../graphql/schema/user";
import { UserPermissionField } from "../../../graphql/types/user";
import { LogoDark, Search } from "../../Icons";
import { CtsInput } from "../../Input";
import "./cts-sider.scss";

interface MenuItemByPermission extends IMenuItemCustomProps {
  field: UserPermissionField;
}

const listMenu: MenuItemByPermission[] = [
  {
    field: UserPermissionField.LIGHT,
    icon: <BulbOutlined />,
    to: "/lighting",
    children: "Chiếu sáng",
  },
  {
    field: UserPermissionField.TRAFFIC,
    icon: <WarningOutlined />,
    to: "/traffic",
    children: "Giao thông",
  },
  {
    field: UserPermissionField.CAMERA,
    icon: <VideoCameraAddOutlined />,
    to: "/camera",
    children: "Camera",
  },
  {
    field: UserPermissionField.ADBOARD,
    icon: <ContainerOutlined />,
    to: "/ads",
    children: "Quảng cáo",
  },
  {
    field: UserPermissionField.ADBOARD,
    icon: <ContainerOutlined />,
    to: "/logout",
    children: "Đăng xuất",
  },
];

const CtsSider: React.FC = () => {
  const [fields, setFields] = useState<any>([]);
  useQuery<getCurrentUserData, getCurrentUserVars>(GET_CURRENT_USER, {
    onCompleted(data) {
      setFields(data.getCurrentUser.permissions.map((item) => item.field));
    },
    onError(error) {
      console.log("error11:", error);
    },
  });

  const listMenuI = useMemo(() => {
    const a = listMenu.filter((item) => {
      return fields.indexOf(item.field) >= 0;
    });
    // return listMenu;
    return a;
  }, [fields]);
  return (
    <div className="cts-sider w-[184px] bg-neutral-D70">
      <div className="p-[16px]">
        <LogoDark />
      </div>
      <div className="py-0 px-[16px]">
        <CtsInput
          borderColor="transparent"
          placeholder="Tìm kiếm"
          bgColor={Neutral.D50}
          textColor={Neutral.L90}
          rightIcon={<Search />}
        />
      </div>
      <Menu style={{ backgroundColor: "transparent", border: "none" }}>
        <Menu.ItemGroup
          title={
            <div className="text-[12px] tracking-[0.04em] h-[15px] leading-[15px] uppercase mt-[33px] text-neutral-D30">
              Menu
            </div>
          }
        />
        <div className="mt-[16px]">
          {listMenuI.map(({ field, ...item }, key) => (
            <MenuItemCustom {...item} key={`menuitem-${key}`} />
          ))}
        </div>

        <div onClick={deauth} className="h-[48px] flex items-center gap-[8px] px-[16px] !text-neutral-White hover:!bg-neutral-L30 hover:!font-7 hover:!text-neutral-D90 transition duration-500 cursor-pointer"><LogoutOutlined /> Đăng xuất</div>
      </Menu>
    </div>
  );
};

export default React.memo(CtsSider);

interface IMenuItemCustomProps {
  icon?: ReactNode;
  to: string;
  children: ReactNode;
}

const MenuItemCustom: React.FunctionComponent<IMenuItemCustomProps> = ({
  children,
  to,
  icon,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const ItemX = useCallback(() => {
    return (
      <Link
        to={to}
        className={`h-[48px] flex items-center gap-[8px] px-[16px] ${
          pathname.indexOf(to) >= 0
            ? " !bg-neutral-L30 !text-neutral-D90 font-7 group"
            : " !text-neutral-White hover:!bg-neutral-L30 hover:!font-7 hover:!text-neutral-D90"
        } transition duration-500 cursor-pointer`}>
        {icon}
        {children}
      </Link>
    );
  }, [pathname, to, children, icon]);
  return <ItemX />;
};
