import { useQuery } from "@apollo/client";
import { Select } from "antd";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Sub2 } from "../../../../../../components/Text";
import TwButton from "../../../../../../components/TwButton";
import {
  GetLightBoxTimeSlotsData,
  GetLightBoxTimeSlotsVars,
  GET_LIGHT_BOX_TIME_SLOTS,
} from "../../../../../../graphql/schema/lightBox";
import {
  LightBoxDetailState,
  LightBoxTimeSlot,
} from "../../../../../../graphql/types/lightBox";
import EditModal from "./components/EditModal";
// import { PowerChartData } from '../../../../../../graphql/types/type';
import "./power.scss";
import PowerChart from "./components/PowerChart";
import TimeSlotBadge, { TimeEndBadge } from "./components/TimeSlotBadge";
import { DetailContext } from "../../../../../../context/LightBoxDetailContext";

export interface PowerChartData {
  startTime: Date;
  power: number;
  endTime: Date;
}
export const formatTime = "HH:mm";
const { Option } = Select;
export const mapListToDateTimeData = (
  list: {
    x: string;
    y: number;
  }[]
) => list.map((item) => ({ ...item, x: new Date(item.x) }));
interface IPowerProps {}
interface IOptionProps {
  key: number;
  value: string;
}

export const options: IOptionProps[] = [
  {
    key: 0,
    value: "0%",
  },
  {
    key: 10,
    value: "10%",
  },
  {
    key: 20,
    value: "20%",
  },
  {
    key: 30,
    value: "30%",
  },
  {
    key: 40,
    value: "40%",
  },
  {
    key: 50,
    value: "50%",
  },
  {
    key: 60,
    value: "60%",
  },
  {
    key: 70,
    value: "70%",
  },
  {
    key: 80,
    value: "80%",
  },
  {
    key: 90,
    value: "90%",
  },
  {
    key: 100,
    value: "100%",
  },
];

export const getMinuteFromMoment = (time: moment.Moment): number => {
  const endHour = time.hour() < 12 ? time.hour() + 24 : time.hour();
  return endHour * 60 + time.minute();
};
const Power: React.FunctionComponent<IPowerProps> = (props) => {
  const [timeSlots, setTimeSlots] = useState<LightBoxTimeSlot[]>([]);
  const [visibleEditModal, setVisibleEditModal] = useState<boolean>(false);
  const { boxId } = useParams();
  const [chartData, setChartData] = React.useState<PowerChartData[]>([]);
  const { error } = React.useContext(DetailContext)!;

  const { data } = useQuery<GetLightBoxTimeSlotsData, GetLightBoxTimeSlotsVars>(
    GET_LIGHT_BOX_TIME_SLOTS,
    {
      variables: {
        boxId: boxId!,
      },
    }
  );

  const getTimeOffset = (timeStr: string) => {
    let minute = Number(timeStr.split(":")[1]);
    let hour = Number(timeStr.split(":")[0]);
    let time = moment(timeStr, formatTime);
    if (hour + 18 >= 24) {
      time = time.subtract("1", "day");
    }
    return time;
  };

  useEffect(() => {
    const dataToChart: PowerChartData[] = timeSlots.map(
      ({ startTime, power, endTime }) => {
        return {
          startTime: getTimeOffset(startTime).toDate(),
          power: power ? power / 100 : 0,
          endTime: getTimeOffset(endTime).toDate(),
        };
      }
    );
    setChartData(dataToChart);
  }, [timeSlots]);
  React.useEffect(() => {
    if (data) {
      const { getLightBoxTimeSlots } = data;
      setTimeSlots(getLightBoxTimeSlots);
    }
  }, [data]);

  return (
    <>
      {error?.errZEN ? (
        <div className="text-error font-7 text-[24px] h-[500px] flex justify-center items-center">
          Không có kết nối ZEN
        </div>
      ) : (
        <div>
          {/* <div className="text-right pr-[8px]">
            <div className="inline-block rounded-sm overflow-hidden bg-neutral-L30">
              <Select
                style={{ width: "auto", backgroundColor: "#F6F6F7" }}
                value="1"
                size="large"
                bordered={false}
              >
                <Option key="1">
                  <span style={{ fontWeight: "400" }}>Nhóm đèn mở rộng</span>{" "}
                </Option>
              </Select>
            </div>
          </div> */}
          <div className="">
            <div className="w-full pl-[6px] pr-[32px]">
              {chartData.length !== 0 && (
                <PowerChart width={681} height={364} data={chartData} />
              )}
            </div>
            <div className="px-[24px] pt-[20px]">
              {timeSlots.length > 0 && (
                <>
                  <div className="flex flex-col gap-[22px]">
                    <div className="flex gap-[16px] items-start">
                      <div className="flex flex-col gap-[9px]">
                        <Sub2 className="text-neutral-D50">Thời gian bật</Sub2>
                        <TimeSlotBadge
                          time={timeSlots[0].startTime}
                          power={timeSlots[0].power}
                        />
                      </div>
                      <div className="flex flex-col gap-[9px]">
                        <Sub2 className="text-neutral-D50">Thời gian tắt</Sub2>
                        <TimeEndBadge
                          time={timeSlots[timeSlots.length - 1].endTime}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                      <Sub2 className="text-neutral-D50">Điểm điều chỉnh</Sub2>
                      <div className="flex gap-[8px]">
                        {timeSlots.map(
                          (item: LightBoxTimeSlot, key: number) => {
                            return (
                              key !== 0 && (
                                <TimeSlotBadge
                                  time={item.startTime}
                                  power={item.power || 0}
                                  key={key}
                                />
                              )
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <EditModal
                    data={timeSlots}
                    onRefetch={setTimeSlots}
                    toggleVisible={() => setVisibleEditModal(!visibleEditModal)}
                    visible={visibleEditModal}
                  />
                  <div className="mt-[32px] flex justify-start">
                    <TwButton
                      onClick={() => setVisibleEditModal(!visibleEditModal)}>
                      {" "}
                      Chỉnh sửa{" "}
                    </TwButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Power;
