import * as d3 from "d3";
import * as React from "react";
import { PowerChartData } from "..";
import {
  Neutral,
  Turquoise,
  Variant,
} from "../../../../../../../constants/colors";
import { CommonContext } from "../../../../../../../context/CommonContext";

const paddings: [number, number, number, number] = [12, 32, 32, 48];
interface IPowerChartProps {
  width?: number;
  height?: number;
  data: PowerChartData[];
}

const PowerChart: React.FunctionComponent<IPowerChartProps> = ({
  width = 700,
  height = 364,
  data,
}) => {
  const { PLCTime } = React.useContext(CommonContext)!;
  let svgRef = React.createRef<SVGSVGElement>();
  let minTimeDomain = new Date();
  const current = new Date();
  minTimeDomain.setDate(current.getDate() - 1);
  minTimeDomain.setHours(17);
  minTimeDomain.setMinutes(30);
  let maxTimeDomain = new Date();
  maxTimeDomain.setHours(5);
  maxTimeDomain.setMinutes(29);
  let xScale = d3
    .scaleTime()
    .domain([minTimeDomain, maxTimeDomain])
    .range([paddings[3], width - paddings[1]]);
  let yScale = d3
    .scaleLinear()
    .domain([1, 0])
    .range([paddings[0], height - paddings[2]]);
  React.useEffect(() => {
    const SVGElement = d3.select(svgRef.current);

    SVGElement.selectAll(".current-time-line").remove();
    SVGElement.append("line")
      .attr("class", "current-time-line")
      .style("stroke", Variant.Error)
      .style("stroke-width", 2)
      .attr("x1", xScale(PLCTime))
      .attr("x2", xScale(PLCTime))
      .attr("y1", 0)
      .attr("y2", height - paddings[2])
      .style("stroke-dasharray", "10,10")
      .raise();
  }, [PLCTime]);
  const updateXScale = () => {
    const SVGElement = d3.select(svgRef.current);

    const timeFormatter = d3.timeFormat("%H:%M");
    if (data.length >= 1) {
      const xAxis: d3.Axis<Date> = d3
        .axisBottom<Date>(xScale)
        .ticks(d3.timeHour.every(2))
        .tickFormat((d) => timeFormatter(d))
        .tickPadding(17)
        .tickSize(height - paddings[2] - paddings[0]);
      SVGElement.select(".xAxis").remove();
      SVGElement.append("g")
        .classed("xAxis", true)
        .attr("transform", `translate(0, ${paddings[0]})`)
        .call(xAxis)
        .lower();
      SVGElement.selectAll("line").attr("stroke", "#C3C5CA");
    }
  };
  const updateYScale = () => {
    const SVGElement = d3.select(svgRef.current);
    if (data.length >= 1) {
      const yAxis: d3.Axis<d3.NumberValue> = d3
        .axisLeft<d3.NumberValue>(yScale)
        .tickValues(d3.range(0, 1.1, 0.25))
        .tickFormat((d) => {
          if (d === 1) {
            return (parseFloat(d.toString()) * 100).toString() + " %";
          }
          return (parseFloat(d.toString()) * 100).toString();
        })
        .tickPadding(8)
        .tickSize(width - paddings[1] - paddings[3]);
      SVGElement.select(".yAxis").remove();
      SVGElement.append("g")
        .classed("yAxis", true)
        .attr("transform", `translate(${width - paddings[1]}, 0)`)
        .call(yAxis)
        .lower();
      SVGElement.selectAll("line").attr("stroke", Neutral.L50);
      SVGElement.selectAll(".domain").attr("stroke", Neutral.L70);

      d3.selectAll(".tick>text")
        .attr("color", Neutral.D50)
        .attr("font-size", "12px")
        .attr("font-weight", 400);
    }
  };
  const convertData = (data: PowerChartData[]): PowerChartData[] => {
    let converted: PowerChartData[] = [];
    data.forEach((datum, index) => {
      converted.push(datum);
      let temp =
        index + 1 !== data.length ? data[index + 1].startTime : datum.endTime;
      converted.push({
        startTime: temp,
        power: datum.power,
        endTime: datum.endTime,
      });
    });
    return converted;
  };
  const updateLineData = () => {
    const SVGElement = d3.select(svgRef.current);
    const area = d3
      .area<PowerChartData>()
      .x((d) => xScale!(d.startTime))
      .y0(yScale(0))
      .y1((d) => yScale!(d.power));
    const line = d3
      .line<PowerChartData>()
      .x((d) => xScale!(d.startTime))
      .y((d) => yScale!(d.power));

    const convertedData = convertData(data);

    SVGElement.select(".area").remove();
    SVGElement.select(".line_on_top").remove();
    SVGElement.select(".dots").remove();

    SVGElement.append("path")
      .classed("area", true)
      .data([convertedData])
      .attr("d", (d) => area(d))
      .style("fill", "url(#profileGradient)")
      .raise();
    SVGElement.append("path")
      .classed("line_on_top", true)
      .data([convertedData])
      .attr("stroke-width", 2)
      .attr("stroke", "#FFC700")
      .attr("fill", "none")
      .attr("d", (d) => line(d))
      .raise();

    SVGElement.append("g")
      .classed("dots", true)
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("r", 6)
      .attr("cx", (d) => xScale!(d.startTime))
      .attr("cy", (d) => yScale!(d.power))
      .style("fill", Turquoise.Secondary);
  };

  const initLineChart = () => {
    const svgElement = d3.select(svgRef.current);
    let linearGradient = svgElement
      .append("linearGradient")
      .attr("id", "profileGradient")
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "100%")
      .attr("y2", "0%");
    linearGradient
      .append("stop")
      .attr("offset", "0%")
      .style("stop-color", "#F5C109")
      .style("stop-opacity", 0.1);
    linearGradient
      .append("stop")
      .attr("offset", "100%")
      .style("stop-color", "#F5C109")
      .style("stop-opacity", 0.5);
    renderLineChart();
  };
  const renderLineChart = () => {
    updateXScale();
    updateYScale();
    updateLineData();
  };
  React.useEffect(() => {
    initLineChart();
  }, [data]);
  return <svg ref={svgRef} width={"100%"} viewBox={`0 0 ${width} ${height}`} />;
};
export default PowerChart;
