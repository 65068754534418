import { useMutation, useQuery, useSubscription } from "@apollo/client";
import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Label1, Sub2 } from "../../../../../../components/Text";
import { DetailContext } from "../../../../../../context/LightBoxDetailContext";
import {
  getLightBoxVoltageData,
  getLightBoxVoltageInfoData,
  getLightBoxVoltageInfoVars,
  getLightBoxVoltageVars,
  GET_LIGHT_BOX_VOLTAGE,
  GET_LIGHT_BOX_VOLTAGE_INFO,
  setLightBoxDelayVoltageData,
  setLightBoxDelayVoltageVars,
  setLightBoxHighVoltageData,
  setLightBoxHighVoltageVars,
  setLightBoxLowVoltageData,
  setLightBoxLowVoltageVars,
  SET_LIGHT_BOX_DELAY_VOLTAGE,
  SET_LIGHT_BOX_HIGH_VOLTAGE,
  SET_LIGHT_BOX_LOW_VOLTAGE,
} from "../../../../../../graphql/schema/lightBox";
import {
  getLightBoxInfoSubscriptionData,
  getLightBoxInfoSubscriptionVars,
  GET_LIGHT_BOX_INFO_SUBSCRIPTION,
} from "../../../../../../graphql/schema/lightBoxSubscription";
import SettingInput from "../components/SettingInput";
import VoltageChart, { IVoltageChartData } from "./components/VoltageChart";

interface IVoltageProps {}
export const getTimeBefore = (h: number) => {
  const currentTime = new Date();
  currentTime.setHours(currentTime.getHours() - h);
  return currentTime;
};
interface IVoltageInfoSetting {
  highVoltage: number;
  lowVoltage: number;
  delayVoltage: number;
}
interface IVoltageInfo {
  voltageV1: number;
  voltageV2: number;
  voltageV3: number;
  highVoltage: number;
  lowVoltage: number;
  delayVoltage: number;
}
const Voltage: React.FunctionComponent<IVoltageProps> = (props) => {
  const [voltageInfo, setVoltageInfo] = useState<IVoltageInfo>({
    voltageV1: 0,
    voltageV2: 0,
    voltageV3: 0,
    highVoltage: 0,
    lowVoltage: 0,
    delayVoltage: 0,
  });
  const [voltageInfoSettingUpdate, setVoltageInfoSettingUpdate] =
    useState<IVoltageInfoSetting>({
      highVoltage: 0,
      lowVoltage: 0,
      delayVoltage: 0,
    });
  const [currentTime] = React.useState<string>(getTimeBefore(24).toISOString());
  const { boxId } = useParams();
  const { error } = React.useContext(DetailContext)!;

  useQuery<getLightBoxVoltageData, getLightBoxVoltageVars>(
    GET_LIGHT_BOX_VOLTAGE,
    {
      variables: {
        boxId: boxId!,
        startTime: currentTime,
      },
      onCompleted: (data) => {
        const {
          getLightBoxVoltageV1,
          getLightBoxVoltageV2,
          getLightBoxVoltageV3,
        } = data;
        setVoltageChartData({
          V1: getLightBoxVoltageV1,
          V2: getLightBoxVoltageV2,
          V3: getLightBoxVoltageV3,
        });
      },
      onError(error) {
        console.error("getLightBoxVoltage", error)
      }
    }
  );

  const [voltageChartData, setVoltageChartData] = useState<IVoltageChartData>({
    V1: [],
    V2: [],
    V3: [],
  });

  useSubscription<
    getLightBoxInfoSubscriptionData,
    getLightBoxInfoSubscriptionVars
  >(GET_LIGHT_BOX_INFO_SUBSCRIPTION, {
    variables: { boxId: boxId! },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data) {
        const { data } = subscriptionData;
        const { value } = data.getLightBoxInfo;
        const statusName = data?.getLightBoxInfo.status!;
        if (
          statusName === "voltageV1" ||
          statusName === "voltageV2" ||
          statusName === "voltageV3"
        ) {
          setVoltageInfo({ ...voltageInfo, [statusName]: value });
        }
        const time = new Date();
        if (statusName === "voltageV1") {
          setVoltageChartData({
            ...voltageChartData,
            V1: [
              ...voltageChartData.V1,
              { value: value, time: time.toISOString() },
            ],
          });
        }
        if (statusName === "voltageV2") {
          setVoltageChartData({
            ...voltageChartData,
            V2: [
              ...voltageChartData.V2,
              { value: value, time: time.toISOString() },
            ],
          });
        }
        if (statusName === "voltageV3") {
          setVoltageChartData({
            ...voltageChartData,
            V3: [
              ...voltageChartData.V3,
              { value: value, time: time.toISOString() },
            ],
          });
        }
      }
    },
  });
  useQuery<getLightBoxVoltageInfoData, getLightBoxVoltageInfoVars>(
    GET_LIGHT_BOX_VOLTAGE_INFO,
    {
      variables: {
        boxId: boxId!,
      },
      onCompleted: (data) => {
        const { getLightBoxVoltageInfo } = data;
        const highVoltage =
          getLightBoxVoltageInfo.find((item) => item.status === "highVoltage")
            ?.value || 0;
        const lowVoltage =
          getLightBoxVoltageInfo.find((item) => item.status === "lowVoltage")
            ?.value || 0;
        const delayVoltage =
          getLightBoxVoltageInfo.find((item) => item.status === "delayVoltage")
            ?.value || 0;

        const voltageV1 =
          getLightBoxVoltageInfo.find((item) => item.status === "voltageV1")
            ?.value || 0;
        const voltageV2 =
          getLightBoxVoltageInfo.find((item) => item.status === "voltageV2")
            ?.value || 0;
        const voltageV3 =
          getLightBoxVoltageInfo.find((item) => item.status === "voltageV3")
            ?.value || 0;
        setVoltageInfo({
          ...voltageInfo,
          voltageV1,
          voltageV3,
          voltageV2,
          highVoltage,
          lowVoltage,
          delayVoltage,
        });
        setVoltageInfoSettingUpdate({
          ...voltageInfoSettingUpdate,
          highVoltage,
          lowVoltage,
          delayVoltage,
        });
      },
    }
  );

  const [submitHighVoltageMutation] = useMutation<
    setLightBoxHighVoltageData,
    setLightBoxHighVoltageVars
  >(SET_LIGHT_BOX_HIGH_VOLTAGE);
  const [submitLowVoltageMutation] = useMutation<
    setLightBoxLowVoltageData,
    setLightBoxLowVoltageVars
  >(SET_LIGHT_BOX_LOW_VOLTAGE);
  const [submitDelayVoltageMutation] = useMutation<
    setLightBoxDelayVoltageData,
    setLightBoxDelayVoltageVars
  >(SET_LIGHT_BOX_DELAY_VOLTAGE);

  const handleUpdateHighVoltage = () => {
    const { highVoltage } = voltageInfoSettingUpdate;
    submitHighVoltageMutation({
      variables: {
        boxId: boxId!,
        value: highVoltage,
      },
    })
      .then((rs) => {
        setVoltageInfo({ ...voltageInfo, highVoltage });
      })
      .catch((err) => {});
  };
  const handleUpdateLowVoltage = () => {
    const { lowVoltage } = voltageInfoSettingUpdate;
    submitLowVoltageMutation({
      variables: {
        boxId: boxId!,
        value: lowVoltage,
      },
    })
      .then((rs) => {
        setVoltageInfo({ ...voltageInfo, lowVoltage });
      })
      .catch((err) => {});
  };
  const handleUpdateDelayVoltage = () => {
    const { delayVoltage } = voltageInfoSettingUpdate;
    submitDelayVoltageMutation({
      variables: {
        boxId: boxId!,
        value: delayVoltage,
      },
    })
      .then((rs) => {
        setVoltageInfo({ ...voltageInfo, delayVoltage });
      })
      .catch((err) => {});
  };
  const handleChangeSetting = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setVoltageInfoSettingUpdate({
      ...voltageInfoSettingUpdate,
      [name]: Number(value),
    });
  };

  return (
    <>
      {error?.errPM ? (
        <div className="text-error font-7 text-[24px] h-[500px] flex justify-center items-center">
          Không có kết nối PM
        </div>
      ) : (
        <div className="px-[24px] pb-[24px]">
          <div className="h-[400px]">
            <VoltageChart
              data={voltageChartData}
              lowVoltage={voltageInfo.lowVoltage}
              highVoltage={voltageInfo.highVoltage}
            />
          </div>
          <div className="py-[30px] flex justify-between">
            <div>
              <Sub2 className="color-neutral-D50">Cài đặt thông số</Sub2>
              <div className="flex flex-col gap-[16px] mt-[16px]">
                <SettingInput
                  label="Ngưỡng cao điện áp pha"
                  isSubmited={
                    voltageInfo.highVoltage ===
                    voltageInfoSettingUpdate.highVoltage
                  }
                  type="number"
                  name="highVoltage"
                  value={voltageInfoSettingUpdate.highVoltage}
                  onChange={handleChangeSetting}
                  onPressEnter={handleUpdateHighVoltage}
                  unit="V"
                />
                <SettingInput
                  isSubmited={
                    voltageInfo.lowVoltage ===
                    voltageInfoSettingUpdate.lowVoltage
                  }
                  label="Ngưỡng thấp điện áp pha"
                  type="number"
                  value={voltageInfoSettingUpdate.lowVoltage}
                  name="lowVoltage"
                  onChange={handleChangeSetting}
                  onPressEnter={handleUpdateLowVoltage}
                  unit="V"
                />
                <SettingInput
                  isSubmited={
                    voltageInfo.delayVoltage ===
                    voltageInfoSettingUpdate.delayVoltage
                  }
                  label="Độ dao động điện áp pha"
                  type="number"
                  value={voltageInfoSettingUpdate.delayVoltage}
                  name="delayVoltage"
                  onChange={handleChangeSetting}
                  onPressEnter={handleUpdateDelayVoltage}
                  unit="V"
                />
              </div>
            </div>
            <div className="w-[256px] h-[128px] shadow-lg p-[22px] flex flex-col justify-around">
              <ParamItem
                status="success"
                name="Pha 1"
                value={Math.round(voltageInfo.voltageV1 * 100) / 100}
              />
              <ParamItem
                status="warning"
                name="Pha 2"
                value={Math.round(voltageInfo.voltageV2 * 100) / 100}
              />
              <ParamItem
                status="error"
                name="Pha 3"
                value={Math.round(voltageInfo.voltageV3 * 100) / 100}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Voltage;

interface IParamItemProps {
  name?: string;
  value?: number;
  status?: "success" | "warning" | "error";
}

const ParamItem: React.FC<IParamItemProps> = ({
  name = "",
  value = "",
  status = "success",
}) => {
  return (
    <div className="flex items-center gap-[8px]">
      <Label1 className="text-neutral-D90">{name}</Label1>
      <div className={`h-[3px] w-[80px] shrink-0 bg-${status}`} />
      <Label1 className="text-neutral-D90">{value} V</Label1>
    </div>
  );
};
