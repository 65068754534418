import { useMutation, useQuery } from "@apollo/client";
import { Modal } from "antd";
import moment from "moment";
import * as React from "react";
import { useParams } from "react-router-dom";
import { CTSNotification } from "../../../../components/CTSNotification";
import { Label2 } from "../../../../components/Text";
import TwButton from "../../../../components/TwButton";
import { ControlContext } from "../../../../context/ControlContext";
import TrafficCabProvider, {
  useTrafficCab,
} from "../../../../context/TrafficContext";
import {
  getTrafficBoxData,
  getTrafficBoxVars,
  GET_TRAFFIC_BOX,
  GET_TRAFFIC_BOX_TIME_SLOTS,
  setTimeSlotTrafficBoxData,
  setTimeSlotTrafficBoxVars,
  SET_TIME_SLOT_TRAFFIC_BOX,
} from "../../../../graphql/schema/trafficBox";
import { TrafficBoxTimeSlot } from "../../../../graphql/types/trafficBox";
import TrafficTimeSlot from "./components/TrafficTimeSlot";
import TrafficTimeSlotEdit from "./components/TrafficTimeSlotEdit";
import "./traffic-detail.scss";

const sortTimeSlots = (a: TrafficBoxTimeSlot, b: TrafficBoxTimeSlot) =>
  a.hour * 60 + a.minute - b.hour * 60 - b.minute;
const TrafficBoxDetailData: React.FC = () => {
  const { boxId } = useParams();
  const [timeSlotArr, setTimeSlotArr] = React.useState<TrafficBoxTimeSlot[]>(
    []
  );
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const { status, watchMessage } = useTrafficCab(boxId!);
  const enabledSetting = React.useMemo(() => {
    if (Object.keys(status).length === 0) {
      return false;
    }
    return true;
  }, [status]);
  const trafficBoxCurrentTimeSlot = React.useMemo(() => {
    // TODO: handle undefined value
    return moment(status.CURRENT_TIME_SLOT!, "HH:mm");
  }, [status.CURRENT_TIME_SLOT]);
  const [editTimeSlotIdx, setEditTimeSlotIdx] = React.useState<
    number | undefined
  >(undefined);
  const [editTimeSlot, setEditTimeSlot] = React.useState<
    TrafficBoxTimeSlot | undefined
  >(undefined);

  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = React.useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const { setBreadcrumbText } = React.useContext(ControlContext)!;
  useQuery<getTrafficBoxData, getTrafficBoxVars>(GET_TRAFFIC_BOX, {
    variables: { input: { boxId } },
    onCompleted(data) {
      setBreadcrumbText(data.getTrafficBox.name);
    },
  });

  const { data } = useQuery<
    { getTrafficBoxTimeSlots: TrafficBoxTimeSlot[] },
    { boxId: string }
  >(GET_TRAFFIC_BOX_TIME_SLOTS, {
    variables: { boxId: boxId! },
  });

  const [setTimeSlotServer] = useMutation<
    setTimeSlotTrafficBoxData,
    setTimeSlotTrafficBoxVars
  >(SET_TIME_SLOT_TRAFFIC_BOX);

  React.useEffect(() => {
    if (data) {
      setTimeSlotArr(
        [...data.getTrafficBoxTimeSlots].sort(
          (a, b) => a.hour * 60 + a.minute - b.hour * 60 - b.minute
        )
      );
    }
  }, [data]);

  const AddNewTimeSlot = React.useCallback(() => {
    // setShowModalError(false);
    const currentTime = new Date();
    const newTimeSlotDefault: TrafficBoxTimeSlot = {
      mode: "INITIALIZE",
      green1: 20,
      green2: 20,
      yellow1: 5,
      yellow2: 5,
      clearSessionTime: 5,
      hour: currentTime.getHours(),
      minute: currentTime.getMinutes(),
    };
    setEditTimeSlot(newTimeSlotDefault);
    let idx = timeSlotArr.findIndex((v) => {
      return (
        v.hour * 60 + v.minute >
        newTimeSlotDefault.hour * 60 + newTimeSlotDefault.minute
      );
    });

    if (idx === -1) {
      idx = timeSlotArr.length - 1;
    } else if (idx > 0) {
      idx--;
    }
    setEditTimeSlotIdx(idx);
    setOpenAddModal(true);
  }, [timeSlotArr]);
  const DeleteTimeSlot = React.useCallback((index: number) => {
    setEditTimeSlotIdx(index);
    setOpenDeleteModal(true);
  }, []);
  const ModifyTimeSlot = React.useCallback(
    (index: number) => {
      setEditTimeSlotIdx(index);
      setEditTimeSlot(timeSlotArr[index]);
      setOpenEditModal(true);
    },
    [timeSlotArr]
  );

  const onCancelEditModal = React.useCallback(() => {
    setOpenEditModal(false);
  }, [setOpenEditModal]);
  const updateTimeSlot = async (newTimeSlotList: TrafficBoxTimeSlot[]) => {
    setSubmitLoading(true);
    try {
      const setTimeSlotServerResult = await setTimeSlotServer({
        variables: { boxId: boxId!, timeSlots: newTimeSlotList },
      });
      const { messageId } =
        setTimeSlotServerResult.data?.setTimeSlotTrafficBox!;
      if (messageId) {
        watchMessage(messageId, {
          onSuccess() {},
          onError() {},
          onResult(message) {
            switch (message) {
              case "SUCCESSFUL":
                setOpenEditModal(false);
                setSubmitLoading(false);
                setTimeSlotArr(newTimeSlotList.sort(sortTimeSlots));
                setEditTimeSlot(undefined);
                setEditTimeSlotIdx(undefined);
                CTSNotification("success", "Cập nhật thời đoạn thành công");
                break;
              case "ERROR":
                setOpenEditModal(false);
                setSubmitLoading(false);
                CTSNotification("error", "Cập nhật thời đoạn thất bại");
                break;
              default:
                break;
            }
          },
        });
      } else {
        setSubmitLoading(false);
        CTSNotification("error", "Cập nhật thời đoạn thất bại");
      }
    } catch (error) {
      CTSNotification(
        "error",
        "Có tiến trình đang hoạt động, vui lòng chờ trong giây lát"
      );
      setSubmitLoading(false);
    }
  };
  const onOkEditModal = React.useCallback(async () => {
    if (editTimeSlot && editTimeSlotIdx != null) {
      let newTimeSlotList = [...timeSlotArr];
      newTimeSlotList[editTimeSlotIdx] = editTimeSlot;
      newTimeSlotList = newTimeSlotList.map(
        (item) => ({ ...item, __typename: undefined } as TrafficBoxTimeSlot)
      );
      updateTimeSlot(newTimeSlotList);
    }
  }, [editTimeSlot, editTimeSlotIdx, timeSlotArr]);

  const onCancelAddModal = React.useCallback(() => {
    setOpenAddModal(false);
  }, [setOpenAddModal]);
  const onOkAddModal = React.useCallback(async () => {
    if (editTimeSlot && editTimeSlotIdx != null) {
      let newTimeSlotList = [...timeSlotArr].map(
        (v) =>
          ({
            ...v,
            __typename: undefined,
          } as TrafficBoxTimeSlot)
      );
      newTimeSlotList.splice(editTimeSlotIdx, 0, editTimeSlot);
      updateTimeSlot(newTimeSlotList);
    }
  }, [editTimeSlot, editTimeSlotIdx, timeSlotArr]);

  const onOkDeleteModal = React.useCallback(async () => {
    if (editTimeSlotIdx != null) {
      let newTimeSlotList = [...timeSlotArr].map(
        (item) => ({ ...item, __typename: undefined } as TrafficBoxTimeSlot)
      );
      newTimeSlotList.splice(editTimeSlotIdx, 1);
      updateTimeSlot(newTimeSlotList);
    }
  }, [editTimeSlotIdx, setTimeSlotServer, timeSlotArr, boxId, watchMessage]);
  return (
    <>
      <div className="flex flex-col w-full gap-[1rem] flex-auto overflow-hidden">
        <TwButton
          sizeBtn="md"
          variant="OUTLINED"
          className="!rounded-[20px] ml-auto shrink-0"
          onClick={AddNewTimeSlot}>
          <Label2>Thêm thời đoạn</Label2>
        </TwButton>
        <div className="flex flex-col gap-y-[0.5rem] overflow-y-scroll flex-auto mr-[-1rem]">
          {timeSlotArr.map((timeslot, idx, arr) => (
            <TrafficTimeSlot
              enabledSetting={enabledSetting}
              onAddSelected={AddNewTimeSlot}
              onDeleteSelected={DeleteTimeSlot}
              onEditSelected={ModifyTimeSlot}
              index={idx}
              key={idx}
              mode={timeslot.mode === "YELLOW_ONLY" ? "YELLOW_ONLY" : "AUTO"}
              active={
                trafficBoxCurrentTimeSlot.hour() === timeslot.hour &&
                trafficBoxCurrentTimeSlot.minute() === timeslot.minute
              }
              params={{
                start: moment(
                  `${timeslot.hour
                    .toString()
                    .padStart(2, "0")}:${timeslot.minute
                    .toString()
                    .padStart(2, "0")}`,
                  "HH:mm"
                ),
                end:
                  arr.length === idx + 1
                    ? moment("23:59", "HH:mm")
                    : moment(
                        `${arr[idx + 1].hour.toString().padStart(2, "0")}:${arr[
                          idx + 1
                        ].minute
                          .toString()
                          .padStart(2, "0")}`,
                        "HH:mm"
                      ),
                green1: timeslot.green1,
                green2: timeslot.green2,
                yellow1: timeslot.yellow1,
                yellow2: timeslot.yellow2,
                clearSessionTime: timeslot.clearSessionTime,
              }}
            />
          ))}
        </div>
      </div>
      <Modal
        title="Cập nhật thời đoạn"
        maskClosable={false}
        closable={false}
        destroyOnClose
        centered
        visible={openEditModal}
        onCancel={onCancelEditModal}
        onOk={onOkEditModal}
        width={"47.875rem"}
        className="!font-['Inter']"
        okButtonProps={{
          loading: submitLoading,
        }}>
        {editTimeSlot != null && editTimeSlotIdx != null ? (
          <TrafficTimeSlotEdit
            key={editTimeSlotIdx}
            index={editTimeSlotIdx}
            defaultValue={[editTimeSlot, timeSlotArr]}
            onChange={(timeSlot) => setEditTimeSlot(timeSlot)}
          />
        ) : null}
      </Modal>
      <Modal
        title="Thêm thời đoạn"
        maskClosable={false}
        closable={false}
        destroyOnClose
        visible={openAddModal}
        centered
        onCancel={onCancelAddModal}
        onOk={onOkAddModal}
        okButtonProps={{ loading: submitLoading }}
        cancelButtonProps={{}}
        width={"47.875rem"}
        className="!font-['Inter']">
        {editTimeSlot != null && editTimeSlotIdx != null ? (
          <TrafficTimeSlotEdit
            key={editTimeSlotIdx}
            index={editTimeSlotIdx}
            defaultValue={[editTimeSlot, timeSlotArr]}
            onChange={(timeSlot) => {
              setEditTimeSlot(timeSlot);
              let idx = timeSlotArr.findIndex((v) => {
                return (
                  v.hour * 60 + v.minute > timeSlot.hour * 60 + timeSlot.minute
                );
              });
              if (idx === -1) {
                idx = timeSlotArr.length - 1;
              } else if (idx > 0) {
                idx--;
              }
              setEditTimeSlotIdx(idx);
            }}
          />
        ) : null}
      </Modal>
      <Modal
        title="Xóa thời đoạn"
        maskClosable={false}
        closable={false}
        destroyOnClose
        centered
        visible={openDeleteModal}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
        okButtonProps={{ loading: submitLoading }}
        cancelButtonProps={{}}
        onOk={onOkDeleteModal}
        className="!font-['Inter']">
        Bạn chắc chắn muốn xóa thời đoạn này chứ?
      </Modal>
    </>
  );
};

const TrafficBoxDetail: React.FC = () => {
  const { boxId } = useParams();
  const Component = React.useMemo(
    () => (
      <TrafficCabProvider boxIds={[boxId!]}>
        <TrafficBoxDetailData />
      </TrafficCabProvider>
    ),
    [boxId]
  );

  return <>{Component}</>;
};

export default TrafficBoxDetail;
