import { gql } from "@apollo/client";
import { BackTopProps } from "antd";
import { GraphQLDateTime, Paging } from "../types/common";
import {
  BoxDetailInput,
  DataStream,
  DataStreamInput,
  LightBox,
  LightBoxInfo,
  LightBoxInput,
  LightBoxRealTimeInfo,
  LightBoxTimeSlot,
  TimeSlotLightBoxInput,
} from "../types/lightBox";

export type GetLightBoxInRangeVars = {
  lat: number;
  lng: number;
  distance: number;
  paging?: Paging;
};
export type GetLightBoxInRangeData = {
  getLightBoxInRange: LightBox[];
  countLightBoxInRange: number;
};

export const GET_LIGHT_BOX_IN_RANGE = gql`
  query GET_LIGHT_BOX_IN_RANGE(
    $lat: Float!
    $lng: Float!
    $distance: Float!
    $paging: Paging
  ) {
    countLightBoxInRange(lat: $lat, lng: $lng, distance: $distance)
    getLightBoxInRange(
      lat: $lat
      lng: $lng
      distance: $distance
      paging: $paging
    ) {
      id
      lat
      lng
      address
      boxId
      PENumber
      name
    }
  }
`;

export const GET_LIGHT_BOX_IN_RANGE_FOR_MAP = gql`
  query GET_LIGHT_BOX_IN_RANGE_FOR_MAP(
    $lat: Float!
    $lng: Float!
    $distance: Float!
    $paging: Paging
  ) {
    countLightBoxInRange(lat: $lat, lng: $lng, distance: $distance)
    getLightBoxInRange(
      lat: $lat
      lng: $lng
      distance: $distance
      paging: $paging
    ) {
      boxId,
      lat
      lng
    }
  }
`;

export type searchLightBoxWithFilterData = {
  searchLightBoxWithFilter: LightBox[];
  countLightBoxWithFilter: number;
};
export type searchLightBoxWithFilterVars = {
  input: string;
  paging?: Paging;
};
export const SEARCH_LIGHT_BOX_WITH_FILTER = gql`
  query SEARCH_LIGHT_BOX_WITH_FILTER($input: String!, $paging: Paging) {
    countLightBoxWithFilter(input: $input)
    searchLightBoxWithFilter(input: $input, paging: $paging) {
      id
      boxId
      name
      PENumber
      address
      lat
      lng
      isConnected
    }
  }
`;
export type getAllLightBoxData = {
  getAllLightBox: LightBox[];
  countAllLightBox: number;
};
export type getAllLightBoxVars = {
  paging?: Paging;
};
export const GET_ALL_LIGHT_BOX = gql`
  query GET_ALL_LIGHT_BOX($paging: Paging) {
    countAllLightBox
    getAllLightBox(paging: $paging) {
      id
      boxId
      name
      PENumber
      address
      lat
      lng
      isConnected
    }
  }
`;
export type getLightBoxData = {
  getLightBox: LightBox;
};
export type getLightBoxVars = {
  input: BoxDetailInput;
};
export const GET_LIGHT_BOX = gql`
  query GET_LIGHT_BOX($input: BoxDetailInput!) {
    getLightBox(input: $input) {
      id
      boxId
      name
      PENumber
      address
      lat
      lng
    }
  }
`;

export type GetLightBoxTimeSlotsData = {
  getLightBoxTimeSlots: LightBoxTimeSlot[];
};
export type GetLightBoxTimeSlotsVars = {
  boxId: string;
};
export const GET_LIGHT_BOX_TIME_SLOTS = gql`
  query GET_LIGHT_BOX_TIME_SLOTS($boxId: String!) {
    getLightBoxTimeSlots(boxId: $boxId) {
      id
      startTime
      endTime
      power
    }
  }
`;
export type GetLightBoxInfoData = {
  getLightBoxInfo: LightBoxInfo[];
};
export type GetLightBoxInfoVars = {
  boxId: string;
};
export const GET_LIGHT_BOX_INFO = gql`
  query GET_LIGHT_BOX_INFO($boxId: String!) {
    getLightBoxInfo(boxId: $boxId) {
      status
      value
      type
    }
  }
`;

export type UpdateTimeSlotsLightBoxData = {
  updateTimeSlotsLightBox: LightBoxTimeSlot[];
};
export type UpdateTimeSlotsLightBoxVars = {
  boxId: string;
  data: TimeSlotLightBoxInput[];
};
export const UPDATE_TIME_SLOTS_LIGHT_BOX = gql`
  mutation UPDATE_TIME_SLOTS_LIGHT_BOX(
    $boxId: String!
    $data: [TimeSlotLightBoxInput!]!
  ) {
    updateTimeSlotsLightBox(boxId: $boxId, data: $data) {
      id
      startTime
      endTime
      power
    }
  }
`;

export const GET_ALL_SLIGHTING_LIGHTBOX_DATA = gql`
  query GET_ALL_SLIGHTING_LIGHTBOX_DATA($boxId: String!) {
    getLightBoxAllValue(boxId: $boxId) {
      tag
      value
    }
  }
`;

export const SET_LIGHT_BOX_VALUE = gql`
  mutation SET_LIGHT_BOX_VALUE($boxId: String!, $input: [DataStreamInput!]!) {
    setLightBoxValue(boxId: $boxId, input: $input)
  }
`;

export type setCurrentTimeLightBoxData = {
  setCurrentTimeLightBox: boolean;
};
export type setCurrentTimeLightBoxVars = {
  boxId: string;
  time: GraphQLDateTime;
};
export const SET_CURRENT_TIME_LIGHT_BOX = gql`
  mutation SET_CURRENT_TIME_LIGHT_BOX(
    $boxId: String!
    $time: GraphQLDateTime!
  ) {
    setCurrentTimeLightBox(boxId: $boxId, time: $time)
  }
`;

export type setLightBoxHighVoltageVars = {
  boxId: string;
  value: number;
};
export type setLightBoxHighVoltageData = {
  setLightBoxHighVoltage: boolean;
};
export const SET_LIGHT_BOX_HIGH_VOLTAGE = gql`
  mutation SET_LIGHT_BOX_HIGH_VOLTAGE($boxId: String!, $value: Float!) {
    setLightBoxHighVoltage(boxId: $boxId, value: $value)
  }
`;

export type setLightBoxLowVoltageVars = {
  boxId: string;
  value: number;
};
export type setLightBoxLowVoltageData = {
  setLightBoxLowVoltage: boolean;
};
export const SET_LIGHT_BOX_LOW_VOLTAGE = gql`
  mutation SET_LIGHT_BOX_LOW_VOLTAGE($boxId: String!, $value: Float!) {
    setLightBoxLowVoltage(boxId: $boxId, value: $value)
  }
`;

export type setLightBoxDelayVoltageVars = {
  boxId: string;
  value: number;
};
export type setLightBoxDelayVoltageData = {
  setLightBoxDelayVoltage: boolean;
};
export const SET_LIGHT_BOX_DELAY_VOLTAGE = gql`
  mutation SET_LIGHT_BOX_DELAY_VOLTAGE($boxId: String!, $value: Float!) {
    setLightBoxDelayVoltage(boxId: $boxId, value: $value)
  }
`;
export type setLightBoxDelayCurrentVars = {
  boxId: string;
  value: number;
};
export type setLightBoxDelayCurrentData = {
  setLightBoxDelayCurrent: boolean;
};
export const SET_LIGHT_BOX_DELAY_CURRENT = gql`
  mutation SET_LIGHT_BOX_DELAY_CURRENT($boxId: String!, $value: Float!) {
    setLightBoxDelayCurrent(boxId: $boxId, value: $value)
  }
`;

export type setLightBoxLowCurrentVars = {
  boxId: string;
  value: number;
};
export type setLightBoxLowCurrentData = {
  setLightBoxLowCurrent: boolean;
};
export const SET_LIGHT_BOX_LOW_CURRENT = gql`
  mutation SET_LIGHT_BOX_LOW_CURRENT($boxId: String!, $value: Float!) {
    setLightBoxLowCurrent(boxId: $boxId, value: $value)
  }
`;
export type setLightBoxHighCurrentVars = {
  boxId: string;
  value: number;
};
export type setLightBoxHighCurrentData = {
  setLightBoxHighCurrent: boolean;
};
export const SET_LIGHT_BOX_HIGH_CURRENT = gql`
  mutation SET_LIGHT_BOX_HIGH_CURRENT($boxId: String!, $value: Float!) {
    setLightBoxHighCurrent(boxId: $boxId, value: $value)
  }
`;
export type setLightBoxNoLoadVars = {
  boxId: string;
  value: number;
};
export type setLightBoxNoLoadData = {
  setLightBoxNoLoad: number;
};
export const SET_LIGHT_BOX_NO_LOAD = gql`
  mutation SET_LIGHT_BOX_NO_LOAD($boxId: String!, $value: Float!) {
    setLightBoxNoload(boxId: $boxId, value: $value)
  }
`;

// setLightBoxHighCurrent(boxId:String!, value: Float!): Float
// setLightBoxLowCurrent(boxId:String!, value: Float!): Float
// setLightBoxDelayCurrent(boxId:String!, value: Float!): Float
// setLightBoxNoload(boxId:String!, value: Float!): Float

export const SUBSCRIBE_LIGHTBOX_DATA = gql`
  subscription SUBSCRIBE_LIGHTBOX_DATA($boxId: String!, $fieldList: [String]) {
    getLightBoxInfo(boxId: $boxId, fieldList: $fieldList) {
      tag
      value
    }
  }
`;

export type getLightBoxVoltageData = {
  getLightBoxVoltageV1: LightBoxRealTimeInfo[];
  getLightBoxVoltageV2: LightBoxRealTimeInfo[];
  getLightBoxVoltageV3: LightBoxRealTimeInfo[];
  getLightBoxVoltageVA: LightBoxRealTimeInfo[];
  getLightBoxConsumptionData: LightBoxRealTimeInfo[];
};
export type getLightBoxVoltageVars = {
  boxId: string;
  startTime: GraphQLDateTime;
  endTime?: GraphQLDateTime;
};
export const GET_LIGHT_BOX_VOLTAGE = gql`
  query GET_LIGHT_BOX_VOLTAGE(
    $boxId: String!
    $startTime: GraphQLDateTime!
    $endTime: GraphQLDateTime
  ) {
    getLightBoxVoltageV1(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
    getLightBoxVoltageV2(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
    getLightBoxVoltageV3(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
    getLightBoxVoltageVA(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
    getLightBoxConsumptionData(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
  }
`;
export type getLightBoxConsumptionDataData = {
  getLightBoxConsumptionData: LightBoxRealTimeInfo[];
};
export type getLightBoxConsumptionDataVars = {
  boxId: string;
  startTime: string;
  endTime?: string;
};
export const GET_LIGHT_BOX_CONSUMPTION_DATA = gql`
  query GET_LIGHT_BOX_CONSUMPTION_DATA(
    $boxId: String!
    $startTime: GraphQLDateTime!
    $endTime: GraphQLDateTime
  ) {
    getLightBoxConsumptionData(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
  }
`;

// subcription
// GET_LIGHT_BOX_VOLTAGE_SUBCRI
export type getLightBoxVoltageInfoData = {
  getLightBoxVoltageInfo: LightBoxInfo[];
};
export type getLightBoxVoltageInfoVars = {
  boxId: string;
};
export const GET_LIGHT_BOX_VOLTAGE_INFO = gql`
  query GET_LIGHT_BOX_VOLTAGE_INFO($boxId: String!) {
    getLightBoxVoltageInfo(boxId: $boxId) {
      status
      value
      type
    }
  }
`;
export type getLightBoxCurrentInfoData = {
  getLightBoxCurrentInfo: LightBoxInfo[];
};
export type getLightBoxCurrentInfoVars = {
  boxId: string;
};
export const GET_LIGHT_BOX_CURRENT_INFO = gql`
  query GET_LIGHT_BOX_CURRENT_INFO($boxId: String!) {
    getLightBoxCurrentInfo(boxId: $boxId) {
      status
      value
      type
    }
  }
`;
export type getLightBoxCurrentData = {
  getLightBoxCurrentI1: LightBoxRealTimeInfo[];
  getLightBoxCurrentI2: LightBoxRealTimeInfo[];
  getLightBoxCurrentI3: LightBoxRealTimeInfo[];
  getLightBoxCurrentIA: LightBoxRealTimeInfo[];
  getLightBoxConsumptionData: LightBoxRealTimeInfo[];
};
export type getLightBoxCurrentVars = {
  boxId: string;
  startTime: GraphQLDateTime;
  endTime?: GraphQLDateTime;
};
export const GET_LIGHT_BOX_CURRENT = gql`
  query GET_LIGHT_BOX_CURRENT(
    $boxId: String!
    $startTime: GraphQLDateTime!
    $endTime: GraphQLDateTime
  ) {
    getLightBoxCurrentI1(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
    getLightBoxCurrentI2(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
    getLightBoxCurrentI3(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
    getLightBoxCurrentIA(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
    getLightBoxConsumptionData(
      boxId: $boxId
      startTime: $startTime
      endTime: $endTime
    ) {
      boxId
      value
      time
    }
  }
`;
