import { gql } from "@apollo/client";
import {
  AdBoxGroup,
  AdBoxGroupContentInput,
  AdBoxGroupInput,
} from "../types/adBoxGroup";
import { Paging } from "../types/common";

export type adBoxGroupAllData = {
  adBoxGroupAll: AdBoxGroup[];
  adBoxGroupAllCount: number;
};
export type adBoxGroupAllVars = {
  paging?: Paging;
};
export const AD_BOX_GROUP_ALL = gql`
  query AD_BOX_GROUP_ALL($paging: Paging) {
    adBoxGroupAllCount
    adBoxGroupAll(paging: $paging) {
      id
      name
      adBoxs {
        lat
        lng
        adBoxId
        id
      }
    }
  }
`;
export const AD_BOX_GROUP_ALL_COUNT = gql`
  query AD_BOX_GROUP_ALL_COUNT {
    adBoxGroupAllCount
  }
`;

export type adBoxGroupData = {
  adBoxGroup: AdBoxGroup;
};
export type adBoxGroupVars = {
  id: number;
};
export const AD_BOX_GROUP = gql`
  query AD_BOX_GROUP($id: Int!) {
    adBoxGroup(id: $id) {
      id
      name
      contents {
        id
        video {
          id
          filename
          rawUrl
          thumbnailUrl
        }
        order
      }
      onTime
      offTime
      adBoxs {
        id
        name
        lat
        lng
        adBoxId
        serialNumber
        province {
          name
        }
      }
    }
  }
`;
export const AD_BOX_GROUP_FIND_BY_NAME = gql`
  query AD_BOX_GROUP_FIND_BY_NAME(
    $input: String!
    $district_id: Int
    $paging: Paging
  ) {
    adBoxGroupFindByName(
      input: $input
      district_id: $district_id
      paging: $paging
    ) {
      id
    }
  }
`;

// type Query {
//   adBoxGroupAll(paging: Paging): [AdBoxGroup]
//   adBoxGroupAllCount: Int
//   adBoxGroup(id: Int!): AdBoxGroup
//   adBoxGroupFindByName(input: String!, district_id: Int, paging: Paging): [AdBoxGroup]
// }

// type Mutation {
//   addAdBoxGroup(input: AdBoxGroupInput!): AdBoxGroup
//   updateAdBoxGroupInfo(id: Int!, input: AdBoxGroupInput!): AdBoxGroup
//   updateAdBoxGroupContent(id: Int!, content: [AdBoxGroupContentInput!]!): Boolean
//   deleteAdBoxGroup(id: Int!): Boolean
// }
export type addAdBoxGroupData = {
  addAdBoxGroup: AdBoxGroup;
};
export type addAdBoxGroupVars = {
  input: AdBoxGroupInput;
};
export const ADD_AD_BOX_GROUP = gql`
  mutation ADD_AD_BOX_GROUP($input: AdBoxGroupInput!) {
    addAdBoxGroup(input: $input) {
      id
    }
  }
`;

export type updateAdBoxGroupInfoData = {
  updateAdBoxGroupInfo: AdBoxGroup;
};
export type updateAdBoxGroupInfoVars = {
  id: number;
  input: AdBoxGroupInput;
};
export const UPDATE_AD_BOX_GROUP_INFO = gql`
  mutation UPDATE_AD_BOX_GROUP_INFO($id: Int!, $input: AdBoxGroupInput!) {
    updateAdBoxGroupInfo(id: $id, input: $input) {
      id
    }
  }
`;

export type updateAdBoxGroupContentData = {
  updateAdBoxGroupContent: boolean;
};
export type updateAdBoxGroupContentVars = {
  id: number;
  content: AdBoxGroupContentInput[];
};
export const UPDATE_AD_BOX_GROUP_CONTENT = gql`
  mutation UPDATE_AD_BOX_GROUP_CONTENT(
    $id: Int!
    $content: [AdBoxGroupContentInput!]!
  ) {
    updateAdBoxGroupContent(id: $id, content: $content) {
      id
      video {
        id
        filename
        rawUrl
        thumbnailUrl
      }
      order
    }
  }
`;

export const DELETE_AD_BOX_GROUP = gql`
  mutation DELETE_AD_BOX_GROUP($id: Int!) {
    deleteAdBoxGroup(id: $id)
  }
`;
// updateAdBoxGroupInfo(id: Int!, input: AdBoxGroupInput!): AdBoxGroup
