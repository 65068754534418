import { Select, TimePicker } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { formatTime, options } from '../..';
import { TimeSlotAjust } from '../EditModal';
import './ontime-select.scss';

interface IOnTimeSelectProps {
    item?: TimeSlotAjust;
    index?: number;
    callbackChangeTime: (id: number, time: moment.Moment | null) => void;
    callbackChangePower: (id: number, value: string) => void;
    enableCheck?: boolean;
}
const { Option } = Select;
const OnTimeSelect: React.FunctionComponent<IOnTimeSelectProps> = ({ item, index = -1, enableCheck = true, callbackChangeTime, callbackChangePower, ...props }) => {
    const onChangeTime = (value: moment.Moment | null) => {
        callbackChangeTime(index, value);
    }
    const onChangePower = (value: string) => {
        callbackChangePower(index, value);
    }
    return <div className='ontime-select border rounded-[4px] overflow-hidden border-neutral-L70 pl-[19px] flex justify-center items-center h-[40px] w-[192px]'>
        <TimePicker
            suffixIcon={false}
            bordered={false}
            clearIcon={<></>}
            value={moment(item?.startTime, formatTime)}
            format={formatTime}
            onChange={onChangeTime}
        />
        <div className='h-[16px] w-[1px] bg-neutral-L90'></div>
        <Select
            style={{
                border: 'none',
                borderRadius: 0,
                backgroundColor: 'transparent',
                height: '40px',
                
                width: '90px',
                textAlign:'right'
            }}
            value={options.find((itemx) => itemx.key === item?.power)?.value}
            bordered={false}
            onChange={onChangePower}
            suffixIcon={false}>
            {options.map(item => (<Option key={item.key}>{item.value}</Option>))}
        </Select>
    </div>
};

export default OnTimeSelect;
