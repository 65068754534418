import * as d3 from "d3";
import { max, ScaleLinear, ScaleTime } from "d3";
import moment from "moment";
import * as React from "react";
import { getTimeBefore } from "../..";
import { Neutral, Variant } from "../../../../../../../../constants/colors";
import { LightBoxRealTimeInfo } from "../../../../../../../../graphql/types/lightBox";
import "./voltageChart.scss";
const padding = {
  top: 12,
  right: 32,
  bottom: 32,
  left: 48,
};
const LOW_VOLTAGE_WARNING = 200;
const HIGHT_VOLTAGE_WARNING = 220;
const RATIO = 63 / 351;

const timeFormatter = d3.timeFormat("%H:%M");
export interface IVoltageChartData {
  V1: LightBoxRealTimeInfo[];
  V2: LightBoxRealTimeInfo[];
  V3: LightBoxRealTimeInfo[];
}
interface IVoltageChartProps {
  width?: number;
  height?: number;
  data: IVoltageChartData;
  lowVoltage: number;
  highVoltage: number;
}

const parseToDataFormat = (d: LightBoxRealTimeInfo[]): [number, number][] => {
  const rs: [number, number][] = d.map((item) => {
    const date = new Date(item.time);
    return [date.getTime(), item.value ? item.value : 0];
  });
  return rs;
};
const getDomain = (
  low = LOW_VOLTAGE_WARNING,
  high = HIGHT_VOLTAGE_WARNING,
  ratio = RATIO
): [number, number] => {
  let min = (low - (low + high) * ratio) / (1 - 2 * ratio);
  let max = low + high - min;
  return [max, min];
};
const VoltageChart: React.FunctionComponent<IVoltageChartProps> = ({
  width = 700,
  height = 395,
  data,
  lowVoltage,
  highVoltage,
}) => {
  let svgRef = React.createRef<SVGSVGElement>();
  const chartRef = React.createRef<HTMLDivElement>();
  let scaleLine = d3.line();
  let xScale: ScaleTime<number, number, never> = d3.scaleTime();
  let yScale: ScaleLinear<number, number, never> = d3.scaleLinear();
  const dataLength = React.useMemo(() => {
    return data.V1.length;
  }, [data]);

  React.useEffect(() => {
    initLineChart();
    updateHighThreadhold();
    updateLowThreadhold();
  }, [highVoltage, lowVoltage, data]);

  React.useEffect(() => {
    const { V1 } = data;
    if (V1.length <= 0) return;
    appendDataLineV1();
  }, [data.V1, highVoltage, lowVoltage]);
  React.useEffect(() => {
    const { V2 } = data;
    if (V2.length <= 0) return;
    appendDataLineV2();
  }, [data.V2, highVoltage, lowVoltage]);
  React.useEffect(() => {
    const { V3 } = data;
    if (V3.length <= 0) return;
    appendDataLineV3();
  }, [data.V3, highVoltage, lowVoltage]);

  const appendXAxis = () => {
    const SVGElement = d3.select(svgRef.current);
    const xAxis: d3.Axis<Date> = d3
      .axisBottom<Date>(xScale)
      .ticks(d3.timeHour.every(2))
      .tickFormat((d) => timeFormatter(d))
      .tickPadding(17)
      .tickSize(height - padding.bottom - padding.top);
    SVGElement.select(".xAxis").remove();
    SVGElement.append("g")
      .classed("xAxis", true)
      .attr("stroke", "transparent")
      .attr("transform", `translate(0, ${padding.top})`)
      .call(xAxis);
  };
  const appendYAxis = () => {
    const SVGElement = d3.select(svgRef.current);
    const yAxis: d3.Axis<d3.NumberValue> = d3
      .axisLeft<d3.NumberValue>(yScale)
      .tickFormat((d, i) => {
        if (i === 0) {
          return d + " V";
        }
        return d.toString();
      })
      .tickPadding(8)
      .tickSize(width - padding.right - padding.left);
    SVGElement.select(".yAxis").remove();
    SVGElement.append("g")
      .classed("yAxis", true)
      .attr("transform", `translate(${width - padding.right}, 0)`)
      .call(yAxis);
  };

  const appendDataLineV1 = () => {
    const { V1 } = data;
    if (V1.length === 0) return;
    // let strokeColor = '';
    const SVGElement = d3.select(svgRef.current);
    SVGElement.selectAll(".data-line1").remove();
    SVGElement.selectAll(".line")
      .data([parseToDataFormat(V1)])
      .join("path")
      .attr("class", "data-line1")
      .attr("d", (d) => scaleLine(d))
      .attr("fill", "none")
      .style("stroke-width", 2)
      .attr("clip-path", "url(#clip)")
      .attr("stroke", Variant.Success);
  };
  const appendDataLineV2 = () => {
    const { V2 } = data;
    if (V2.length === 0) return;
    const SVGElement = d3.select(svgRef.current);
    SVGElement.selectAll(".data-line2").remove();
    SVGElement.selectAll(".line")
      .data([parseToDataFormat(V2)])
      .join("path")
      .attr("class", "data-line2")
      .attr("d", (d) => scaleLine(d))
      .attr("fill", "none")
      .style("stroke-width", 2)
      .attr("clip-path", "url(#clip)")
      .attr("stroke", Variant.Warning);
  };
  const appendDataLineV3 = () => {
    const { V3 } = data;
    if (V3.length === 0) return;
    const SVGElement = d3.select(svgRef.current);
    SVGElement.selectAll(".data-line3").remove();
    SVGElement.selectAll(".line")
      .data([parseToDataFormat(V3)])
      .join("path")
      .attr("class", "data-line3")
      .attr("d", (d) => scaleLine(d))
      .attr("fill", "none")
      .style("stroke-width", 2)
      .attr("clip-path", "url(#clip)")
      .attr("stroke", Variant.Error);
  };
  const updateHighThreadhold = () => {
    const SVGElement = d3.select(svgRef.current);
    SVGElement.select(".top-line").remove();
    SVGElement.select(".rect1").remove();
    SVGElement.select(".rectFilled1").remove();
    SVGElement.select("#mainGradient1").remove();
    SVGElement.append("rect")
      .classed("rectFilled1", true)
      .attr("x", padding.left)
      .attr("y", padding.top)
      .attr("width", width - padding.right - padding.left)
      .attr("height", 53);
    SVGElement.append("rect")
      .classed("rect1", true)
      .attr("x", padding.left)
      .attr("y", padding.top + 53)
      .attr("height", 10)
      .attr("width", width - padding.left - padding.right)
      .style("fill", "#EF5E0D20");
    const mainGradient1 = SVGElement.append("linearGradient")
      .attr("id", "mainGradient1")
      .attr("x1", "0%")
      .attr("y1", "60%")
      .attr("x2", "0%")
      .attr("y2", "100%");
    mainGradient1.append("stop").attr("class", "stop-top1").attr("offset", "0");

    mainGradient1
      .append("stop")
      .attr("class", "stop-bottom1")
      .attr("offset", "1");
    SVGElement.append("line")
      .classed("top-line", true)
      .style("stroke", "#EF5E0D")
      .attr("stroke-dasharray", "5,5")
      .attr("x1", padding.left)
      .attr("x2", width - padding.right)
      .attr("y1", 63 + padding.top)
      .attr("y2", 63 + padding.top);
  };
  const updateLowThreadhold = () => {
    const SVGElement = d3.select(svgRef.current);
    SVGElement.select(".bottom-line").remove();
    SVGElement.select(".rect2").remove();
    SVGElement.select(".rectFilled2").remove();
    SVGElement.select("#mainGradient2").remove();
    SVGElement.append("rect")
      .classed("rectFilled2", true)
      .attr("x", padding.left)
      .attr("y", height - padding.bottom - 53)
      .attr("width", width - padding.right - padding.left)
      .attr("height", 53);
    SVGElement.append("rect")
      .classed("rect2", true)
      .attr("x", padding.left)
      .attr("y", height - padding.bottom - padding.top - 53 + 2)
      .attr("height", 10)
      .attr("width", width - padding.left - padding.right)
      .style("fill", "#EF5E0D20");
    const mainGradient2 = SVGElement.append("linearGradient")
      .attr("id", "mainGradient2")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "40%");
    mainGradient2
      .append("stop")
      .attr("class", "stop-bottom1")
      .attr("offset", "0");

    mainGradient2.append("stop").attr("class", "stop-top1").attr("offset", "1");
    SVGElement.append("line")
      .classed("bottom-line", true)
      .style("stroke", "#EF5E0D")
      .attr("stroke-dasharray", "5,5")
      .attr("x1", padding.left)
      .attr("x2", width - padding.right)
      .attr("y1", height - padding.bottom - 63)
      .attr("y2", height - padding.bottom - 63);
  };

  const addClipPath = () => {
    const SVGElement = d3.select(svgRef.current);
    SVGElement.append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", width - padding.right)
      .attr("height", height - padding.bottom - padding.top)
      .attr("transform", `translate(0, ${padding.top})`);
  };
  const initLineChart = () => {
    addClipPath();
    const SVGElement = d3.select(svgRef.current);
    if (dataLength <= 0) return;
    const minTime: Date = new Date(data.V1[0].time || "");
    // const minTime = getTimeBefore(1);
    const maxTime: Date = new Date(data.V1[dataLength - 1].time || "");
    // const separate = (maxTime.getTime() - minTime.getTime()) / 30;
    xScale
      .domain([minTime, maxTime])
      .range([padding.left, width - padding.right]);
    yScale
      .domain(getDomain(lowVoltage, highVoltage))
      .range([padding.top, height - padding.bottom]);
    appendYAxis();
    appendXAxis();
    scaleLine
      .x((d) => xScale(new Date(d[0])))
      .y((d) => yScale(d[1]))
      .curve(d3.curveStepAfter);

    SVGElement.selectAll("line").attr("stroke", Neutral.L50);
    SVGElement.append("line")
      .attr("stroke", Neutral.L70)
      .attr("x1", padding.left)
      .attr("y1", height - padding.bottom)
      .attr("x2", width - padding.right)
      .attr("y2", height - padding.bottom);
    d3.selectAll(".tick>text")
      .attr("color", Neutral.D50)
      .attr("font-size", "12px")
      .attr("font-weight", 400);
  };

  return (
    <div className="w-full h-full" ref={chartRef}>
      <svg ref={svgRef} width={"100%"} viewBox={`0 0 ${width} ${height}`} />
    </div>
  );
};

export default VoltageChart;
