import { Select, TimePicker } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { formatTime, options } from '../..';
import TwCheckbox from '../../../../../../../../components/Input/TwCheckbox';
import CustomSelect from '../CustomSelect';
import { TimeSlotAjust } from '../EditModal';
import './power-select.scss';

interface IPowerSelectProps {
    item?: TimeSlotAjust;
    index?: number;
    callbackChangeTime: (id: number, time: moment.Moment | null) => void;
    callbackChangePower: (id: number, value: string) => void;
    callbackSelect: (value: any, id: number) => void;
    enableCheck?: boolean;
}
const { Option } = Select;
const PowerSelect: React.FunctionComponent<IPowerSelectProps> = ({ item, index = -1, enableCheck = true, callbackChangeTime, callbackChangePower, callbackSelect, ...props }) => {
    const onChangeTime = (value: moment.Moment | null) => {
        callbackChangeTime(index, value);
    }
    const onChangePower = (value: string) => {
        callbackChangePower(index, value);
    }
    const onSelectPower = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        callbackSelect(checked, index);
    }
    return <div className='power-select border border-neutral-L70 rounded-[4px] overflow-hidden h-[40px] flex items-center justify-center pl-[16px] w-[192px]'>
        <TwCheckbox checked={item?.selected} onChange={onSelectPower} />
        <TimePicker
            bordered={false}
            suffixIcon={false}
            clearIcon={<></>}
            value={moment(item?.startTime, formatTime)}
            format={formatTime}
            onChange={onChangeTime}
        />
        <CustomSelect
            value={
                options.find((itemx) => itemx.key === item?.power)?.value
            }
            onChange={onChangePower}>
            {
                options.map(item => (
                    <Option key={item.key}>{item.value}</Option>
                ))
            }
        </CustomSelect>
    </div>
};

export default PowerSelect;
