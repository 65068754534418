import * as React from "react";
import AdboardMarkerIcon from "../../../../../components/Icons/AdboardIcon.svg";
import { Marker, MarkerProps } from "react-leaflet";
import L, { LeafletMouseEvent } from "leaflet";
import { AdBox } from "../../../../../graphql/types/adBox";
import { useLocation, useNavigate } from "react-router-dom";

const adboardIconMarker = new L.Icon({
  iconUrl: AdboardMarkerIcon,
  iconRetinaUrl: AdboardMarkerIcon,
  iconAnchor: undefined,
  popupAnchor: undefined,
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(24, 24),
  className: "electrical-box-icon",
});
interface AdsMarkerProps {
  ads: AdBox;
}

export function AdsMarker(props: AdsMarkerProps) {
  const { ads } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const handleContextMenu = (e: LeafletMouseEvent) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
  };

  const handleClick = (e: LeafletMouseEvent) => {
    e.originalEvent.stopPropagation();
    if (location.pathname.split("/").at(-1) === "ads") {
      navigate(ads.id!.toString());
    }
  };

  return (
    <Marker
      position={[ads.lat!, ads.lng!]}
      icon={adboardIconMarker}
      eventHandlers={{
        click: handleClick,
        contextmenu: handleContextMenu,
      }}
    />
  );
}

export default AdsMarker;
