const LogoDark = () =><svg width={79} height={16} viewBox="0 0 79 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_404_3726)">
      <path d="M18.0816 0C13.6434 0 10.0458 3.58128 10.0458 7.99929C10.0458 12.4173 13.6434 15.9986 18.0816 15.9986C22.5197 15.9986 26.1175 12.4173 26.1175 7.99929C26.1175 3.58128 22.5197 0 18.0816 0ZM18.0816 13.0001C15.3075 13.0001 13.0594 10.7621 13.0594 8.00071C13.0594 5.23926 15.3075 3.00132 18.0816 3.00132C20.8557 3.00132 23.1038 5.23926 23.1038 8.00071C23.1038 10.7621 20.8557 13.0001 18.0816 13.0001Z" fill="url(#paint0_linear_404_3726)" />
      <path d="M13.0595 8.00071C13.0595 9.61356 12.2912 11.0472 11.1005 11.9601C10.2528 12.6106 9.18966 13.0001 8.03583 13.0001C5.26177 13.0001 3.01361 10.7621 3.01361 8.00071C3.01361 5.23926 5.26177 3.00132 8.03583 3.00132C9.18966 3.00132 10.2514 3.38936 11.1005 4.04128C12.2912 4.95282 13.0595 6.38644 13.0595 8.00071C13.0595 6.38787 13.8277 4.95423 15.0184 4.03987C14.5138 3.16078 13.849 2.3875 13.0595 1.75818C11.6831 0.658964 9.93669 0 8.03583 0C3.59763 0 0 3.58128 0 7.99929C0 12.4173 3.59763 15.9986 8.03583 15.9986C9.93669 15.9986 11.6831 15.3396 13.058 14.2418C13.8462 13.6125 14.5124 12.8378 15.017 11.9601C13.8263 11.0458 13.0595 9.61213 13.0595 8.00071Z" fill="#13E2E2" />
      <path d="M33.5778 11.8247C35.8624 11.8247 37.298 10.4647 37.4096 8.46424H35.1643C35.0247 9.39362 34.4214 9.91498 33.6059 9.91498C32.4943 9.91498 31.7738 8.96858 31.7738 7.30251C31.7738 5.65909 32.4999 4.71838 33.6059 4.71838C34.4773 4.71838 35.0359 5.30207 35.1643 6.16912H37.4096C37.3092 4.15734 35.8067 2.83695 33.5668 2.83695C30.9639 2.83695 29.3552 4.66737 29.3552 7.33652C29.3552 9.98298 30.9359 11.8247 33.5778 11.8247Z" fill="#E8E9EC" />
      <path d="M38.9332 11.6547H41.3125V2.9503H38.9332V11.6547Z" fill="#E8E9EC" />
      <path d="M47.782 2.95031H46.1679V0.864862H43.7884V2.95031H42.6152V4.76374H43.7884V9.29729C43.7771 11.003 44.9222 11.8474 46.6481 11.7737C47.2626 11.7511 47.6982 11.6264 47.9385 11.5471L47.5642 9.75066C47.447 9.77332 47.1955 9.82999 46.9721 9.82999C46.4972 9.82999 46.1679 9.64865 46.1679 8.97995V4.76374H47.782V2.95031Z" fill="#E8E9EC" />
      <path d="M49.332 11.6547H51.7115V2.9503H49.332V11.6547Z" fill="#E8E9EC" />
      <path d="M60.756 5.43241C60.5438 3.82867 59.2703 2.83695 57.103 2.83695C54.9078 2.83695 53.4612 3.86832 53.4668 5.54575C53.4612 6.84914 54.2711 7.69353 55.9469 8.03353L57.4326 8.3339C58.1811 8.48689 58.5217 8.76458 58.533 9.20093C58.5217 9.71662 57.9687 10.085 57.1366 10.085C56.2875 10.085 55.7235 9.71662 55.5782 9.00826L53.2378 9.13293C53.4612 10.799 54.8577 11.8247 57.131 11.8247C59.354 11.8247 60.9459 10.6744 60.9515 8.95725C60.9459 7.6992 60.1361 6.94549 58.4714 6.5998L56.9186 6.28244C56.12 6.10679 55.8239 5.8291 55.8295 5.40975C55.8239 4.88839 56.4049 4.54836 57.1423 4.54836C57.9687 4.54836 58.4604 5.00739 58.5776 5.56841L60.756 5.43241Z" fill="#E8E9EC" />
      <path d="M63.919 14.9189C65.6896 14.9189 66.6279 14.0009 67.1138 12.5954L70.4484 2.96163L67.9295 2.9503L66.1364 9.34263H66.0471L64.2709 2.9503H61.7686L64.8461 11.9041L64.7064 12.2724C64.3937 13.0885 63.796 13.1281 62.9583 12.8675L62.4219 14.6696C62.7627 14.8169 63.3157 14.9189 63.919 14.9189Z" fill="#E8E9EC" />
      <path d="M78.8044 5.43241C78.5922 3.82867 77.3187 2.83695 75.1514 2.83695C72.9563 2.83695 71.5097 3.86832 71.5153 5.54575C71.5097 6.84914 72.3195 7.69353 73.9953 8.03353L75.481 8.3339C76.2296 8.48689 76.5702 8.76458 76.5814 9.20093C76.5702 9.71662 76.0172 10.085 75.1851 10.085C74.336 10.085 73.7719 9.71662 73.6267 9.00826L71.2863 9.13293C71.5097 10.799 72.9062 11.8247 75.1795 11.8247C77.4025 11.8247 78.9944 10.6744 79 8.95725C78.9944 7.6992 78.1845 6.94549 76.5199 6.5998L74.9671 6.28244C74.1685 6.10679 73.8723 5.8291 73.8779 5.40975C73.8723 4.88839 74.4534 4.54836 75.1905 4.54836C76.0172 4.54836 76.5089 5.00739 76.6261 5.56841L78.8044 5.43241Z" fill="#E8E9EC" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_404_3726" x1="10.0458" y1="7.9185" x2="26.1175" y2="7.9185" gradientUnits="userSpaceOnUse">
        <stop stopColor="#103DDA" />
        <stop offset={1} stopColor="#13E2E2" />
      </linearGradient>
      <clipPath id="clip0_404_3726">
        <rect width={79} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>

export default LogoDark;
