import { useLazyQuery } from "@apollo/client";
import * as React from "react";
import { ControlContext } from "../../../../context/ControlContext";
import {
  GetTrafficBoxInRangeData,
  GetTrafficBoxInRangeVars,
  GET_TRAFFIC_BOX_IN_RANGE,
} from "../../../../graphql/schema/trafficBox";
import { TrafficBox } from "../../../../graphql/types/trafficBox";
import { usePreventExceed } from "../../../../hooks/usePreventExceed";
import TrafficBoxMarker from "./components/TrafficBoxMarker";

const TrafficTabMap: React.FC = () => {
  const { center, distanceMap } = React.useContext(ControlContext)!;
  const [trafficBoxs, setTrafficBoxs] = React.useState<TrafficBox[]>([]);
  const [getTrafficBoxInRangeLazy] = useLazyQuery<
    GetTrafficBoxInRangeData,
    GetTrafficBoxInRangeVars
  >(GET_TRAFFIC_BOX_IN_RANGE, {
    variables: {
      distance: distanceMap,
      lat: center?.[0]!,
      lng: center?.[1]!,
    },
    onCompleted(data) {
      setTrafficBoxs(data.getTrafficBoxInRange);
    },
  });
  usePreventExceed(getTrafficBoxInRangeLazy, [center, distanceMap]);
  return (
    <>
      {trafficBoxs.map((item, idx) => {
        return <TrafficBoxMarker trafficBox={item} key={`lightbox-${idx}`} />;
      })}
    </>
  );
};

export default TrafficTabMap;
