import * as React from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { ControlContext } from "../../context/ControlContext";

export const ControlMapContainer: React.FC = ({ children }) => {
  const { center, setCenter } = React.useContext(ControlContext)!;
  return (
    <MapContainer
      center={center}
      zoom={15}
      className=" h-screen w-full relative">
      {children}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MapEventFC setCenterCallback={setCenter} />
      <Marker draggable position={center!}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
  );
};

interface IMapEventFCProps {
  setCenterCallback?: (center: [number, number]) => void;
}
const MapEventFC: React.FC<IMapEventFCProps> = ({ setCenterCallback }) => {
  const map = useMapEvents({
    dragend: () => {
      const { lat, lng } = map.getCenter();
      setCenterCallback && setCenterCallback([lat, lng]);
    },
  });
  return <></>;
};
