
import * as React from 'react';
import { Body3 } from '../../../../../../../components/Text';

interface ITimeSlotBadgeProps {
    time: string;
    power?: number;
}

const TimeSlotBadge: React.FunctionComponent<ITimeSlotBadgeProps> = ({ power, time }) => {
    return <div className='h-[40px] w-[176px] border border-neutral-L70 rounded-sm px-[20px] flex items-center shrink-0'>
        <div className='h-[17px] flex flex-auto divide-x'>
            <Body3 className='flex-auto text-center text-neutral-D90'>{time}</Body3>
            <Body3 className='flex-auto text-center text-neutral-D90'>{power || 0}%</Body3>
        </div>
    </div>;
};
const TimeEndBadge: React.FunctionComponent<ITimeSlotBadgeProps> = ({ power, time }) => {
    return <div className='h-[40px] w-[176px] border border-neutral-L70 rounded-sm px-[20px] flex items-center'>
        <Body3 className='flex-auto text-center text-neutral-D90'>{time}</Body3>
    </div>;
};

export { TimeEndBadge };

export default TimeSlotBadge;
