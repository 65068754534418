import { ApolloClient, gql, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { SUBSCRIPTION_LINK, UPLOAD_LINK } from "../config";
import { GraphQLError } from "graphql";

const link = createUploadLink({
  uri: UPLOAD_LINK,
});
let cache = new InMemoryCache();

export const client = new ApolloClient({
  cache: cache,
});

export function deauth() {
  localStorage.clear();
  window.location.href = "/login";
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error: any) => {
      if (error.statusCode === 401) {
        deauth();
      }
    });
  }
});

const getToken = () => {
  let token = "";
  const cachedResult = client.readQuery({
    query: gql`
      query GetUser {
        getuser {
          token
          user {
            role
          }
        }
      }
    `,
  });

  if (cachedResult === null || cachedResult.token === "") {
    const localToken = localStorage.getItem("CTS_ACCESS_TOKEN");
    const role = localStorage.getItem("role");
    const id = localStorage.getItem("CTS_UID");
    if (localToken && id) {
      client.writeQuery({
        query: gql`
          query GetUser {
            getuser {
              token
              user {
                id
                role
              }
            }
          }
        `,
        data: {
          getuser: {
            token: localToken,
            user: {
              role,
              id,
            },
          },
        },
      });
      token = localToken;
    }
  } else {
    token = cachedResult.getuser.token;
  }
  return token;
};

const getWsLink = () => {
  const token = getToken();
  return new WebSocketLink(
    new SubscriptionClient(SUBSCRIPTION_LINK, {
      reconnect: true,
      // lazy: true,
      connectionParams: {
        authToken: token,
      },
    })
  );
};

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const wsLink = getWsLink();
window.addEventListener("beforeunload", () => {
  // @ts-ignore - the function is private in typescript
  wsLink.subscriptionClient.close();
});
const httpLink = authLink.concat(link);
const splitLink = from([
  errorLink,
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  ),
]);
client.setLink(splitLink);
