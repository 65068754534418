import * as React from "react";
import { IIconProps } from "./IIconProps";

const ArrowRight2: React.FunctionComponent<IIconProps> = ({
  size = "sm",
  color = "currentColor",
}) => {
  return (
    <>
      <svg
        width={
          size === "sm" ? 16 : size === "md" ? 24 : size === "lg" ? 32 : 24
        }
        height={
          size === "sm" ? 16 : size === "md" ? 24 : size === "lg" ? 32 : 24
        }
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="5" y1="12" x2="19" y2="12"></line>
        <polyline points="12 5 19 12 12 19"></polyline>
      </svg>
    </>
  );
};

export default ArrowRight2;
