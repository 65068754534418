import { gql } from "@apollo/client";
import { User } from "../types/user";

export const LOGIN = gql`
  mutation LOGIN($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      userId
      isFirstLogin
      isCheck2FA
      message
    }
  }
`;

export const ADD_PHONE_NUMBER = gql`
  mutation ADD_PHONE_NUMBER($id: String!, $phoneNumber: String!) {
    addPhoneNumber(id: $id, phoneNumber: $phoneNumber)
  }
`;

export const UPDATE_PHONE_NUMBER = gql`
  mutation UPDATE_PHONE_NUMBER(
    $id: String!
    $phoneNumber: String!
    $otp: String!
  ) {
    updatePhoneNumber(id: $id, phoneNumber: $phoneNumber, otp: $otp)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($userInput: ChangePasswordInput!) {
    changePassword(userInput: $userInput)
  }
`;

export type getCurrentUserData = {
  getCurrentUser: User;
};
export type getCurrentUserVars = {};
export const GET_CURRENT_USER = gql`
  query GET_CURRENT_USER {
    getCurrentUser {
      permissions {
        field
        actions
      }
    }
  }
`;
