import ReactDOM from "react-dom";
import "./App.scss";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/auth";
import { BrowserRouter } from "react-router-dom";
import CommonProvider from "./context/CommonContext";
import AuthProvider from "./context/AuthContext";
ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <AuthProvider>
        <CommonProvider>
            <App />
        </CommonProvider>
      </AuthProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);
reportWebVitals();
