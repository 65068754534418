import moment from "moment";
import * as React from "react";
import { createContext, useState } from "react";

export const CommonContext = createContext<any>(null);
const CommonProvider: React.FC = ({ children }) => {
  const [PLCTime, setPLCTime] = useState<moment.Moment>(moment());
  const [detailBreadscrumName, setDetailBreadscrumName] = useState("");
  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState({
    "/control/lightbox": "Danh sách tủ điện",
    "/control/traffic": "Giao thông",
    "/control/camera": "Camera",
    "/control/ads": "Quảng cáo",
    "/control/tree": "Cây xanh",
    "/control/lightbox/detail": "Chi tiết tủ điện",
  });
  const data = {
    breadscrumb: {
      breadcrumbNameMap,
      setBreadcrumbNameMap,
      detailBreadscrumName,
      setDetailBreadscrumName,
    },
    PLCTime,
    setPLCTime,
  };
  return (
    <CommonContext.Provider value={data}>{children}</CommonContext.Provider>
  );
};

export default CommonProvider;
