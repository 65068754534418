import React from "react";
import videojs from "video.js";

interface VideoPlayerPropsInferface {
  videoJsOptions: videojs.PlayerOptions;
}

export class VideoPlayer extends React.Component<
  VideoPlayerPropsInferface,
  any
> {
  private player?: videojs.Player;
  private videoNode?: HTMLVideoElement;

  constructor(props: VideoPlayerPropsInferface) {
    super(props);
    this.state = {};
    this.player = undefined;
    this.videoNode = undefined;
  }

  componentDidMount() {
    if (this.videoNode) {
      this.player = videojs(this.videoNode, this.props.videoJsOptions).ready(
        () => {}
      );
    }
  }

  componentWillUnmount() {
    this.player && this.player.dispose();
  }

  render() {
    return (
      <div data-vjs-player="true">
        <video
          id="video-js-el"
          ref={(node: HTMLVideoElement) => {
            this.videoNode = node;
          }}
          className="video-js"></video>
      </div>
    );
  }
}
