import { Dropdown } from "antd";
import * as React from "react";
import { MoreHorizontal } from "../../../../../components/Icons";
import { Body3, Sub3 } from "../../../../../components/Text";
import OptionMenu from "./OptionMenu";
import TrafficTimeSlotParam from "./TrafficTimeSlotParam";

const TrafficTimeSlot: React.FC<{
  index: number;
  mode: "YELLOW_ONLY" | "AUTO";
  enabledSetting?: boolean;
  params?: {
    start: moment.Moment;
    end: moment.Moment;
    green1?: number;
    green2?: number;
    yellow1?: number;
    yellow2?: number;
    clearSessionTime?: number;
  };
  className?: string;
  active?: boolean;
  onEditSelected?: (key: number) => void;
  onAddSelected?: VoidFunction;
  onDeleteSelected?: (key: number) => void;
}> = ({
  enabledSetting,
  mode,
  params,
  active = false,
  className,
  index,
  onEditSelected,
  onAddSelected,
  onDeleteSelected,
}) => {
  const onMenuSelect = React.useCallback(
    (key: string) => {
      if (key === "MODIFY") {
        onEditSelected && onEditSelected(index);
      } else if (key === "DELETE") {
        onDeleteSelected && onDeleteSelected(index);
      } else if (key === "ADD") {
        onAddSelected && onAddSelected();
      }
    },
    [onDeleteSelected, onAddSelected, onEditSelected, index]
  );

  return mode === "YELLOW_ONLY" && params != null ? (
    <>
      <div
        className={`grow flex flex-row bg-[#FFF7DC] mr-[1rem] ${
          className || ""
        }`}>
        <div
          className={`w-[0.5rem] -ml-[0.5rem] ${
            active ? "bg-blue-Secondary" : ""
          }`}></div>
        <div className="grow p-[1rem] flex flex-col">
          <div className="flex flex-row  justify-between">
            <div className="mr-[1.875rem] pl-[1rem] my-auto">
              <Sub3>{`${params.start.format("HH:mm")} - ${params.end.format(
                "HH:mm"
              )}`}</Sub3>
              {active ? (
                <Sub3 className="text-blue-Secondary">Đang hoạt động</Sub3>
              ) : null}
            </div>
            {enabledSetting && (
              <Dropdown.Button
                icon={
                  <div className="my-[0.25rem] w-[1.5rem] mx-[0.375rem]">
                    <MoreHorizontal size="md" />
                  </div>
                }
                overlay={<OptionMenu onMenuSelect={onMenuSelect} />}
              />
            )}
          </div>
          <div className="flex flex-row gap-x-[0.375rem] items-center">
            <div className="w-[0.875rem] h-[0.875rem] bg-[#E5B40E]"></div>
            <Body3>Chớp vàng</Body3>
          </div>
        </div>
      </div>
    </>
  ) : mode === "AUTO" && params != null ? (
    <>
      <div
        className={`grow flex flex-row bg-neutral-White drop-shadow-[0_0.25rem_1rem_rgba(126, 129, 138, 0.1] mr-[1rem] ${
          className || ""
        }`}>
        <div
          className={`min-w-[0.5rem] -ml-[0.5rem] ${
            active ? "bg-blue-Secondary" : ""
          }`}></div>
        <div className="pt-[0.5rem] pb-[1rem]">
          <div className="px-[1rem] grow justify-between flex flex-row">
            <div className="pl-[1rem] my-autof flex flex-row">
              <Sub3>{`${params.start.format("HH:mm")} - ${params.end.format(
                "HH:mm"
              )}`}</Sub3>
              {active ? (
                <Sub3 className="text-blue-Secondary ml-[1rem]">
                  Đang hoạt động
                </Sub3>
              ) : null}
            </div>
            {enabledSetting && (
              <Dropdown.Button
                icon={
                  <div className="my-[0.25rem] w-[1.5rem] mx-[0.375rem]">
                    <MoreHorizontal size="md" />
                  </div>
                }
                overlay={<OptionMenu onMenuSelect={onMenuSelect} />}
              />
            )}
          </div>
          <div className="pl-[1rem] flex flex-row flex-wrap gap-x-[0.5rem] gap-y-[0.625rem] mt-[0.5rem]">
            <TrafficTimeSlotParam
              color="GREEN"
              title="Xanh tuyến 1"
              count={params.green1 || 0}
            />
            <TrafficTimeSlotParam
              color="GREEN"
              title="Xanh tuyến 2"
              count={params.green2 || 0}
            />
            <TrafficTimeSlotParam
              color="RED"
              title="Giải tỏa"
              count={params.clearSessionTime || 0}
            />
            <TrafficTimeSlotParam
              color="YELLOW"
              title="Vàng tuyến 1"
              count={params.yellow1 || 0}
            />
            <TrafficTimeSlotParam
              color="YELLOW"
              title="Vàng tuyến 2"
              count={params.yellow2 || 0}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default TrafficTimeSlot;
