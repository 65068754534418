import { Select, SelectProps } from 'antd';
import * as React from 'react';

interface ICustomSelectProps extends SelectProps {
}

const CustomSelect: React.FunctionComponent<ICustomSelectProps> = ({ children, ...props }) => {
    return <Select
        style={{
            border: 'none',
            borderRadius: 0,
            backgroundColor: 'transparent',
            height: '40px',

            width: '90px',
            textAlign: 'right'
        }}
        bordered={false}
        suffixIcon={false}
        {...props}
    >
        {children}
    </Select>;
};

export default CustomSelect;
