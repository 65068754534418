import { useMutation } from "@apollo/client";
import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AntdButtonCustom from "../../../components/AntdButtonCustom";
import { Label2, Sub1 } from "../../../components/Text";
import { PHONE_NUMBER_REGEX } from "../../../constants/regex";
import { useAuth } from "../../../context/AuthContext";
import { ADD_PHONE_NUMBER } from "../../../graphql/schema/user";
import AuthenLayout from "../components/AuthenLayout";

type PhoneFormValues = {
  phoneNumber: string;
};
const AddPhoneNumberForm: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { userId } = auth?.authState!;
  const [form] = useForm();
  const [addPhoneNumber] = useMutation<
    { addPhoneNumber: boolean },
    { id: string; phoneNumber: string }
  >(ADD_PHONE_NUMBER);
  const setphone = async (
    phoneNumber: string,
    options?: Partial<{
      onSuccess: () => void;
      onError: () => void;
    }>
  ) => {
    try {
      const addPhoneNumberResult = await addPhoneNumber({
        variables: {
          id: userId || "",
          phoneNumber,
        },
      });
      if (addPhoneNumberResult) {
        auth?.setAuthState({ ...auth.authState, phoneNumber });
        options?.onSuccess && options.onSuccess();
      } else {
        options?.onError && options.onError();
      }
    } catch (error) {
      options?.onError && options.onError();
    }
  };
  const [showSystemError, setShowSystemError] = React.useState<boolean>(false);
  const handleSubmit = (values: PhoneFormValues) => {
    const { phoneNumber } = values;
    auth?.setAuthState({ ...auth.authState, phoneNumber });
  };
  const validatePhoneNumber = (_: any, value: any) => {
    if (PHONE_NUMBER_REGEX.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Số điện thoại không hợp lệ"));
  };
  const { phoneNumber } = auth?.authState!;
  React.useEffect(() => {
    if (phoneNumber !== undefined)
      setphone(phoneNumber, {
        onSuccess: () => {
          auth?.setAuthState({ ...auth.authState, phoneNumber });
          navigate("/login/2fa/otp");
        },
        onError: () => {
          setShowSystemError(true);
        },
      });
  }, [phoneNumber, auth, navigate]);
  return (
    <AuthenLayout title="Xác thực hai yếu tố bằng điện thoại" step={1}>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{ phoneNumber: phoneNumber ? phoneNumber : "" }}
        onFieldsChange={() => setShowSystemError(false)}>
        <Sub1 className="text-neutral-D50 mb-[16px] text-[18px]">
          Nhập số điện thoại
        </Sub1>
        <Form.Item
          style={{ borderRadius: "10px" }}
          name="phoneNumber"
          rules={[{ validator: validatePhoneNumber }]}>
          <Input
            addonBefore="(+84)"
            size="large"
            style={{ borderRadius: "4px", marginBottom: "8px" }}
          />
        </Form.Item>
        {showSystemError && (
          <Label2 className="text-error">Có lỗi từ hệ thống</Label2>
        )}
        <div className="flex justify-center mt-[88px]">
          <AntdButtonCustom htmlType="submit" size="middle" type="primary">
            Gửi mã xác thực
          </AntdButtonCustom>
        </div>
      </Form>
    </AuthenLayout>
  );
};

export default AddPhoneNumberForm;
