import * as React from "react";
import { Body3, Label2 } from "../../../../../components/Text";
import { DetailContext } from "../../../../../context/LightBoxDetailContext";
import { LightBoxInfo } from "../../../../../graphql/types/lightBox";
import LightNumChart, { LightNumChartData } from "./LightNumChart";

interface StatusState {
  branch1?: LightBoxInfo;
  branch2?: LightBoxInfo;
  autoControlMode?: LightBoxInfo;
  doorOpening?: LightBoxInfo;
  baterry?: LightBoxInfo;
  numOfLight?: LightBoxInfo;
  errZB?: LightBoxInfo;
  errZEN?: LightBoxInfo;
  errPM?: LightBoxInfo;
  isConnected?: LightBoxInfo;
  totalLight?: LightBoxInfo;
  LightBoxInfo?: LightBoxInfo;
  connectedLight?: LightBoxInfo;
  errorLight?: LightBoxInfo;
  offLight?: LightBoxInfo;
  voltageV1?: LightBoxInfo;
  voltageV2?: LightBoxInfo;
  voltageV3?: LightBoxInfo;
  currentI1?: LightBoxInfo;
  currentI2?: LightBoxInfo;

  currentI3?: LightBoxInfo;
  highVoltage?: LightBoxInfo;
  lowVoltage?: LightBoxInfo;
  delayVoltage?: LightBoxInfo;
  highCurrent?: LightBoxInfo;
  lowCurrent?: LightBoxInfo;
  delayCurrent?: LightBoxInfo;
  noLoad?: LightBoxInfo;
}
interface ILightBoxStatusProps {
  status: StatusState;
  lightNumChartData: LightNumChartData[];
}

const LightBoxStatus: React.FunctionComponent<ILightBoxStatusProps> = ({
  status,
  lightNumChartData,
}) => {
  const { error } = React.useContext(DetailContext)!;

  const getValue = (item: LightBoxInfo, err: typeof error) => {
    if (!item) return -1;
    if (error?.isConnected !== 1) {
      return -1;
    } else {
      if (item.type === "ZEN" && err?.errZEN === 1) {
        return -1;
      }
      if (item.type === "ZB" && err?.errZB === 1) {
        return -1;
      }
      if (item.type === "PM" && err?.errPM === 1) {
        return -1;
      }
    }
    if (!item) return -1;
    return item.value;
  };

  return (
    <div>
      <div className="flex rounded-sm overflow-hidden shadow w-fit ml-auto mb-[1rem]">
        <div className="flex-auto h-[32px] leading-[32px] px-[16px] text-neutral-D50 text-[12px] font-4 tracking-[0.02em]">
          Điều khiển tự động
        </div>
        <AutoControlMode status={getValue(status.autoControlMode!, error)} />
      </div>
      <div className="flex justify-between gap-[16px] h-[128px]">
        <StatusGroup>
          <div className="flex justify-between items-center h-1/2">
            <Body3>Nhánh đèn 1:</Body3>
            <LightCabinetStatus status={getValue(status.branch1!, error)} />
          </div>
          <div className="flex justify-between items-center h-1/2">
            <Body3>Nhánh đèn 2:</Body3>
            <LightCabinetStatus status={getValue(status.branch2!, error)} />
          </div>
        </StatusGroup>

        <StatusGroup>
          <div className="flex justify-between items-center h-1/2">
            <Body3>Cửa tủ ĐK:</Body3>
            <WindowCabinetStatus
              status={getValue(status.doorOpening!, error)}
            />
          </div>
          <div className="flex justify-between items-center h-1/2">
            <Body3>Pin dự phòng:</Body3>
            <Battery status={getValue(status.baterry!, error)} />
          </div>
        </StatusGroup>
        <div className="w-[176px] shrink-0 shadow rounded-sm">
          <LightNumChart
            data={lightNumChartData}
            num={status.numOfLight?.value!}
            flag= {getValue(status.branch1!, error) ===1 || getValue(status.branch2!, error) === 1}
          />
        </div>
      </div>
    </div>
  );
};

export default LightBoxStatus;

interface INumberStatusProps {
  status: number;
}

const LightCabinetStatus: React.FC<INumberStatusProps> = ({ status }) => {
  return (
    <>
      {status === 1 && (
        <button className="bg-success w-[130px] h-[32px] text-neutral-White font-7 text-[14px] rounded-[4px]">
          On
        </button>
      )}
      {status === 0 && (
        <button className="bg-error w-[130px] h-[32px] text-neutral-White font-7 text-[14px] rounded-[4px]">
          Off
        </button>
      )}
      {status !== 1 && status !== 0 && (
        <button className="bg-neutral-L70 w-[130px] h-[32px] text-neutral-White font-7 text-[14px] rounded-[4px]">
          Disconnected
        </button>
      )}
    </>
  );
};

const WindowCabinetStatus: React.FC<INumberStatusProps> = ({ status }) => {
  return (
    <>
      {status === 1 && (
        <button className=" bg-success w-[130px] h-[32px] text-neutral-White font-7 text-[14px] rounded-[4px]">
          On
        </button>
      )}
      {status === 0 && (
        <button className=" bg-error w-[130px] h-[32px] text-neutral-White font-7 text-[14px] rounded-[4px]">
          Close
        </button>
      )}
      {status !== 1 && status !== 0 && (
        <button className=" bg-neutral-L70 w-[130px] h-[32px] text-neutral-White font-7 text-[14px] rounded-[4px]">
          Disconnected
        </button>
      )}
    </>
  );
};

const Battery: React.FC<INumberStatusProps> = ({ status }) => {
  const [widthClass, setWithClass] = React.useState<string>("");
  React.useEffect(() => {
    if (status > 90) {
      setWithClass("w-[18px]");
    } else if (status > 75) {
      setWithClass("w-[16px]");
    } else if (status > 50) {
      setWithClass("w-[12px]");
    } else if (status > 25) {
      setWithClass("w-[9px]");
    } else if (status > 0) {
      setWithClass("w-[3px]");
    }
  }, [status]);
  return (
    <>
      {status === -1 ? (
        <div>Không kết nối</div>
      ) : (
        <div className="flex justify-between items-center py-[16px] gap-[5px] ">
          <Body3 className="text-neutral-D90">{status}% </Body3>
          <div className="w-[24px] h-[12px] flex justify-between items-center">
            <div className="w-[22px] h-[12px] border border-neutral-D50 rounded-[2px]">
              <div className="w-full h-[100%] m-[1px] flex items-stretch">
                <div
                  className={`bg-neutral-D50 h-[8px] ${widthClass} rounded-[2px]`}></div>
              </div>
            </div>
            <div className="border-r h-[6px] border-neutral-D50"></div>
          </div>
        </div>
      )}
    </>
  );
};

const AutoControlMode: React.FC<INumberStatusProps> = ({ status }) => {
  return (
    <>
      {status === 1 && (
        <Label2 className="shrink-0 px-[16px] bg-[#D9F1E6] h-[32px] leading-[32px] text-center text-[#0DBC68]">
          {" "}
          BẬT{" "}
        </Label2>
      )}
      {status === 0 && (
        <Label2 className="shrink-0 px-[16px] bg-[#D9F1E6] h-[32px] leading-[32px] text-center text-error">
          {" "}
          TẮT{" "}
        </Label2>
      )}
    </>
  );
};

interface IStatusGroupProps {}
const StatusGroup: React.FunctionComponent<IStatusGroupProps> = ({
  children,
  ...props
}) => {
  return (
    <div className="w-[272px] shadow rounded-sm">
      <div className="flex flex-col divide-y divide-neutral-L50 px-[16px] h-[128px]">
        {children}
      </div>
    </div>
  );
};
