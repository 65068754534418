import { Tabs } from "antd";
import Current from "./Current";
import Power from "./Power";
import Voltage from "./Voltage";
import "./light-box-control.scss";

const { TabPane } = Tabs;
const tabList = [
  { index: "1", name: "Tiết giảm", component: <Power /> },
  { index: "2", name: "Điện áp", component: <Voltage /> },
  { index: "3", name: "Dòng điện", component: <Current /> },
];
const LightBoxControl = () => {
  return (
    <Tabs
      defaultActiveKey={tabList[0].index}
      className="flex-auto flex flex-col lightboxcontrol-tab">
      {tabList.map((item: any, key: number) => (
        <TabPane tab={item.name} key={item.index} className="flex-auto">
          {item.component}
        </TabPane>
      ))}
    </Tabs>
  );
};
export default LightBoxControl;
