import { EditOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, TimePicker } from "antd";
import moment from "moment";
import * as React from "react";
import { useParams } from "react-router-dom";
import { CTSNotification } from "../../../../../../components/CTSNotification";
import {
  adBoxGroupData,
  adBoxGroupVars,
  AD_BOX_GROUP,
  updateAdBoxGroupInfoData,
  updateAdBoxGroupInfoVars,
  UPDATE_AD_BOX_GROUP_INFO,
} from "../../../../../../graphql/schema/adBoxGroup";

const formatTime = "HH:mm";
const ActiveTimeRange = () => {
  const { id } = useParams();
  const [isSetTime, setIsSetTime] = React.useState<boolean>(false);
  const [timeShow, setTimeShow] = React.useState<[string, string]>([
    "00:00",
    "23:59",
  ]);
  const [timeRange, setTimeRange] = React.useState<[string, string]>([
    "00:00",
    "23:59",
  ]);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const handleChangeTimeRange = (value: any, dateString: [string, string]) => {
    setTimeRange(dateString);
  };
  const submitTimeDisabled = React.useMemo(() => {
    return JSON.stringify(timeRange) === JSON.stringify(timeShow);
  }, [timeRange, timeShow]);
  const [updateAdBoxMutation, { data, loading: updateTimeLoading, error }] =
    useMutation<updateAdBoxGroupInfoData, updateAdBoxGroupInfoVars>(
      UPDATE_AD_BOX_GROUP_INFO
    );
  useQuery<adBoxGroupData, adBoxGroupVars>(AD_BOX_GROUP, {
    variables: { id: Number(id!) },
    onCompleted(data) {
      if (data.adBoxGroup) {
        const { onTime, offTime } = data.adBoxGroup;
        setTimeShow([onTime || "", offTime || ""]);
        setTimeRange([onTime || "00:00", offTime || "23:59"]);
      }
    },
  });
  const handleSubmitTimeRange = () => {
    const [onTime, offTime] = timeRange;
    setSubmitLoading(true);
    updateAdBoxMutation({
      variables: {
        id: Number(id!),
        input: { onTime, offTime },
      },
    })
      .then((rs) => {
        setSubmitLoading(false);
        setTimeShow([onTime, offTime]);
        setIsSetTime(false);
        CTSNotification("success", "Cập nhật thời gian thành công");
      })
      .catch((err) => {
        CTSNotification("error", "Cập nhật thời gian thất bại");
      });
  };
  return (
    <div>
      <p className="mt-[16px] text-[18px] font-6">Thời gian hoạt động</p>
      <button
        className="group flex gap-[20px] mt-[10px] h-[30px] items-start "
        onClick={() => setIsSetTime(!isSetTime)}>
        <p className="font-7 leading-[30px]">
          {timeShow[0] + " : " + timeShow[1]}{" "}
        </p>
        <EditOutlined className="leading-[30px] mt-[8px] group-hover:text-[red]" />
      </button>
      {isSetTime && (
        <div className="flex gap-[16px]">
          <TimePicker.RangePicker
            suffixIcon={<></>}
            clearIcon={<></>}
            className="mt-[1rem]"
            format={formatTime}
            value={[
              moment(timeRange[0], formatTime),
              moment(timeRange[1], formatTime),
            ]}
            onChange={handleChangeTimeRange}
          />
          <Button
            type="primary"
            loading={submitLoading}
            disabled={submitTimeDisabled}
            onClick={handleSubmitTimeRange}>
            Cập nhật
          </Button>
          <Button danger onClick={() => setIsSetTime(false)}>
            Hủy
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActiveTimeRange;
