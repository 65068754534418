import { Select, SelectProps, TimePicker, TimePickerProps } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { formatTime, options } from '../..';
import TwCheckbox from '../../../../../../../../components/Input/TwCheckbox';
import { TimeSlotAjust } from '../EditModal';
import './offtime-select.scss';

interface IOffTimeSelectProps extends TimePickerProps {
    index?: number;
    enableCheck?: boolean;
}
const OffTimeSelect: React.FunctionComponent<IOffTimeSelectProps> = ({ index = -1, ...props }) => {
    return <div className='offtime-select border border-neutral-L70 h-[40px] rounded-[4px] w-[192px] text-center' >
        <TimePicker
        style={{
            height:'40px'
        }}
            bordered={false}
            suffixIcon={false}
            clearIcon={<></>}
            format={formatTime}
            {...props}
        />
    </div>
};

export default OffTimeSelect;
