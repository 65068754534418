import { useLazyQuery } from "@apollo/client";
import * as React from "react";
import { ControlContext } from "../../../../context/ControlContext";
import {
  GetLightBoxInRangeData,
  GetLightBoxInRangeVars,
  GET_LIGHT_BOX_IN_RANGE_FOR_MAP
} from "../../../../graphql/schema/lightBox";
import { LightBox } from "../../../../graphql/types/lightBox";
import LightBoxMarker from "./components/LightBoxMarker";

const LightingTabMap: React.FC = () => {
  const { center, distanceMap } = React.useContext(ControlContext)!;
  const [lightBoxs, setLightBoxs] = React.useState<LightBox[]>([]);
  const [getLightBoxInRangeLazy] = useLazyQuery<
    GetLightBoxInRangeData,
    GetLightBoxInRangeVars
  >(GET_LIGHT_BOX_IN_RANGE_FOR_MAP, {
    variables: { distance: distanceMap, lat: center?.[0]!, lng: center?.[1]! },
    onCompleted(data) {
      setLightBoxs(data.getLightBoxInRange || []);
    },
  });
  React.useEffect(() => {
    getLightBoxInRangeLazy();
  }, [center]);
  return (
    <>
      {lightBoxs.map((item, idx) => {
        return <LightBoxMarker lightBox={item} key={`lightbox-${idx}`} />;
      })}
    </>
  );
};

export default LightingTabMap;
