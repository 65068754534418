import { gql } from "@apollo/client";
import { Camera } from "../types/camera";
import { Paging } from "../types/common";
// type Query {
//   cameraAll(paging: Paging): [Camera]
//   cameraTypeAll(paging: Paging): [CameraType]
//   cameraAllCount: Int
//   camera(id: String!): Camera
//   cameraFindByCondition(input: CameraFilterInput!, paging: Paging): [Camera]
//   cameraPlayback(input: CameraPlaybackInput!): String
//   getCameraInRange(
//     lat: Float!
//     lng: Float!
//     distance: Float!
//     paging: Paging
//   ): [Camera]
// }

export type getAllCameraData = {
  countAllCamera: number;
  getAllCamera: Camera[];
};
export type getAllCameraVars = {
  paging: Paging;
};
export const GET_ALL_CAMERA = gql`
  query GET_ALL_CAMERA($paging: Paging) {
    countAllCamera
    getAllCamera(paging: $paging) {
      id
      cameraId
      name
      serialNumber
      lat
      lng
      province {
        name
      }
      district {
        name
      }
      ward {
        name
      }
    }
  }
`;
export const CAMERA_TYPE_ALL = gql`
  query CAMERA_TYPE_ALL($paging: Paging) {
    cameraTypeAll(paging: $paging) {
      id
    }
  }
`;
export const CAMERA_ALL_COUNT = gql`
  query CAMERA_ALL_COUNT {
    cameraAllCount
  }
`;
export type cameraData = {
  getCamera: Camera;
};
export type cameraVars = {
  id: Number;
};
export const GET_CAMERA = gql`
  query GET_CAMERA($id: Int!) {
    getCamera(id: $id) {
      id
      cameraId
      name
      serialNumber
      lat
      lng
      province {
        name
      }
      district {
        name
      }
      ward {
        name
      }
    }
  }
`;
export const CAMERA_FIND_BY_CONDITION = gql`
  query CAMERA_FIND_BY_CONDITION($input: CameraFilterInput!, $paging: Paging) {
    cameraFindByCondition(input: $input, paging: $paging)
  }
`;
export const CAMERA_PLAY_BACK = gql`
  query CAMERA_PLAY_BACK($input: CameraPlaybackInput!) {
    getCameraPlayback(input: $input)
  }
`;
export type getCameraInRangeData = {
  getCameraInRange: Camera[];
};
export type getCameraInRangeVars = {
  lat: number;
  lng: number;
  distance: number;
  paging?: Paging;
};
export const GET_CAMERA_IN_RANGE = gql`
  query GET_CAMERA_IN_RANGE(
    $lat: Float!
    $lng: Float!
    $distance: Float!
    $paging: Paging
  ) {
    getCameraInRange(lat: $lat, lng: $lng, distance: $distance, paging: $paging){
      cameraId
      id
      lat
      lng
    }
  }
`;

// type Mutation {
//   createCamera(input: CameraInput!): Camera
//   updateCamera(id: String!, input: CameraInput!): Camera
//   deleteCamera(id: String!): Boolean
//   controlCamera(input: ControlCameraInput!): Boolean
// }

export const CREATE_CAMERA = gql`
  mutation CREATE_CAMERA($input: CameraPlaybackInput!) {
    createCamera(input: $input) {
      id
    }
  }
`;

export const UPDATE_CAMERA = gql`
  mutation UPDATE_CAMERA($id: String!, $input: CameraInput!) {
    updateCamera(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_CAMERA = gql`
  mutation DELETE_CAMERA($id: String!) {
    deleteCamera(id: $id)
  }
`;

export const CONTROL_CAMERA = gql`
  mutation CONTROL_CAMERA($input: ControlCameraInput!) {
    controlCamera(input: $input)
  }
`;
