import * as React from "react";
import { IIconProps } from "./IIconProps";

const ArrowDownLeft: React.FunctionComponent<IIconProps> = ({
  size = "sm",
  color = "currentColor",
}) => {
  return (
    <>
      <svg
        width={
          size === "sm" ? 16 : size === "md" ? 24 : size === "lg" ? 32 : 24
        }
        height={
          size === "sm" ? 16 : size === "md" ? 24 : size === "lg" ? 32 : 24
        }
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="17" y1="7" x2="7" y2="17"></line>
        <polyline points="17 17 7 17 7 7"></polyline>
      </svg>
    </>
  );
};

export default ArrowDownLeft;
