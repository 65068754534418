import { useLazyQuery } from "@apollo/client";
import { Table } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ControlContext } from "../../../context/ControlContext";
import {
  getAllTrafficBoxData,
  getAllTrafficBoxVars,
  GET_ALL_TRAFFIC_BOX,
} from "../../../graphql/schema/trafficBox";
import { TrafficBox } from "../../../graphql/types/trafficBox";
import { useCalcTableScrollHeight } from "../../../hooks/useCalcTableScrollHeight";
import { usePagination } from "../../../hooks/usePagination";
import { usePreventExceed } from "../../../hooks/usePreventExceed";
import { mapKey } from "../../../utils/mapKey";
import "./box-list.scss";

const columns = [
  {
    title: "Tên tủ",
    dataIndex: "name",
  },
  {
    title: "Mã PE",
    dataIndex: "PENumber",
  },
  {
    title: "Địa chỉ",
    dataIndex: "address",
  },
];
interface ITrafficTabProps {}

const TrafficTab: React.FunctionComponent<ITrafficTabProps> = (props) => {
  const [total, setTotal] = React.useState<number>(0);
  const [trafficBoxs, setTrafficBoxs] = React.useState<TrafficBox[]>([]);
  const { paging, PaginationElement } = usePagination(total);
  const navigate = useNavigate();
  const handleClickName = (record: TrafficBox) => {
    navigate(record.boxId!);
  };
  const { setBreadcrumbText } = React.useContext(ControlContext)!;
  setBreadcrumbText(undefined);
  const [getTrafficBoxLazy] = useLazyQuery<
    getAllTrafficBoxData,
    getAllTrafficBoxVars
  >(GET_ALL_TRAFFIC_BOX, {
    variables: { paging },
    onCompleted(data) {
      setTrafficBoxs(data.getAllTrafficBox);
      setTotal(data.countAllTrafficBox);
    },
  });
  usePreventExceed(getTrafficBoxLazy, [paging]);
  const handleOnRow = (record: TrafficBox) => ({
    onClick: () => handleClickName(record),
  });
  const { scrollHeight, tableRef } = useCalcTableScrollHeight();
  return (
    <>
      <Table
        ref={tableRef}
        className="flex-auto"
        scroll={{
          x: true,
          y: scrollHeight,
        }}
        columns={columns}
        dataSource={mapKey(trafficBoxs)}
        onRow={handleOnRow}
        pagination={false}
      />
      <PaginationElement />
    </>
  );
};

export default TrafficTab;
