import { Input, InputProps } from "antd";
import * as React from "react";
import { Neutral } from "../../constants/colors";
import "./input.scss";

interface ICtsInputProps extends InputProps {
  inputSize?: "sm" | "md" | "lg";
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
}

const CtsInput: React.FC<ICtsInputProps> = ({
  inputSize = "sm",
  bgColor = Neutral.White,
  textColor = Neutral.D50,
  borderColor = Neutral.L90,
  leftIcon,
  rightIcon,
  className,
  ...props
}) => {
  return (
    <div
      style={{
        position: "relative",
        color: textColor,
      }}

    >
      {leftIcon && <div className={`left-icon-${inputSize}`}>{leftIcon}</div>}
      <Input
        className={`basic-input-${inputSize} ${leftIcon ? "has-left-icon" : ""
          } ${rightIcon ? "has-right-icon" : ""} `}
        style={{
          backgroundColor: bgColor,
          color: textColor,
          borderColor: borderColor,
          borderRadius: '4px'
        }}
        {...props}
      />
      {rightIcon && (
        <div className={`right-icon-${inputSize} `}>{rightIcon}</div>
      )}
    </div>
  );
};

export default CtsInput;
