/**
 *
 * CameraTabDetail
 *
 */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { DatePicker, Form, Switch, Tabs } from "antd";
import moment from "moment";
import * as React from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import videojs from "video.js";
import "video.js/src/css/video-js.scss";
import { ArrowUpRight } from "../../../../components/Icons";
import ArrowDown2 from "../../../../components/Icons/ArrowDown2";
import ArrowDownLeft from "../../../../components/Icons/ArrowDownLeft";
import ArrowDownRight from "../../../../components/Icons/ArrowDownRight";
import ArrowLeft2 from "../../../../components/Icons/ArrowLeft2";
import ArrowRight2 from "../../../../components/Icons/ArrowRight2";
import ArrowUp2 from "../../../../components/Icons/ArrowUp2";
import ArrowUpLeft from "../../../../components/Icons/ArrowUpLeft";
import TwButton from "../../../../components/TwButton";
import {
  cameraData,
  cameraVars,
  CAMERA_PLAY_BACK,
  CONTROL_CAMERA,
  GET_CAMERA,
} from "../../../../graphql/schema/camera";
import { VideoPlayer } from "./components/VideoPlayer";

import { CAMERA_LINK } from "../../../../config";
import "./camera-tab.scss";
import { ControlContext } from "../../../../context/ControlContext";

let TOINST: NodeJS.Timeout | undefined = undefined;
let last_x = 0;
let last_y = 0;
let last_zoom = 0;
interface Props {}
const CameraTabDetail: React.FC = (props: Props) => {
  const { setBreadcrumbText } = React.useContext(ControlContext)!;
  const [playerKey, setPlayerKey] = React.useState(uuidv4());
  const { id } = useParams();
  const { data } = useQuery<cameraData, cameraVars>(GET_CAMERA, {
    variables: { id: Number(id) },
    onCompleted(data) {
      setBreadcrumbText(data.getCamera.name);
    },
  });

  const [videoJsOptions, setVideoOptions] =
    React.useState<videojs.PlayerOptions>({
      autoplay: true,
      controls: true,
      liveui: true,
      width: 748,
      height: 512,
      techOrder: ["html5"],
      sources: [
        {
          src: `${CAMERA_LINK}/camera/${
            data?.getCamera.serialNumber
          }/stream.m3u8?playid=${uuidv4()}`,
          type: "application/x-mpegurl",
        },
      ],
    });

  const applyPlayback = React.useCallback(
    (playbackString) => {
      setVideoOptions((prevOptions) => {
        return {
          ...prevOptions,
          sources: [
            {
              src: playbackString,
              type: "application/x-mpegurl",
            },
          ],
        };
      });

      setPlayerKey(uuidv4());
    },
    [data]
  );

  const [getPlayback] = useLazyQuery<
    { getCameraPlayback: string },
    { input: { startTime: string; endTime: string; id: number } }
  >(CAMERA_PLAY_BACK, {
    onCompleted: (data) => {
      applyPlayback(data.getCameraPlayback);
    },
  });

  const [controlCamera] = useMutation<
    { controlCamera: boolean },
    {
      input: {
        id: number;
        ptx: number;
        pty: number;
        zoom: number;
      };
    }
  >(CONTROL_CAMERA);

  const onDateChange = React.useCallback(
    (values: any) => {
      const thisMoment = moment(moment.now());
      const ranges = values["playbackRange"] as [moment.Moment, moment.Moment];
      const notValid = ranges.some((m) => m > thisMoment);
      if (notValid === false) {
        getPlayback({
          variables: {
            input: {
              startTime: ranges[0].toISOString(),
              endTime: ranges[1].toISOString(),
              id: Number(id),
            },
          },
        });
      }
    },
    [getPlayback, id]
  );

  const turnCamera = React.useCallback(
    (x?: number, y?: number, zoom?: number) => {
      if (TOINST) {
        clearTimeout(TOINST);
        const new_x =
          x != null ? (Math.abs(x) > 1.0 ? (1.0 * x) / Math.abs(x) : x) : 0;
        last_x = last_x + new_x;

        const new_y =
          y != null ? (Math.abs(y) > 1.0 ? (1.0 * y) / Math.abs(y) : y) : 0;
        last_y = last_y + new_y;

        const new_zoom =
          zoom != null
            ? Math.abs(zoom) > 1.0
              ? (1.0 * zoom) / Math.abs(zoom)
              : zoom
            : 0;
        last_zoom = last_zoom + new_zoom;
      }

      TOINST = setTimeout(() => {
        controlCamera({
          variables: {
            input: {
              id: Number(id),
              ptx: last_x,
              pty: last_y,
              zoom: last_zoom,
            },
          },
        });
        last_x = 0;
        last_y = 0;
        last_zoom = 0;
      }, 500);
    },
    [id, controlCamera]
  );

  const [isLive, setIsLive] = React.useState<boolean>(true);

  React.useEffect(() => {
    isLive &&
      applyPlayback(
        `${CAMERA_LINK}/camera/${
          data?.getCamera.serialNumber
        }/stream.m3u8?playid=${uuidv4()}`
      );
  }, [isLive, data]);

  return (
    <div className="w-full flex flex-col items-center gap-y-[1rem] pt-[24px] h-[100vh] overflow-scroll">
      <VideoPlayer
        key={playerKey}
        videoJsOptions={
          {
            ...videoJsOptions,
            ish265: true,
            islive: true,
            hasvideo: true,
          } as videojs.PlayerOptions
        }
      />
      <div className="w-full flex">
        <div className="mt-[1rem] px-[18px]">
          <div className="flex gap-[16px]">
            <p className={`text-[16px] ${!isLive ? "font-7" : ""}`}>Phát lại</p>
            <Switch checked={isLive} onChange={() => setIsLive(!isLive)} />
            <p className={`text-[16px] ${isLive ? "font-7" : ""}`}>
              Phát trực tiếp
            </p>
          </div>
          <div>
            {isLive && (
              <div>
                <div className="grid-cols-3 grid grid-rows-3 shrink my-auto rounded-full overflow-hidden w-[240px] h-[240px] relative">
                  <ButtonWrapper
                    arrow="top-left"
                    onClick={() => turnCamera(-0.03, 0.03)}>
                    <ArrowUpLeft size="lg" />
                  </ButtonWrapper>
                  <ButtonWrapper
                    arrow="top"
                    onClick={() => turnCamera(0, 0.03)}>
                    <ArrowUp2 size="lg" />
                  </ButtonWrapper>
                  <ButtonWrapper
                    arrow="top-right"
                    onClick={() => turnCamera(0.03, 0.03)}>
                    <ArrowUpRight size="lg" />
                  </ButtonWrapper>
                  <ButtonWrapper arrow="left" onClick={() => turnCamera(-0.03)}>
                    <ArrowLeft2 size="lg" />
                  </ButtonWrapper>
                  <div className="home"></div>
                  <HomeButton onClick={() => turnCamera()}>
                    {/* <Home size="lg" /> */}
                  </HomeButton>
                  <ButtonWrapper arrow="right" onClick={() => turnCamera(0.03)}>
                    <ArrowRight2 size="lg" />
                  </ButtonWrapper>
                  <ButtonWrapper
                    arrow="bottom-left"
                    onClick={() => turnCamera(-0.03, -0.03)}>
                    <ArrowDownLeft size="lg" />
                  </ButtonWrapper>
                  <ButtonWrapper
                    arrow="bottom"
                    onClick={() => turnCamera(0, -0.03)}>
                    <ArrowDown2 size="lg" />
                  </ButtonWrapper>
                  <ButtonWrapper
                    arrow="bottom-right"
                    onClick={() => turnCamera(0.03, -0.03)}>
                    <ArrowDownRight size="lg" />
                  </ButtonWrapper>
                </div>
              </div>
            )}
            <div className={`${isLive ? "hidden" : ""}`}>
              <Form
                layout="inline"
                onFinish={onDateChange}
                initialValues={{
                  playbackRange: [
                    moment(moment.now() - 300000),
                    moment(moment.now()),
                  ],
                }}>
                <Form.Item
                  name="playbackRange"
                  rules={[
                    {
                      type: "array",
                      required: true,
                      message: "Please select time!",
                    },
                  ]}
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    border: "#ccc solid 1px",
                    borderRadius: "4px",
                    overflow: "hidden",
                    paddingBottom: "5px",
                  }}>
                  <DatePicker.RangePicker
                    className="range-playback"
                    bordered={false}
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    showNow={true}
                  />
                </Form.Item>
                <TwButton type="submit">Phát lại</TwButton>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraTabDetail;

interface IButtonWrapperProps extends React.HTMLProps<HTMLDivElement> {
  arrow?:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "center";
}
const ButtonWrapper: React.FC<IButtonWrapperProps> = ({
  children,
  arrow = "top",
  ...props
}) => {
  const classBtn = React.useMemo(() => {
    switch (arrow) {
      case "top":
        return "w-[80px] h-[80px] justify-center pt-[16px] ";
      case "left":
        return "items-center pl-[16px]";
      case "bottom":
        return " w-[80px] h-[80px] justify-center items-end pb-[16px]";
      case "right":
        return "w-[80px] h-[80px] justify-end items-center pr-[16px]";
      case "top-right":
        return "w-[80px] h-[80px] justify-center items-center pt-[40px] pr-[40px]";
      case "top-left":
        return "w-[80px] h-[80px] justify-center items-center pt-[40px] pl-[40px]";
      case "bottom-right":
        return "w-[80px] h-[80px] justify-center items-center pb-[40px] pr-[40px]";
      case "bottom-left":
        return "w-[80px] h-[80px] justify-center items-center pb-[40px] pl-[40px]";
      case "center":
        return "w-[120px] h-[120px] justify-center items-center rounded-full  z-10 border transform translate-x-1/2 -translate-y-1/2 top-1/2 bottom-1/2 absolute";
      default:
        return "";
    }
  }, [arrow]);
  return (
    <div className={`w-[80px] h-[80px] bg-neutral-L50 flex ${classBtn}`}>
      <div
        className="w-[40px] h-[40px] flex items-center justify-center bg-neutral-L70 hover:bg-neutral-L90 active:bg-neutral-D30 cursor-pointer rounded-full overflow-hidden"
        {...props}>
        {children}
      </div>
    </div>
  );
};
interface IHomeButtonProps extends React.HTMLProps<HTMLDivElement> {}
const HomeButton: React.FC<IHomeButtonProps> = ({ children, ...props }) => {
  return (
    <div
      className="w-[120px] h-[120px] flex justify-center items-center z-10 transform translate-x-1/2 -translate-y-1/2 top-1/2 bottom-1/2 absolute 
  bg-neutral-L70 rounded-full overflow-hidden"
      {...props}>
      {children}
    </div>
  );
};
