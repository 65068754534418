import { useMutation } from "@apollo/client";
import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import Countdown from "antd/lib/statistic/Countdown";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Body3, Label2, Sub1 } from "../../../components/Text";
import TwButton from "../../../components/TwButton";
import { useAuth } from "../../../context/AuthContext";
import { UPDATE_PHONE_NUMBER } from "../../../graphql/schema/user";
import AuthenLayout from "../components/AuthenLayout";

const OTPForm: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [form] = useForm();

  const [disable, setDisable] = React.useState(true);
  const [deadline] = React.useState(Date.now() + 0.2 * 1000 * 60);
  const [otps, setOtp] = React.useState<(string | undefined)[]>([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const [focus, setFocus] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [inputValue, setInputValue] = React.useState("");
  const [errorOTP, setErrorOTP] = React.useState(false);

  const changeOverlayInputState = React.useCallback(
    (current_value: string) => {
      if (current_value.length === 0) {
        setOtp([
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        ]);
        setFocus([true, false, false, false, false, false]);
        setInputValue("");
      } else {
        let valid_char = [];
        for (var i = 0; i < current_value.length; i++) {
          if (!isNaN(parseInt(current_value[i]))) {
            valid_char.push(current_value[i]);
            if (valid_char.length === 6) {
              break;
            }
          }
        }
        setDisable(valid_char.length < 6);
        const valid_string = valid_char.join("");
        setInputValue(valid_string);
        if (valid_string.length === 6) {
          setTimeout(() => {
            form.submit();
          }, 500);
        }
        setOtp((p) => {
          let n: (string | undefined)[] = p.map(() => undefined);
          for (var i = 0; i < valid_string.length; i++) {
            n[i] = valid_string[i];
          }
          return n;
        });
        setFocus((p) => {
          let n = p.map(() => false);
          for (var i = 0; i < valid_string.length; i++) {
            n[i] = true;
          }
          if (valid_string.length < 6) {
            n[valid_string.length] = true;
          }
          return n;
        });
      }
    },
    [setInputValue, setOtp, setFocus, form]
  );
  const otpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    changeOverlayInputState(value);
  }
  const { userId, phoneNumber, isFirstLogin, accessToken } = auth?.authState!;
  const handleSubmitOTPFinish = () => {
    sendOTP(otps.join(''), {
      onError: () => {
        setErrorOTP(true);
      },
      onSuccess: () => {
        if (isFirstLogin) {
          // navigate("/login/2fa/change_password");
          // thực tế sẽ chuyển qua trang đổi password
          localStorage.setItem("CTS_ACCESS_TOKEN", accessToken!);
          localStorage.setItem("CTS_UID", userId!);
          navigate('/');
        } else {
          localStorage.setItem("CTS_ACCESS_TOKEN", accessToken!);
          localStorage.setItem("CTS_UID", userId!);
          navigate('/');
        }
      }
    })
  }
  const [updateOTP] = useMutation<{ updatePhoneNumber: boolean }, { id: string; phoneNumber: string; otp: string }>(UPDATE_PHONE_NUMBER);
  const sendOTP = async (
    otp: string,
    options?: Partial<{
      onSuccess: () => void;
      onError: () => void;
    }>
  ) => {
    try {
      const updateOTPResult = await updateOTP({
        variables: {
          id: userId!,
          phoneNumber: phoneNumber!,
          otp,
        },
      })
      if (updateOTPResult.data?.updatePhoneNumber) {
        options?.onSuccess && options.onSuccess();
      } else {
        options?.onError && options.onError();
      }
    } catch (error) {
      options?.onError && options.onError();
    }
  }

  return (
    <AuthenLayout title="Nhập mã xác thực hai yếu tố" step={2}>
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={handleSubmitOTPFinish}
        className="flex flex-col mr-auto shrink"
      >
        <Form.Item className="shrink mr-auto">
          <div className="flex items-baseline justify-between mb-[16px]">
            <Body3>Nhập mã xác thực đã được gửi</Body3>
            <Countdown
              valueStyle={{
                fontSize: "14px",
                fontWeight: 600,
                fontFamily: "Inter",
                lineHeight: "1rem",
                letterSpacing: "0.02em",
              }}
              onFinish={() => {
                setDisable(false);
              }} value={deadline} format="mm:ss" />
          </div>
          <div className="relative">
            <div className="flex gap-x-[0.5rem] absolute ">
              {otps.map((value, idx) => {
                const hasValue = value != null;
                return (
                  <div
                    key={idx}
                    className={`w-[48px] h-[48px] border rounded-[0.5rem] ${errorOTP
                      ? "border-[#FDEAEA] bg-[#FDEAEA]"
                      : focus[idx]
                        ? "border-neutral-D90"
                        : "border-neutral-L50"
                      } justify-center items-center flex`}
                  >
                    <Sub1 className={hasValue ? `text-neutral-D90` : `text-neutral-L50`} >
                      {hasValue ? value : 0}
                    </Sub1>
                  </div>
                );
              })}
            </div>
            <Input
              onFocus={() => {
                setErrorOTP(false);
                changeOverlayInputState("");
              }}
              value={inputValue}
              onInput={otpChange}
              className="absolute inset-0 w-full h-[48px] opacity-0"
              size="large"
            />
          </div>
        </Form.Item>
        <div className="h-[31px] mb-[49px]">
          {errorOTP && <Label2 className="text-error mt-[16px]">Có lỗi từ hệ thống</Label2>}
          {!disable &&
            <Label2 className={`text-blue-Primary font-6 tracking-[0.02em] mt-[16px]`}> Không nhận được mã xác thực? Vui lòng bấm Gửi lại. </Label2>
          }
        </div>
        <TwButton
          className="mx-auto"
          type="button"
          sizeBtn="md"
          variant="CONTAINED"
          disabled={disable}
          onClick={() => { }}
        >
          Gửi lại
        </TwButton>
      </Form>
    </AuthenLayout>
  )
}
export default OTPForm;
