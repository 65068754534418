import { useMutation } from "@apollo/client";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { AdBoxLocationState, PlayVideoItem } from "../..";
import { CTSNotification } from "../../../../../../components/CTSNotification";
import {
  updateAdBoxGroupContentData,
  updateAdBoxGroupContentVars,
  UPDATE_AD_BOX_GROUP_CONTENT,
} from "../../../../../../graphql/schema/adBoxGroup";
import { AdBoxGroupContentInput } from "../../../../../../graphql/types/adBoxGroup";
import { AdVideo } from "../../../../../../graphql/types/adVideo";
import ThumbnailComponent from "../ThumbnailComponent";

interface IPlayingListProps {
  playList: PlayVideoItem[];
  setPlayList: (playList: PlayVideoItem[]) => void;
}

const PlayingList: React.FunctionComponent<IPlayingListProps> = ({
  playList,
  setPlayList,
}) => {
  const { id } = useParams();
  const uploadFailHandle = (clId: string) => {
    setPlayList(playList.filter((item) => item.clientId !== clId));
  };
  const [updateAdboxContentMutation] = useMutation<
    updateAdBoxGroupContentData,
    updateAdBoxGroupContentVars
  >(UPDATE_AD_BOX_GROUP_CONTENT);
  const updateAdContent = async (content: AdBoxGroupContentInput[]) => {
    try {
      const result = await updateAdboxContentMutation({
        variables: {
          id: Number(id!),
          content,
        },
      });
      if (result.data) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };
  const removeAd = async (id: number) => {
    let content = playList.filter((item) => item.id !== id);
    let contentToUpdate = content.map((item, idx) => ({
      videoId: item.id || -1,
      order: idx,
    }));
    if (contentToUpdate.length === 0) {
      CTSNotification("error", "Bảng quảng cáo phải có video");
      return;
    }
    try {
      const rs = await updateAdContent(contentToUpdate);
      if (rs) {
        setPlayList(playList.filter((item) => item.id !== id));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const addAd = (video: AdVideo, clientId: string) => {
    const { length } = playList;
    let tempPlaylist = playList.map((item, idx) => {
      if (idx === length - 1) {
        return { ...item, ...video };
      }
      return item;
    });

    updateContent(tempPlaylist, clientId);
  };
  const updateContent = async (list: PlayVideoItem[], clientId: string) => {
    const content: AdBoxGroupContentInput[] = list.map((item, idx) => ({
      videoId: item.id!,
      order: idx,
    }));
    try {
      const updateMutation = await updateAdboxContentMutation({
        variables: {
          id: Number(id!),
          content,
        },
      });
      if (updateMutation.data) {
        const tempList = list.map((item) => {
          if (item.clientId === clientId) {
            item.status = "SUCCESSFUL";
          }
          return item;
        });
        setPlayList(tempList);
        return true;
      } else {
        setPlayList(playList.filter((item) => item.clientId !== clientId));
        return false;
      }
    } catch (error) {
      setPlayList(playList.filter((item) => item.clientId !== clientId));
      return false;
    }
  };
  return (
    <>
      {playList.map((props, idx) => {
        return (
          <ThumbnailComponent
            failCallback={uploadFailHandle}
            removeAdCallback={removeAd}
            addAdCallback={addAd}
            {...props}
            key={`thumb-${idx}`}
          />
        );
      })}
    </>
  );
};

export default PlayingList;
