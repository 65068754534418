import { Breadcrumb } from "antd";
import * as React from "react";
import {
  Link,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ControlContext } from "../../context/ControlContext";
import AdsTab from "./AdsTab";
import AdsBoxDetail from "./AdsTab/AdsTabDetail";
import AdsDetailMap from "./AdsTab/AdsTabDetail/AdsDetailMap";
import AdsTabMap from "./AdsTab/AdsTabMap";
import CameraTab from "./CameraTab";
import CameraTabDetail from "./CameraTab/CameraTabDetail";
import CameraDetailMap from "./CameraTab/CameraTabDetail/CameraDetailMap";
import CameraTabMap from "./CameraTab/CameraTabMap";
import { ControlMapContainer } from "./ControlMapContainer";
import LightingTab from "./LightingTab";
import LightBoxDetail from "./LightingTab/LightBoxDetail";
import LightingDetailMap from "./LightingTab/LightBoxDetail/LightingDetailMap";
import LightingTabMap from "./LightingTab/LightingTabMap";
import TrafficTab from "./TrafficTab";
import TrafficBoxDetail from "./TrafficTab/TrafficBoxDetail";
import TrafficDetailMap from "./TrafficTab/TrafficBoxDetail/TrafficDetailMap";
import TrafficTabMap from "./TrafficTab/TrafficTabMap";

const ControlPage: React.FunctionComponent = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { center } = React.useContext(ControlContext)!;
  React.useEffect(() => {
    if (pathname === "/") {
      navigate("/lighting");
    }
  }, [pathname, navigate]);
  const { breadcrumbText } = React.useContext(ControlContext)!;
  const link = React.useMemo(() => {
    if (pathname.indexOf("/traffic") >= 0) {
      return ["/traffic", "Giao thông"];
    }
    if (pathname.indexOf("/ads") >= 0) {
      return ["/ads", "Quảng cáo"];
    }
    if (pathname.indexOf("/camera") >= 0) {
      return ["/camera", "Camera"];
    }
    return ["/", "Chiếu sáng"];
  }, [pathname]);

  return (
    <div className="bg-neutral-L30 flex h-[100vh]">
      <div className="w-[784px] shrink-0 flex flex-col p-[16px] gap-[16px]">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={link[0]}>{link[1]}</Link>
          </Breadcrumb.Item>
          {breadcrumbText && (
            <Breadcrumb.Item>{breadcrumbText}</Breadcrumb.Item>
          )}
        </Breadcrumb>
        <Routes>
          <Route path="lighting" element={<LightingTab />} />
          <Route path="lighting/:boxId" element={<LightBoxDetail />} />

          <Route path="traffic" element={<TrafficTab />} />
          <Route path="traffic/:boxId" element={<TrafficBoxDetail />} />

          <Route path="camera" element={<CameraTab />} />
          <Route path="camera/:id" element={<CameraTabDetail />} />

          <Route path="ads" element={<AdsTab />} />
          <Route path="ads/:id" element={<AdsBoxDetail />} />
        </Routes>
      </div>
      <div className="flex-auto relative">
        <ControlMapContainer>
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route path="lighting" element={<LightingTabMap />} />
              <Route path="lighting/:boxId" element={<LightingDetailMap />} />
              <Route path="traffic" element={<TrafficTabMap />} />
              <Route path="traffic/:boxId" element={<TrafficDetailMap />} />
              <Route path="camera" element={<CameraTabMap />} />
              <Route path="camera/:id" element={<CameraDetailMap />} />
              <Route path="ads" element={<AdsTabMap />} />
              <Route path="ads/:id" element={<AdsDetailMap />} />
            </Route>
          </Routes>
        </ControlMapContainer>
      </div>
    </div>
  );
};
export default ControlPage;

// interface ITabButtonProps extends LinkProps {
//   active?: boolean;
// }
// const TabButton: React.FC<ITabButtonProps> = ({
//   active = false,
//   children,
//   ...props
// }) => {
//   return (
//     <>
//       {active ? (
//         <button className="h-[48px] px-[38px] leading-[48px] text-blue-Secondary text-[14px] tracking-[0.02em] font-7 bg-blue-L30 inline-block transition duration-300">
//           {" "}
//           {children}{" "}
//         </button>
//       ) : (
//         <Link
//           className="h-[48px] px-[38px] leading-[48px] text-neutral-D30 text-[14px] tracking-[0.02em] font-7 bg-neutral-White inline-block transition duration-300 hover:text-neutral-D30"
//           {...props}>
//           {" "}
//           {children}{" "}
//         </Link>
//       )}
//     </>
//   );
// };
