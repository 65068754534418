import { useLazyQuery, useQuery } from "@apollo/client";
import { Form, Modal, Radio, Select } from "antd";
import { RcFile } from "antd/lib/upload";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Label1 } from "../../../../components/Text";
import TwButton from "../../../../components/TwButton";
import { ControlContext } from "../../../../context/ControlContext";
import {
  adBoxGroupData,
  adBoxGroupVars,
  AD_BOX_GROUP,
} from "../../../../graphql/schema/adBoxGroup";
import {
  adCustomerAllData,
  adCustomerAllVars,
  adCustomerData,
  adCustomerVars,
  AD_CUSTOMER,
  AD_CUSTOMER_ALL,
} from "../../../../graphql/schema/adCustomer";
import { AdBox } from "../../../../graphql/types/adBox";
import { AdVideo } from "../../../../graphql/types/adVideo";
import "./ads-tab-detail.scss";
import ActiveTimeRange from "./components/ActiveTimeRange";
import CustomerVideos from "./components/CustomerVideos";
import PlayingList from "./components/PlayingList";
import VideoDragger from "./components/VideoDragger";

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};
const formatTime = "HH:mm";
export type AdBoxLocationState = {
  ads: AdBox;
};
export interface PlayVideoItem extends AdVideo {
  status: "SUCCESSFUL" | "PROCESSING" | "FAILED";
  batchId?: string;
  clientId?: string;
  file?: RcFile;
}
export interface CustomerVideoItem extends AdVideo {
  isSelected: boolean;
}

const AdsBoxDetail: React.FC = () => {
  const [enableShowModalBtn, setEnableShowModalBtn] = useState<boolean>(true);
  const { id } = useParams();
  const { setBreadcrumbText } = React.useContext(ControlContext)!;
  const [addMode, setAddMode] = React.useState<number>(1);
  const [currentCustomerId, setCurrentCustomerId] = React.useState<
    number | undefined
  >(undefined);
  const [currentPlaylist, setCurrentPlaylist] = React.useState<PlayVideoItem[]>(
    []
  );
  const [videosByCustomer, setVideosByCustomer] = React.useState<
    CustomerVideoItem[]
  >([]);
  const [customer, setCustomer] = React.useState<string>("Chọn nhà sản xuất");

  useQuery<adBoxGroupData, adBoxGroupVars>(AD_BOX_GROUP, {
    variables: { id: Number(id!) },
    onCompleted({ adBoxGroup }) {
      setBreadcrumbText(adBoxGroup.name);
      if (adBoxGroup?.contents) {
        let { contents } = adBoxGroup;
        contents = contents.filter((c) => c.video !== null) || [];
        const listVideo = contents.map((item) => item.video!);
        const tmpPlaylist: PlayVideoItem[] = listVideo.map((item) => ({
          ...item,
          status: "SUCCESSFUL",
        }));
        setCurrentPlaylist(tmpPlaylist);
      }
    },
  });

  const { data: adCustomers } = useQuery<adCustomerAllData, adCustomerAllVars>(
    AD_CUSTOMER_ALL,
    {
      variables: { paging: { count: 10 } },
    }
  );
  const [adCustomerLazyQuery] = useLazyQuery<adCustomerData, adCustomerVars>(
    AD_CUSTOMER
  );

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (currentCustomerId !== undefined && addMode === 1) {
      adCustomerLazyQuery({
        variables: {
          id: currentCustomerId,
        },
      }).then((rs) => {
        let listVideo: AdVideo[] =
          rs.data?.adCustomer?.videos?.filter((video) => video !== null) || [];
        const listVideoMap: CustomerVideoItem[] = listVideo.map((item) => ({
          ...item,
          isSelected: false,
        }));
        setVideosByCustomer(listVideoMap);
      });
    }
  }, [currentCustomerId, addMode]);
  useEffect(() => {
    const isProcessing = currentPlaylist.some(
      (item) => item.status === "PROCESSING"
    );
    if (isProcessing) {
      setEnableShowModalBtn(false);
    } else {
      setEnableShowModalBtn(true);
    }
  }, [currentPlaylist]);

  return (
    <>
      <div className="w-full flex flex-col flex-auto overflow-hidden">
        <ActiveTimeRange />
        <div className="flex flex-row justify-between items-center">
          <Label1>Danh sách phát</Label1>
          <TwButton
            className="!rounded-[1.25rem]"
            sizeBtn="md"
            variant="OUTLINED"
            onClick={showModal}
            disabled={!enableShowModalBtn}>
            Thêm vào danh sách
          </TwButton>
        </div>
        <div className="flex-auto overflow-y-scroll grid grid-cols-3 gap-[0.5rem] mt-[1rem]">
          <PlayingList
            playList={currentPlaylist}
            setPlayList={setCurrentPlaylist}
          />
        </div>
      </div>
      <Modal
        title="Thêm clip vào danh sách phát"
        visible={isModalVisible}
        maskClosable={false}
        destroyOnClose
        onCancel={handleCloseModal}
        width={900}
        footer={false}>
        <Form {...formItemLayout} layout={"horizontal"}>
          <Form.Item label="Nguồn">
            <Radio.Group
              onChange={(e) => {
                setAddMode(e.target.value);
              }}
              value={addMode}>
              <Radio value={1}>Có sẵn</Radio>
              <Radio value={2}>Thêm mới</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Nhà sản xuất">
            <Select
              placeholder="Chọn nhà sản xuất"
              value={customer}
              options={adCustomers?.adCustomerAll.map((customer) => ({
                key: customer.id,
                value: customer.name,
              }))}
              onSelect={(
                _value: string,
                option: { key?: number; value?: string }
              ) => {
                setCurrentCustomerId(option.key);
                setCustomer(option.value || "");
              }}
            />
          </Form.Item>
          {addMode === 2 && currentCustomerId !== undefined && (
            <VideoDragger
              onOffModal={setIsModalVisible}
              playlist={currentPlaylist}
              setPlaylist={setCurrentPlaylist}
              customerId={currentCustomerId}
            />
          )}
          {addMode === 1 && currentCustomerId !== undefined && (
            <CustomerVideos
              currentPlayList={currentPlaylist}
              setCurrentPlayList={setCurrentPlaylist}
              customerVideoList={videosByCustomer}
              setCustomerVideoList={setVideosByCustomer}
            />
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AdsBoxDetail;
