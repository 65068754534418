import * as React from 'react';
import { IIconProps } from './IIconProps';

const CheckedFillSquare: React.FunctionComponent<IIconProps> = ({ size = 'sm', color = "currentColor" }) => {
    return <>
        {
            size === 'sm' && <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="#35363B" />
                <path d="M12.5 5L6.49999 11.006L3 7.50605" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
        {
            size === 'md' && <></>
        }
        {
            size === 'lg' && <></>
        }
    </>
};

export default CheckedFillSquare;
