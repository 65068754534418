import { gql } from "@apollo/client";
import { Paging } from "../types/common";
import { BoxDetailInput } from "../types/lightBox";
import { TrafficBox, TrafficBoxTimeSlotInput } from "../types/trafficBox";

export type GetTrafficBoxInRangeVars = {
  lat: number;
  lng: number;
  distance: number;
  paging?: Paging;
};
export type GetTrafficBoxInRangeData = {
  getTrafficBoxInRange: TrafficBox[];
  countTrafficBoxInRange: number;
};
export const GET_TRAFFIC_BOX_IN_RANGE = gql`
  query GET_LIGHT_BOX_IN_RANGE(
    $lat: Float!
    $lng: Float!
    $distance: Float!
    $paging: Paging
  ) {
    countTrafficBoxInRange(lat: $lat, lng: $lng, distance: $distance)
    getTrafficBoxInRange(
      lat: $lat
      lng: $lng
      distance: $distance
      paging: $paging
    ) {
      id
      isConnected
      boxId
      name
      PENumber
      address
      lat
      lng
    }
  }
`;
export type getAllTrafficBoxData = {
  getAllTrafficBox: TrafficBox[];
  countAllTrafficBox: number;
};
export type getAllTrafficBoxVars = {
  paging?: Paging;
};
export const GET_ALL_TRAFFIC_BOX = gql`
  query GET_ALL_TRAFFIC_BOX($paging: Paging) {
    getAllTrafficBox(paging: $paging) {
      id
      boxId
      name
      lat
      lng
      PENumber
      address
    }
    countAllTrafficBox
  }
`;
export const COUNT_ALL_TRAFFIC_BOX = gql`
  query COUNT_ALL_TRAFFIC_BOX($paging: Paging) {
    countAllTrafficBox(paging: $paging)
  }
`;

export type getTrafficBoxData = {
  getTrafficBox: TrafficBox;
};
export type getTrafficBoxVars = {
  input: BoxDetailInput;
};
export const GET_TRAFFIC_BOX = gql`
  query GET_TRAFFIC_BOX($input: BoxDetailInput!) {
    getTrafficBox(input: $input) {
      boxId
      name
      lat
      lng
    }
  }
`;
export const GET_TRAFFIC_BOX_BY_NAME = gql`
  query GET_TRAFFIC_BOX_BY_NAME($input: String!, $paging: Paging) {
    getTrafficBox(input: $input, paging: $paging) {
      id
    }
  }
`;

export const GET_TRAFFIC_BOX_TIME_SLOTS = gql`
  query GET_TRAFFIC_BOX_TIME_SLOTS($boxId: String!) {
    getTrafficBoxTimeSlots(boxId: $boxId) {
      mode
      hour
      minute
      green1
      green2
      yellow1
      yellow2
      clearSessionTime
    }
  }
`;

export const ADD_TRAFFIC_BOX = gql`
  mutation ADD_TRAFFIC_BOX($input: TrafficBoxInput) {
    addTrafficBox(input: $input) {
      id
    }
  }
`;
export const UPDATE_TRAFFIC_BOX = gql`
  mutation UPDATE_TRAFFIC_BOX($id: Int!, $input: TrafficBoxInput) {
    updateTrafficBox(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_TRAFFIC_BOX = gql`
  mutation DELETE_TRAFFIC_BOX($id: Int!) {
    deleteTrafficBox(id: $id)
  }
`;

export const SET_CURRENT_TIME_TRAFFIC_BOX = gql`
  mutation SET_CURRENT_TIME_TRAFFIC_BOX($boxId: String!) {
    setCurrentTimeTrafficBox(boxId: $boxId, input: $input)
  }
`;

export type setTimeSlotTrafficBoxData = {
  setTimeSlotTrafficBox: {
    messageId: string;
  };
};
export type setTimeSlotTrafficBoxVars = {
  boxId: string;
  timeSlots: TrafficBoxTimeSlotInput[];
};
export const SET_TIME_SLOT_TRAFFIC_BOX = gql`
  mutation SET_TIME_SLOT_TRAFFIC_BOX(
    $boxId: String!
    $timeSlots: [TrafficBoxTimeSlotInput!]!
  ) {
    setTimeSlotTrafficBox(boxId: $boxId, timeSlots: $timeSlots) {
      messageId
    }
  }
`;

export const SUBSCRIBE_TRAFFIC_BOX_DATA = gql`
  subscription SUBSCRIBE_TRAFFIC_BOX_DATA($boxId: String!) {
    getTrafficBoxInfo(boxId: $boxId) {
      data {
        status
        value
      }
    }
  }
`;
export const GET_TRAFFIC_SET_TIMESLOTS_RESULT = gql`
  query GET_TRAFFIC_SET_TIMESLOTS_RESULT($boxId: String!, $messageId: String!) {
    getTrafficSetTimeSlotsResult(boxId: $boxId, messageId: $messageId)
  }
`;

export const WATCH_MESSAGE_STATUS = gql`
  subscription WATCH_MESSAGE_STATUS($boxId: String!, $messageId: String!) {
    getTrafficSetTimeSlotsResult(boxId: $boxId, messageId: $messageId)
  }
`;
