import { useLazyQuery } from "@apollo/client";
import * as React from "react";
import { useMap } from "react-leaflet";
import { useParams } from "react-router-dom";
import { Body3 } from "../../../../components/Text";
import TrafficCabProvider, {
  useTrafficCab
} from "../../../../context/TrafficContext";
import {
  getTrafficBoxData,
  getTrafficBoxVars,
  GET_TRAFFIC_BOX
} from "../../../../graphql/schema/trafficBox";
import { TrafficBox } from "../../../../graphql/types/trafficBox";
import { usePreventExceed } from "../../../../hooks/usePreventExceed";
import TrafficBoxMarker from "../TrafficTabMap/components/TrafficBoxMarker";

const TrafficDetailMap: React.FC = () => {
  const { boxId } = useParams();
  return (
    <TrafficCabProvider boxIds={[boxId!]}>
      <TrafficCurrentStatus />
    </TrafficCabProvider>
  );
};

const TrafficCurrentStatus = () => {
  const { boxId } = useParams();
  const { status } = useTrafficCab(boxId!);
  const [trafficBox, setTrafficBox] = React.useState<TrafficBox>();
  const map = useMap();
  const [getTrafficBoxLazy] = useLazyQuery<
    getTrafficBoxData,
    getTrafficBoxVars
  >(GET_TRAFFIC_BOX, {
    variables: {
      input: { boxId: boxId! },
    },
    onCompleted(data) {
      const { getTrafficBox } = data;
      const { lat, lng } = getTrafficBox;
      setTrafficBox(getTrafficBox);
      if (lat !== undefined && lng !== undefined) {
        map.setView({ lat, lng });
      }
    },
  });
  usePreventExceed(getTrafficBoxLazy, [boxId]);
  return (
    <div className="absolute top-[1rem] left-[1rem] z-[1000] flex flex-row gap-x-[1rem]">
      {Object.keys(status).length === 0 ? (
        <div className=" h-[100px] px-[24px] bg-neutral-D70 text-neutral-White text-[24px] leading-[100px] rounded-[16px] ml-[32px]">
          Không kết nối
        </div>
      ) : (
        <>
          <TrafficLightBoard
            count={parseInt(status.REMAINING_A || "")}
            color={
              status.COLOR_A === "GREEN"
                ? "GREEN"
                : status.COLOR_A === "RED"
                ? "RED"
                : "YELLOW"
            }
            title="Tuyến 1:"
            className="rounded-[1rem]"
          />
          <TrafficLightBoard
            count={parseInt(status.REMAINING_B || "")}
            color={
              status.COLOR_B === "GREEN"
                ? "GREEN"
                : status.COLOR_B === "RED"
                ? "RED"
                : "YELLOW"
            }
            title="Tuyến 2:"
            className="rounded-[1rem]"
          />
        </>
      )}
      {trafficBox && <TrafficBoxMarker trafficBox={trafficBox!} />}
    </div>
  );
};

const TrafficLightBoard: React.FC<{
  className?: string;
  title: string;
  color: "GREEN" | "RED" | "YELLOW";
  count: number;
}> = ({ className, children, title, color, count, ...props }) => {
  return (
    <div
      className={`bg-neutral-D70 w-[14rem] h-[6.5rem] px-[1.5rem] pb-[1rem] pt-[0.625rem] ${className}`}>
      <Body3 className="text-neutral-L50">{title}</Body3>
      <div
        className="flex flex-row gap-x-[1rem] mt-[0.75rem] text-[1.25rem] leading-[1.625rem] text-neutral-White text-center"
        style={{ fontFamily: "Digital Numbers Regular" }}>
        <div
          className={`${
            color === "RED"
              ? "bg-error drop-shadow-[0_4px_24px_#CE2424]"
              : "bg-[#926B6B] text-neutral-D70"
          } rounded-full w-[3rem] h-[3rem] pt-[11px]`}>
          {(color === "RED" ? count : 0).toString().padStart(2, "0")}
        </div>
        <div
          className={`${
            color === "YELLOW"
              ? "bg-warning drop-shadow-[0_4px_24px_#E5B40E] animate-pulse"
              : "bg-[#A1966C] text-neutral-D70"
          } rounded-full w-[3rem] h-[3rem] pt-[11px]`}>
          {(color === "YELLOW" ? count : 0).toString().padStart(2, "0")}
        </div>
        <div
          className={`${
            color === "GREEN"
              ? "bg-[#0DBC68] drop-shadow-[0_4px_24px_#24CE7C]"
              : "bg-[#5E8572] text-neutral-D70"
          } rounded-full w-[3rem] h-[3rem] pt-[11px]`}>
          {(color === "GREEN" ? count : 0).toString().padStart(2, "0")}
        </div>
      </div>
      {children}
    </div>
  );
};

export default TrafficDetailMap;
