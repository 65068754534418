import { useMutation } from "@apollo/client";
import { Button, Checkbox, Form } from "antd";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { AdBoxLocationState, CustomerVideoItem, PlayVideoItem } from "../..";
import { CTSNotification } from "../../../../../../components/CTSNotification";
import {
  updateAdBoxGroupContentData,
  updateAdBoxGroupContentVars,
  UPDATE_AD_BOX_GROUP_CONTENT,
} from "../../../../../../graphql/schema/adBoxGroup";
import { AdBoxGroupContentInput } from "../../../../../../graphql/types/adBoxGroup";
import { AdVideo } from "../../../../../../graphql/types/adVideo";
import VideoSelect from "../VideoSelect";

interface ICustomerVideosProps {
  customerVideoList: CustomerVideoItem[];
  setCustomerVideoList: (customerVideoList: CustomerVideoItem[]) => void;
  currentPlayList: PlayVideoItem[];
  setCurrentPlayList: (list: PlayVideoItem[]) => void;
}

const CustomerVideos: React.FunctionComponent<ICustomerVideosProps> = ({
  customerVideoList,
  setCustomerVideoList,
  currentPlayList,
  setCurrentPlayList,
}) => {
  const { id } = useParams();
  const handleDeleted = (id: number) => {
    setCustomerVideoList(customerVideoList.filter((item) => item.id !== id));
  };
  const [addLoading, setAddLoading] = React.useState<boolean>(false);
  const [updateAdboxContentMutation] = useMutation<
    updateAdBoxGroupContentData,
    updateAdBoxGroupContentVars
  >(UPDATE_AD_BOX_GROUP_CONTENT);
  const handleSelectVideo = (
    event: React.ChangeEvent<HTMLInputElement>,
    video: AdVideo
  ) => {
    let list = customerVideoList;
    const { checked } = event.target;
    list = list.map((item) => {
      if (item.id === video.id) return { ...item, isSelected: checked };
      return item;
    });
    setCustomerVideoList(list);
  };
  const updateAdContent = async (content: AdBoxGroupContentInput[]) => {
    try {
      const result = await updateAdboxContentMutation({
        variables: {
          id: Number(id!),
          content,
        },
      });
      if (result.data) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };
  const handleAddToListVideo = async () => {
    setAddLoading(true);
    let videosPlaying: AdBoxGroupContentInput[] = currentPlayList.map(
      (item) => ({ videoId: item.id || -1, order: 0 })
    );
    let videosAdd: AdBoxGroupContentInput[] = customerVideoList
      .filter((item) => item.isSelected)
      .map((item) => ({ videoId: item.id || -1, order: 0 }));
    let sumVideo = [...videosPlaying, ...videosAdd].map((item, order) => ({
      ...item,
      order,
    }));
    try {
      const rs = await updateAdContent(sumVideo);
      if (rs) {
        let playListAdd: PlayVideoItem[] = customerVideoList
          .filter((item) => item.isSelected)
          .map((item) => ({
            ...item,
            status: "SUCCESSFUL",
          }));
        setCurrentPlayList([...currentPlayList, ...playListAdd]);
        let v = customerVideoList.map((item) => ({
          ...item,
          isSelected: false,
        }));
        setCustomerVideoList(v);
        setAddLoading(false);
        CTSNotification("success", "Cập nhật danh sách phát thành công");
      } else {
        setAddLoading(false);
        CTSNotification("error", "Cập nhật danh sách phát thất bại");
      }
    } catch (error) {
      setAddLoading(false);
      CTSNotification("error", "Cập nhật danh sách phát thất bại");
    }
  };
  return (
    <>
      <Form.Item name="checkbox-group" label="Chọn video">
        <Checkbox.Group>
          <div className="h-[400px] overflow-y-scroll">
            <div className="grid grid-cols-3 gap-[8px]">
              {customerVideoList.map((video, idx) => {
                const isExisted = currentPlayList.some(
                  (item) => item.id === video.id
                );
                return (
                  <VideoSelect
                    key={`video-select-${idx}`}
                    video={video}
                    checked={video.isSelected}
                    deletedCallback={() => handleDeleted(video.id!)}
                    onChange={(event) => handleSelectVideo(event, video)}
                    isExisted={isExisted}
                  />
                );
              })}
            </div>
          </div>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item className="flex justify-end">
        <div className="flex justify-end">
          <Button
            disabled={!customerVideoList.some((item) => item.isSelected)}
            loading={addLoading}
            type="primary"
            onClick={handleAddToListVideo}>
            Thêm vào danh sách phát
          </Button>
        </div>
      </Form.Item>
    </>
  );
};

export default CustomerVideos;
