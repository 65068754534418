import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

interface IControlContext {
  center: [number, number] | undefined;
  setCenter: Dispatch<SetStateAction<[number, number]>>;
  breadcrumbText: string | undefined;
  setBreadcrumbText: Dispatch<SetStateAction<string | undefined>>;
  distanceMap: number;
  setDistanceMap: Dispatch<SetStateAction<number>>;
}
const INIT_DISTANCE_MAP = 1700;
export const ControlContext = createContext<IControlContext | null>(null);

export const ControlProvider: React.FC = ({ children }) => {
  const [distanceMap, setDistanceMap] = useState(INIT_DISTANCE_MAP);
  const [center, setCenter] = useState<[number, number]>([16.8207692488283, 107.10056742911922]);
  const [breadcrumbText, setBreadcrumbText] = useState<string>();
  const data = {
    center,
    setCenter,
    breadcrumbText,
    setBreadcrumbText,
    distanceMap,
    setDistanceMap,
  };
  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const { latitude, longitude } = position.coords;
      setCenter([latitude, longitude]);
    });
  }, []);
  return (
    <ControlContext.Provider value={data}>{children}</ControlContext.Provider>
  );
};
