import * as React from "react";
// import TrafficBoxIcon from "../../../../../components/Icons/d.svg";
import L, { LeafletMouseEvent } from "leaflet";
import { Marker } from "react-leaflet";
import { useLocation, useNavigate } from "react-router-dom";
import TrafficBoxIcon from "../../../../../components/Icons/TrafficBoxIcon.svg";
import { TrafficBox } from "../../../../../graphql/types/trafficBox";

const trafficBoxIconMaker = new L.Icon({
  iconUrl: TrafficBoxIcon,
  iconRetinaUrl: TrafficBoxIcon,
  // iconAnchor: undefined,
  // popupAnchor: undefined,
  // shadowUrl: undefined,
  // shadowSize: undefined,
  // shadowAnchor: undefined,
  iconSize: new L.Point(24, 24),
  // className: "camera-icon",
});
interface ITrafficBoxMarker {
  trafficBox: TrafficBox;
}
const TrafficBoxMarker: React.FC<ITrafficBoxMarker> = ({ trafficBox }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleContextMenu = (e: LeafletMouseEvent) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
  };

  const handleClick = (e: LeafletMouseEvent) => {
    e.originalEvent.stopPropagation();
    const { pathname } = location;
    if (pathname.split("/").at(-1) === "traffic") navigate(trafficBox.boxId!);
  };
  return (
    <Marker
      // {...props}
      position={[trafficBox?.lat!, trafficBox.lng!]}
      eventHandlers={{
        click: handleClick,
        contextmenu: handleContextMenu,
      }}
      icon={trafficBoxIconMaker}
    />
  );
};

export default TrafficBoxMarker;
