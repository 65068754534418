import * as d3 from "d3";
import * as React from "react";
import { ICurrentChartData } from "../..";
import { Neutral, Variant } from "../../../../../../../../constants/colors";
import { LightBoxRealTimeInfo } from "../../../../../../../../graphql/types/lightBox";
import "./line-chart-2.scss";
import { CurrentChart2Data } from "./lineChart2Data";

const margin = {
  top: 12,
  right: 32,
  bottom: 32,
  left: 48,
};
const width = 700;
const height = 395;
const remainWidth = width - margin.left - margin.right;
const remainHeight = height - margin.top - margin.bottom;
const currentChartFilterData = (
  data: LightBoxRealTimeInfo[]
): LightBoxRealTimeInfo[] => {
  let d = [];
  for (const item of data) {
    if (item.time && item.value) {
      d.push(item);
    }
  }
  return d;
};
const getXDomain = (data: LightBoxRealTimeInfo[]) => {
  const d = currentChartFilterData(data);
  const { length } = d;
  if (length < 2) {
    return [0, 0];
  }
  const minTime = new Date(d[0].time);
  const maxTime = new Date(d[length - 1].time);
  return [new Date(minTime.getTime()), new Date(maxTime.getTime())];
};
const getYDomain = (
  data: CurrentChart2Data,
  highThreadhold: number,
  lowThreadHold: number
): [number, number] => {
  const d = currentChartFilterData(data);
  const { length } = d;
  if (length < 2) {
    return [0, 0];
  }
  let max = d[length - 1].value;
  d.forEach((item) => {
    max = item.value > max ? item.value : max;
  });

  if (max >= highThreadhold + lowThreadHold) return [0, max];
  else {
    return [0, highThreadhold + lowThreadHold];
  }
  // return [0, max]
};

const HEIGHT_RECT_THREADHOLD = 10;
const STROKE_WIDTH_LINE_THREADHOLD = 1;

const timeFormatter = d3.timeFormat("%H:%M");
interface ICurrentChart2Props {
  data: ICurrentChartData;
  lowCurrent?: number;
  highCurrent?: number;
  noLoad: number;
}
const CurrentChart2: React.FunctionComponent<ICurrentChart2Props> = ({
  data,
  lowCurrent,
  highCurrent,
  noLoad,
}) => {
  const svgRef = React.useRef<SVGSVGElement>(null);
  const xScale = d3.scaleTime();
  const yScale = d3.scaleLinear();

  React.useEffect(() => {
    initChart();
    drawLine1();
    drawLine2();
    drawLine3();
    drawThreadHold();
    drawNoLoad();
  }, [data, noLoad, lowCurrent, highCurrent]);
  const initChart = () => {
    const svg = d3.select(svgRef.current);
    drawXAxis();
    drawYAxis();
    svg.selectAll(".tick>line").attr("stroke", "#C3C5CA");
  };

  const drawXAxis = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll(".xAxis").remove();
    const { currentI1, currentI2, currentI3 } = data;
    xScale
      .domain(getXDomain([...currentI1, ...currentI2, ...currentI3]))
      .range([0, remainWidth]);
    const xAxis: d3.Axis<Date> = d3
      .axisBottom<Date>(xScale)
      .ticks(d3.timeHour.every(2))
      .tickFormat((d) => timeFormatter(d))
      .tickSize(remainHeight);
    svg
      .append("g")
      .attr("class", "xAxis")
      .call(xAxis)
      .attr("transform", `translate(${margin.left}, ${margin.top})`);
  };

  const drawYAxis = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll(".yAxis").remove();
    const { currentI1, currentI2, currentI3 } = data;
    const yDomain = getYDomain(
      [...currentI1, ...currentI2, ...currentI3],
      highCurrent || 0,
      lowCurrent || 0
    );
    yScale.domain(yDomain).range([remainHeight, 0]);
    const yAxis: d3.Axis<d3.NumberValue> = d3
      .axisLeft<d3.NumberValue>(yScale)
      .tickValues(d3.range(0, yDomain[1], 1))
      .ticks(3)
      .tickFormat((d) => {
        if (d === Math.floor(yDomain[1])) {
          return parseFloat(d.toString()).toString() + " A";
        }
        return parseFloat(d.toString()).toString();
      })
      .tickSize(remainWidth);
    svg
      .append("g")
      .attr("class", "yAxis")
      .call(yAxis)
      .attr(
        "transform",
        `translate(${remainWidth + margin.left}, ${margin.top})`
      );
  };

  const drawThreadHold = () => {
    drawHighThreadhold();
    drawLowThreadhold();
  };
  const drawHighThreadhold = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll(".high-threadhold").remove();
    svg.selectAll(".high-threadhold-rect").remove();
    svg.selectAll(".high-threadhold-line").remove();
    svg
      .append("rect")
      .attr("width", remainWidth)
      .attr("height", yScale(highCurrent || 0) - HEIGHT_RECT_THREADHOLD)
      .attr("x", margin.left)
      .attr("y", margin.top)
      .classed("high-threadhold", true);

    svg
      .append("line")
      .classed("high-threadhold-line", true)
      .attr("x1", margin.left)
      .attr(
        "y1",
        margin.top + yScale(highCurrent || 0) - STROKE_WIDTH_LINE_THREADHOLD
      )
      .attr("x2", remainWidth + margin.left)
      .attr(
        "y2",
        margin.top + yScale(highCurrent || 0) - STROKE_WIDTH_LINE_THREADHOLD
      )
      .style("stroke", "#EF5E0D")
      .style("stroke-dasharray", 5)
      .style("stroke-width", STROKE_WIDTH_LINE_THREADHOLD);

    svg
      .append("rect")
      .classed("high-threadhold-rect", true)
      .attr("width", remainWidth)
      .attr("height", HEIGHT_RECT_THREADHOLD)
      .attr("x", margin.left)
      .attr("y", margin.top + yScale(highCurrent || 0) - HEIGHT_RECT_THREADHOLD)
      .attr("fill", "#EF5E0D20");
  };
  const drawLowThreadhold = () => {
    const svg = d3.select(svgRef.current);
    svg.select(".low-threadhold-line").remove();
    svg.select(".low-threadhold-rect").remove();
    svg.select(".low-threadhold").remove();
    if (yScale(lowCurrent || 0) + HEIGHT_RECT_THREADHOLD < remainHeight) {
      svg
        .append("rect")
        .attr("width", remainWidth)
        .attr(
          "height",
          remainHeight - yScale(lowCurrent || 0) - HEIGHT_RECT_THREADHOLD
        )
        .attr("x", margin.left)
        .attr(
          "y",
          margin.top + yScale(lowCurrent || 0) + HEIGHT_RECT_THREADHOLD
        )
        .classed("low-threadhold", true);

      svg
        .append("line")
        .classed("low-threadhold-line", true)
        .attr("x1", margin.left)
        .attr("x2", remainWidth + margin.left)
        .attr(
          "y1",
          margin.top + yScale(lowCurrent || 0) + STROKE_WIDTH_LINE_THREADHOLD
        )
        .attr(
          "y2",
          margin.top + yScale(lowCurrent || 0) + STROKE_WIDTH_LINE_THREADHOLD
        )
        .style("stroke", "#EF5E0D")
        .style("stroke-dasharray", 5)
        .style("stroke-width", STROKE_WIDTH_LINE_THREADHOLD);

      svg
        .append("rect")
        .classed("low-threadhold-rect", true)
        .attr("width", remainWidth)
        .attr("height", HEIGHT_RECT_THREADHOLD)
        .attr("x", margin.left)
        .attr("y", margin.top + yScale(lowCurrent || 0))
        .attr("fill", "#EF5E0D20");
    }
  };
  const drawLine1 = () => {
    const svg = d3.select(svgRef.current);
    d3.selectAll(".line-i1").remove();
    svg
      .append("path")
      .classed("line-i1", true)
      .datum(currentChartFilterData(data.currentI1))
      .attr(
        "d",
        d3
          .line<LightBoxRealTimeInfo>()
          .x((d) => {
            return xScale(new Date(d?.time || ""));
          })
          .y((d) => yScale(d?.value))
      )
      .attr("fill", "none")
      .attr("stroke", Variant.Success)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .attr("stroke-width", 1.5);
  };
  const drawLine2 = () => {
    const svg = d3.select(svgRef.current);
    d3.selectAll(".line-i2").remove();
    svg
      .append("path")
      .datum(currentChartFilterData(data.currentI2))
      .classed("line-i2", true)
      .attr(
        "d",
        d3
          .line<LightBoxRealTimeInfo>()
          .x((d) => {
            return xScale(new Date(d?.time || ""));
          })
          .y((d) => yScale(d?.value))
      )
      .attr("fill", "none")
      .attr("stroke", Variant.Warning)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .attr("stroke-width", 1.5);
  };
  const drawLine3 = () => {
    const svg = d3.select(svgRef.current);
    d3.selectAll(".line-i3").remove();
    svg
      .append("path")
      .classed("line-i3", true)
      .datum(currentChartFilterData(data.currentI3))
      .attr(
        "d",
        d3
          .line<LightBoxRealTimeInfo>()
          .x((d) => {
            return xScale(new Date(d?.time || ""));
          })
          .y((d) => yScale(d?.value))
      )
      .attr("fill", "none")
      .attr("stroke", Variant.Error)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .attr("stroke-width", 1.5);
  };
  const drawNoLoad = () => {
    const svg = d3.select(svgRef.current);
    const heightNoload = getYDomain(
      [...data.currentI1, ...data.currentI2, ...data.currentI3],
      highCurrent || 0,
      lowCurrent || 0
    );
    const [min, max] = heightNoload;
    const x = yScale(max - min - noLoad);
    svg.selectAll(".rect-noload").remove();
    svg
      .append("rect")
      .classed("rect-noload", true)
      .attr("width", remainWidth)
      .attr("height", x)
      .attr("x", margin.left)
      .attr("y", remainHeight + margin.top - x)
      .attr("fill", Neutral.L70);
  };
  return (
    <>
      <div>
        <svg ref={svgRef} width={width} height={height}>
          <defs>
            <linearGradient
              id="high-threadhold-gradient"
              gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#EF430D10"></stop>
              <stop offset="100%" stopColor="#EF430D70"></stop>
            </linearGradient>
            <linearGradient
              id="low-threadhold-gradient"
              gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#EF430D70"></stop>
              <stop offset="100%" stopColor="#EF430D10"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default CurrentChart2;
