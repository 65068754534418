import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '../../../../components/Icons';
import { Body3, H4 } from '../../../../components/Text';
import { useAuth } from '../../../../context/AuthContext';

interface IAuthenLayoutProps {
    title: string;
    step?: 0 | 1 | 2 | 3;
}

const AuthenLayout: React.FunctionComponent<IAuthenLayoutProps> = ({ children, title, step = 0 }) => {

    const marginClass = React.useMemo(() => {
        switch (step) {
            case 2:
                return 'ml-[33%]'
            case 3:
                return 'ml-[67%]'
            default:
                return ''
        }
    }, [step])
    const navigate = useNavigate();
    const auth = useAuth();
    return <div className="flex flex-col pt-[46px] pb-[32px] shrink-0 w-[562px] bg-neutral-White h-screen auth-form justify-between">
        <div className="pl-[129px] pr-[105px]">
            <H4>{title}</H4>
            <div className={`w-[288px] h-[4px] bg-neutral-L70 mt-[64px] rounded-[999px] overflow-hidden ${step === 0 && 'opacity-0'}`}>
                <div className={`w-1/3 ${marginClass} h-full bg-neutral-D70`}></div>
            </div>
            <div className={`mt-[27px] mb-[55px] h-[29px]`}>
                <div className={`flex items-center h-full ${step !== 2 && 'hidden'}`}>
                    <div className={`ml-[-50px] w-[50px] text-center flex justify-center cursor-pointer`}
                        onClick={() => {
                            navigate("/login/2fa");
                        }}
                    >
                        <ArrowLeft size="md" />
                    </div>
                    <div className='text-[24px] text-neutral-D90 tracking-[0.04em] font-7'>{auth?.authState.phoneNumber}</div>
                </div>
            </div>
            <div> {children} </div>
        </div>
        <div className="grid grid-cols-2 justify-center gap-[16px]">
            <Body3 className="text-right text-blue-Primary text-[14px]">Chính sách chung</Body3>
            <Body3 className="text-blue-Primary text-[14px]">Chính sách bảo mật</Body3>
            <Body3 className="text-right text-blue-Primary text-[14px]">Chính sách người dùng cuối</Body3>
            <Body3>©CitiSYS. Bảo lưu mọi quyền.</Body3>
        </div>
    </div>;
};

export default AuthenLayout;
