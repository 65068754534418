import { useMutation, useQuery, useSubscription } from "@apollo/client";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Label1, Sub2 } from "../../../../../../components/Text";
import { DetailContext } from "../../../../../../context/LightBoxDetailContext";
import {
  getLightBoxCurrentData,
  getLightBoxCurrentInfoData,
  getLightBoxCurrentInfoVars,
  getLightBoxCurrentVars,
  GET_LIGHT_BOX_CURRENT,
  GET_LIGHT_BOX_CURRENT_INFO,
  setLightBoxDelayCurrentData,
  setLightBoxDelayCurrentVars,
  setLightBoxHighCurrentData,
  setLightBoxHighCurrentVars,
  setLightBoxLowCurrentData,
  setLightBoxLowCurrentVars,
  setLightBoxNoLoadData,
  setLightBoxNoLoadVars,
  SET_LIGHT_BOX_DELAY_CURRENT,
  SET_LIGHT_BOX_HIGH_CURRENT,
  SET_LIGHT_BOX_LOW_CURRENT,
  SET_LIGHT_BOX_NO_LOAD,
} from "../../../../../../graphql/schema/lightBox";
import { GET_LIGHT_BOX_INFO_SUBSCRIPTION } from "../../../../../../graphql/schema/lightBoxSubscription";
import {
  getLightBoxInfoSubscriptionData,
  getLightBoxInfoSubscriptionVars,
} from "../../../../../../graphql/schema/subscription";
import {
  LightBoxDetailState,
  LightBoxRealTimeInfo,
} from "../../../../../../graphql/types/lightBox";
import SettingInput from "../components/SettingInput";
// import { ICurrentChartData } from './components/CurrentChart';
import CurrentChart2 from "./components/CurrentChart2";

interface ICurrentProps {}
const getTimeBefore = (h: number) => {
  const currentTime = new Date();
  currentTime.setHours(currentTime.getHours() - h);
  return currentTime;
};
interface ICurrentInfo {
  currentI1?: number;
  currentI2?: number;
  currentI3?: number;
  highCurrent: number;
  lowCurrent: number;
  delayCurrent: number;
  noLoad: number;
  currentLeakI?: number;
}
export type ICurrentChartData = {
  currentI1: LightBoxRealTimeInfo[];
  currentI2: LightBoxRealTimeInfo[];
  currentI3: LightBoxRealTimeInfo[];
};
const Current: React.FunctionComponent<ICurrentProps> = () => {
  const [currentInfo, setCurrentInfo] = React.useState<ICurrentInfo>({
    currentI1: 0,
    currentI2: 0,
    currentI3: 0,
    highCurrent: 0,
    lowCurrent: 0,
    delayCurrent: 0,
    noLoad: 0,
  });
  const [currentSettingUpdateInfo, setCurrentSettingUpdateInfo] =
    React.useState<ICurrentInfo>({
      currentI1: 0,
      currentI2: 0,
      currentI3: 0,
      highCurrent: 0,
      lowCurrent: 0,
      delayCurrent: 0,
      noLoad: 0,
    });
  const [currentTime] = React.useState(getTimeBefore(24).toISOString());
  const { error } = React.useContext(DetailContext)!;
  const { boxId } = useParams();
  const [currentChartData, setCurrentChartData] =
    React.useState<ICurrentChartData>({
      currentI1: [],
      currentI2: [],
      currentI3: [],
    });

  useQuery<getLightBoxCurrentData, getLightBoxCurrentVars>(
    GET_LIGHT_BOX_CURRENT,
    {
      variables: {
        boxId: boxId!,
        startTime: currentTime,
      },
      onCompleted: (data) => {
        const {
          getLightBoxCurrentI1,
          getLightBoxCurrentI2,
          getLightBoxCurrentI3,
        } = data;
        setCurrentChartData({
          currentI1: getLightBoxCurrentI1,
          currentI2: getLightBoxCurrentI2,
          currentI3: getLightBoxCurrentI3,
        });
      },
    }
  );

  useQuery<getLightBoxCurrentInfoData, getLightBoxCurrentInfoVars>(
    GET_LIGHT_BOX_CURRENT_INFO,
    {
      variables: {
        boxId: boxId!,
      },
      onCompleted: (rs) => {
        const { getLightBoxCurrentInfo } = rs;
        const highCurrent =
          getLightBoxCurrentInfo.find((item) => item.status === "highCurrent")
            ?.value || 0;
        const lowCurrent =
          getLightBoxCurrentInfo.find((item) => item.status === "lowCurrent")
            ?.value || 0;
        const delayCurrent =
          getLightBoxCurrentInfo.find((item) => item.status === "delayCurrent")
            ?.value || 0;
        const noLoad =
          getLightBoxCurrentInfo.find((item) => item.status === "noLoad")
            ?.value || 0;
        const currentI1 =
          getLightBoxCurrentInfo.find((item) => item.status === "currentI1")
            ?.value || 0;
        const currentI2 =
          getLightBoxCurrentInfo.find((item) => item.status === "currentI2")
            ?.value || 0;
        const currentI3 =
          getLightBoxCurrentInfo.find((item) => item.status === "currentI3")
            ?.value || 0;
        const currentLeakI =
          getLightBoxCurrentInfo.find((item) => item.status === "currentLeakI")
            ?.value || 0;
        setCurrentInfo({
          ...currentInfo,
          highCurrent,
          lowCurrent,
          delayCurrent,
          noLoad,
          currentI1,
          currentI2,
          currentI3,
          currentLeakI,
        });
        setCurrentSettingUpdateInfo({
          ...currentSettingUpdateInfo,
          highCurrent,
          lowCurrent,
          delayCurrent,
          noLoad,
        });
      },
    }
  );
  useSubscription<
    getLightBoxInfoSubscriptionData,
    getLightBoxInfoSubscriptionVars
  >(GET_LIGHT_BOX_INFO_SUBSCRIPTION, {
    variables: { boxId: boxId! },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data) {
        const { data } = subscriptionData;
        const { value, status } = data.getLightBoxInfo;
        if (
          status === "currentI1" ||
          status === "currentI2" ||
          status === "currentI3" ||
          "currentLeakI"
        ) {
          setCurrentInfo({ ...currentInfo, [status]: value });
        }
        if (
          status === "currentI1" ||
          status === "currentI2" ||
          status === "currentI3"
        ) {
          let time: any = new Date();
          time = time.toISOString();
          setCurrentChartData({
            ...currentChartData,
            [status]: [...currentChartData[status], { value: value, time }],
          });
        }
      }
    },
  });
  const [setLightBoxHighCurrentMutation] = useMutation<
    setLightBoxHighCurrentData,
    setLightBoxHighCurrentVars
  >(SET_LIGHT_BOX_HIGH_CURRENT);
  const [setLightBoxLowCurrentMutation] = useMutation<
    setLightBoxLowCurrentData,
    setLightBoxLowCurrentVars
  >(SET_LIGHT_BOX_LOW_CURRENT);
  const [setLightBoxDelayCurrentMutation] = useMutation<
    setLightBoxDelayCurrentData,
    setLightBoxDelayCurrentVars
  >(SET_LIGHT_BOX_DELAY_CURRENT);
  const [setLightBoxNoLoadMutation] = useMutation<
    setLightBoxNoLoadData,
    setLightBoxNoLoadVars
  >(SET_LIGHT_BOX_NO_LOAD);

  const handleSetLightBoxHighCurrent = () => {
    setLightBoxHighCurrentMutation({
      variables: {
        boxId: boxId!,
        value: currentSettingUpdateInfo.highCurrent,
      },
    })
      .then(() => {
        setCurrentInfo({
          ...currentInfo,
          highCurrent: currentSettingUpdateInfo.highCurrent,
        });
      })
      .catch(() => {});
  };
  const handleSetLightBoxLowCurrent = () => {
    setLightBoxLowCurrentMutation({
      variables: {
        boxId: boxId!,
        value: currentSettingUpdateInfo.lowCurrent,
      },
    })
      .then(() => {
        setCurrentInfo({
          ...currentInfo,
          lowCurrent: currentSettingUpdateInfo.lowCurrent,
        });
      })
      .catch(() => {});
  };
  const handleSetLightBoxDelayCurrent = () => {
    setLightBoxDelayCurrentMutation({
      variables: {
        boxId: boxId!,
        value: currentSettingUpdateInfo.delayCurrent,
      },
    })
      .then(() => {
        setCurrentInfo({
          ...currentInfo,
          delayCurrent: currentSettingUpdateInfo.delayCurrent,
        });
      })
      .catch(() => {});
  };
  const handleSetLightBoxNoLoad = () => {
    setLightBoxNoLoadMutation({
      variables: {
        boxId: boxId!,
        value: currentSettingUpdateInfo.noLoad,
      },
    })
      .then(() => {
        setCurrentInfo({
          ...currentInfo,
          noLoad: currentSettingUpdateInfo.noLoad,
        });
      })
      .catch(() => {});
  };
  const handleChangeCurrentSetting = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setCurrentSettingUpdateInfo({
      ...currentSettingUpdateInfo,
      [name]: Number(value),
    });
  };

  return (
    <>
      {error?.errPM ? (
        <div className="text-error font-7 text-[24px] h-[500px] flex justify-center items-center">
          Không có kết nối PM
        </div>
      ) : (
        <div className="px-[24px] pb-[24px]">
          <div className="h-[400px]">
            <CurrentChart2
              data={currentChartData}
              lowCurrent={currentInfo.lowCurrent}
              highCurrent={currentInfo.highCurrent}
              noLoad={currentInfo.noLoad}
            />
          </div>
          <div className="py-[30px] flex justify-between">
            <div>
              <Sub2 className="color-neutral-D50">Cài đặt thông số</Sub2>
              <div className="flex flex-col gap-[16px] mt-[16px]">
                <SettingInput
                  label="Ngưỡng cao dòng điện pha"
                  type="number"
                  step={0.1}
                  isSubmited={
                    currentInfo.highCurrent ===
                    currentSettingUpdateInfo.highCurrent
                  }
                  onChange={handleChangeCurrentSetting}
                  onPressEnter={handleSetLightBoxHighCurrent}
                  name="highCurrent"
                  value={currentSettingUpdateInfo.highCurrent}
                  unit="A"
                />
                <SettingInput
                  label="Ngưỡng thấp dòng điện pha"
                  type="number"
                  step={0.1}
                  isSubmited={
                    currentInfo.lowCurrent ===
                    currentSettingUpdateInfo.lowCurrent
                  }
                  onChange={handleChangeCurrentSetting}
                  onPressEnter={handleSetLightBoxLowCurrent}
                  name="lowCurrent"
                  value={currentSettingUpdateInfo.lowCurrent}
                  unit="A"
                />
                <SettingInput
                  label="Độ trễ dòng điện pha"
                  type="number"
                  step={0.1}
                  isSubmited={
                    currentInfo.delayCurrent ===
                    currentSettingUpdateInfo.delayCurrent
                  }
                  onChange={handleChangeCurrentSetting}
                  onPressEnter={handleSetLightBoxDelayCurrent}
                  name="delayCurrent"
                  value={currentSettingUpdateInfo.delayCurrent}
                  unit="A"
                />
                <SettingInput
                  label="Ngưỡng không tải"
                  type="number"
                  step={0.1}
                  isSubmited={
                    currentInfo.noLoad === currentSettingUpdateInfo.noLoad
                  }
                  onChange={handleChangeCurrentSetting}
                  onPressEnter={handleSetLightBoxNoLoad}
                  name="noLoad"
                  value={currentSettingUpdateInfo.noLoad}
                  unit="A"
                />
              </div>
            </div>
            <div className="w-[256px] h-[128px] shadow-lg p-[22px] flex flex-col justify-around">
              <ParamItem
                status="success"
                name="Pha 1"
                value={currentInfo.currentI1}
                unit="A"
              />
              <ParamItem
                status="warning"
                name="Pha 2"
                value={currentInfo.currentI2}
                unit="A"
              />
              <ParamItem
                status="error"
                name="Pha 3"
                value={currentInfo.currentI3}
                unit="A"
              />
              <ParamItem
                status="neutral-D70"
                name="Dòng rò"
                value={currentInfo.currentLeakI}
                unit="mA"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Current;

interface IParamItemProps {
  name?: string;
  value?: number;
  status?: "success" | "warning" | "error" | "neutral-D70";
  unit?: string;
}

const ParamItem: React.FC<IParamItemProps> = ({
  name = "",
  value = 0,
  unit = "",
  status = "success",
}) => {
  return (
    <div className="flex items-center gap-[8px]">
      <Label1 className="text-neutral-D90">{name}</Label1>
      <div className={`h-[3px] w-[80px] bg-${status}`} />
      <Label1 className="text-neutral-D90">
        {value.toFixed(2)} {unit}
      </Label1>
    </div>
  );
};
