import { useLazyQuery } from "@apollo/client";
import * as React from "react";
import { useMap } from "react-leaflet";
import { useParams } from "react-router-dom";
import {
  adBoxGroupData,
  adBoxGroupVars,
  AD_BOX_GROUP,
} from "../../../../graphql/schema/adBoxGroup";
import { AdBox } from "../../../../graphql/types/adBox";
import { usePreventExceed } from "../../../../hooks/usePreventExceed";
import AdsMarker from "./components/AdsMarker";

const AdsDetailMap: React.FC = () => {
  const { id } = useParams();
  const map = useMap();
  const [adBoxs, setAdsBoxs] = React.useState<AdBox[]>([]);
  const [adBoxGroupLazy] = useLazyQuery<adBoxGroupData, adBoxGroupVars>(
    AD_BOX_GROUP,
    {
      variables: {
        id: Number(id!),
      },
      onCompleted(data) {
        const { adBoxs } = data.adBoxGroup;
        setAdsBoxs(adBoxs || []);
        if (adBoxs && adBoxs.length > 0) {
          const { lat, lng } = adBoxs[0];
          if (lat !== undefined && lng !== undefined) {
            map.setView({ lat, lng });
          }
        }
      },
    }
  );
  usePreventExceed(adBoxGroupLazy, [id]);
  return (
    <>
      {adBoxs.map((adbox, idx) => (
        <AdsMarker ads={adbox} key={`adsbox-${idx}`} />
      ))}
    </>
  );
};

export default AdsDetailMap;
