import { useLazyQuery, useQuery } from "@apollo/client";
import { Table } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ControlContext } from "../../../context/ControlContext";
import {
  getAllCameraData,
  getAllCameraVars,
  GET_ALL_CAMERA,
} from "../../../graphql/schema/camera";
import { Camera } from "../../../graphql/types/camera";
import { useCalcTableScrollHeight } from "../../../hooks/useCalcTableScrollHeight";
import { usePagination } from "../../../hooks/usePagination";
import { usePreventExceed } from "../../../hooks/usePreventExceed";
import { mapKey } from "../../../utils/mapKey";
import "./box-list.scss";

interface ICameraTabProps {}

const CameraTab: React.FunctionComponent<ICameraTabProps> = (props) => {
  const navigate = useNavigate();
  const [cameras, setCameras] = React.useState<Camera[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  const { paging, PaginationElement } = usePagination(total);

  const handleClickName = (record: Camera) => {
    navigate(record.id.toString());
  };

  const [getCameraLazy] = useLazyQuery<getAllCameraData, getAllCameraVars>(
    GET_ALL_CAMERA,
    {
      variables: {
        paging,
      },
      onCompleted(data) {
        setCameras(data.getAllCamera || []);
        setTotal(data.countAllCamera || 0);
      },
    }
  );
  const { setBreadcrumbText } = React.useContext(ControlContext)!;
  React.useEffect(() => {
    setBreadcrumbText(undefined);
  }, []);
  usePreventExceed(getCameraLazy, [paging]);

  const handleOnRow = (record: Camera) => {
    return {
      onClick: () => handleClickName(record),
    };
  };
  const columns = [
    {
      title: "Tên camera",
      dataIndex: "name",
    },
    {
      title: "Số serial",
      dataIndex: "serialNumber",
    },
    {
      title: "Địa chỉ",
      dataIndex: "province",
    },
  ];
  const { scrollHeight, tableRef } = useCalcTableScrollHeight();
  return (
    <>
      <Table
        ref={tableRef}
        scroll={{
          x: true,
          y: scrollHeight,
        }}
        className="flex-auto"
        columns={columns}
        dataSource={mapKey(cameras)}
        onRow={handleOnRow}
        pagination={false}
      />
      <PaginationElement />
    </>
  );
};

export default CameraTab;
