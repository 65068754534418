import { useLazyQuery } from "@apollo/client";
import * as React from "react";
import { useMap } from "react-leaflet";
import { useParams } from "react-router-dom";
import {
  getLightBoxData,
  getLightBoxVars,
  GET_LIGHT_BOX
} from "../../../../../graphql/schema/lightBox";
import { LightBox } from "../../../../../graphql/types/lightBox";
import { usePreventExceed } from "../../../../../hooks/usePreventExceed";
import LightBoxMarker from "../../LightingTabMap/components/LightBoxMarker";

const LightingTabMap: React.FC = () => {
  const { boxId } = useParams();
  const map = useMap();
  const [lightBox, setLightBox] = React.useState<LightBox>();
  const [getLightBoxDetailLazy] = useLazyQuery<
    getLightBoxData,
    getLightBoxVars
  >(GET_LIGHT_BOX, {
    variables: {
      input: {
        boxId,
      },
    },
    onCompleted(data) {
      const { getLightBox } = data;
      const { lat, lng } = getLightBox;
      setLightBox(getLightBox);
      if (lat !== undefined && lng !== undefined) {
        map.setView({ lat, lng });
      }
    },
  });
  usePreventExceed(getLightBoxDetailLazy, [boxId]);
  return <>{lightBox && <LightBoxMarker lightBox={lightBox} />}</>;
};

export default LightingTabMap;
