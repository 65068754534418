import * as React from "react";
import { IIconProps } from "./IIconProps";

const ArrowUp2: React.FunctionComponent<IIconProps> = ({
  size = "sm",
  color = "currentColor",
}) => {
  return (
    <>
      <svg
        width={
          size === "sm" ? 16 : size === "md" ? 24 : size === "lg" ? 32 : 24
        }
        height={
          size === "sm" ? 16 : size === "md" ? 24 : size === "lg" ? 32 : 24
        }
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="12" y1="19" x2="12" y2="5"></line>
        <polyline points="5 12 12 5 19 12"></polyline>
      </svg>
    </>
  );
};

export default ArrowUp2;
