import * as d3 from "d3";
import { PieArcDatum } from "d3-shape";
import * as React from "react";

const mapToChartData = (
  data: LightNumChartData[],
  num: number,
  flag?: boolean
): LightNumChartData[] => {
  const connectedLight = flag ?
    data.find((item) => item.label === "connectedLight")?.count || 0 : num;
  const errorLight =
    data.find((item) => item.label === "errorLight")?.count || 0;
  const offLight = data.find((item) => item.label === "offLight")?.count || 0;
  const onLight = connectedLight - errorLight - offLight;
  const disConnected = num - connectedLight;
  return [
    {
      label: "onLight",
      count: onLight,
    },
    {
      label: "offLight",
      count: offLight,
    },
    { label: "disConnected", count: disConnected },
  ];
};

interface ILightNumChartProps {
  data: LightNumChartData[];
  num: number;
  flag?: boolean;
}
export interface LightNumChartData {
  label: string;
  count: number;
}
const width = 176;
const height = 128;
const radius = Math.min(96, 96) / 2;
const donutWidth = 23;
const LightNumChart: React.FunctionComponent<ILightNumChartProps> = ({
  data,
  num,
  flag
}) => {
  const chartRef = React.createRef<HTMLDivElement>();

  // React.useEffect(() => {
  //   const on_ =
  //     data.find((item) => item.label === "connectedLight")?.count || 0;
  //   const error_ = data.find((item) => item.label === "errorLight")?.count || 0;
  //   const dis_ = num - on_ - error_;
  // }, [data]);
  const realChartData = React.useMemo(() => {
    return mapToChartData(data, num, flag);
  }, [data, num]);
  React.useEffect(() => {
    updateChart();
  }, [realChartData]);

  const updateChart = () => {
    d3.select(chartRef.current).selectAll("svg").remove();
    if (num !== -1) {
      const chart = d3
        .select(chartRef.current)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + 64 + "," + 64 + ")");
      const color = d3.scaleOrdinal<number, string>([
        "#25E7A1",
        "#F07171",
        "#E8E9EC",
      ]);
      const arc = d3
        .arc<PieArcDatum<LightNumChartData>>()
        .innerRadius(radius - donutWidth)
        .outerRadius(radius);

      const pie = d3
        .pie<LightNumChartData>()
        .value(function (d) {
          return d.count;
        })
        .sort(null);
      const path = chart.selectAll("path");
      path
        .enter()
        .data(pie(mapToChartData(data, num)))
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d, i) {
          return color(i);
        });
      chart
        .append("text")
        .text(num > 0 ? num : "Không có đèn")
        .attr("dominant-baseline", "middle")
        .attr("text-anchor", "middle");

      d3.select(chartRef.current)
        .select("svg")
        .append("circle")
        .attr("cx", 130)
        .attr("cy", 36)
        .attr("r", 4)
        .attr("fill", "#25E7A1")
        .append("title")
        .text("Sáng");
      d3.select(chartRef.current)
        .select("svg")
        .append("text")
        .text(
          realChartData.find((item) => item.label === "onLight")?.count || 0
        )
        .attr("x", 145)
        .attr("y", 40);
      d3.select(chartRef.current)
        .select("svg")
        .append("circle")
        .attr("cx", 130)
        .attr("cy", 60)
        .attr("r", 4)
        .attr("fill", "#F07171")
        .append("title")
        .style("font-size", "18px")
        .text("Tắt");
      d3.select(chartRef.current)
        .select("svg")
        .append("text")
        .text(
          realChartData.find((item) => item.label === "offLight")?.count || 0
        )
        .attr("x", 145)
        .attr("y", 65);

      d3.select(chartRef.current)
        .select("svg")
        .append("circle")
        .attr("cx", 130)
        .attr("cy", 84)
        .attr("r", 4)
        .attr("fill", "#E8E9EC")
        .append("title")
        .text("Mất kết nối");
      d3.select(chartRef.current)
        .select("svg")
        .append("text")
        .text(
          realChartData.find((item) => item.label === "disConnected")?.count ||
            0
        )
        .attr("x", 145)
        .attr("y", 88);
    }
  };
  return (
    <div
      ref={chartRef}
      style={{
        width: "176px",
        height: "128px",
      }}>
      {num === -1 && <div className="leading-[128px] text-center text-[20px] font-6">Không có đèn</div>}
    </div>
  );
};

export default LightNumChart;
