import * as React from "react";
// import LightBoxIcon from "../../../../../components/Icons/d.svg";
import L, { LeafletMouseEvent } from "leaflet";
import { Marker } from "react-leaflet";
import { useLocation, useNavigate } from "react-router-dom";
import LightBoxIcon from "../../../../../components/Icons/LightBoxIcon.svg";
import { LightBox } from "../../../../../graphql/types/lightBox";

const lightBoxIconMaker = new L.Icon({
  iconUrl: LightBoxIcon,
  iconRetinaUrl: LightBoxIcon,
  // iconAnchor: undefined,
  // popupAnchor: undefined,
  // shadowUrl: undefined,
  // shadowSize: undefined,
  // shadowAnchor: undefined,
  iconSize: new L.Point(24, 24),
  // className: "camera-icon",
});
interface ILightBoxMarker {
  lightBox: LightBox;
}
const LightBoxMarker: React.FC<ILightBoxMarker> = ({ lightBox }) => {
  const navigate = useNavigate();

  const handleContextMenu = (e: LeafletMouseEvent) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
  };
  const { pathname } = useLocation();
  const handleClick = (e: LeafletMouseEvent) => {
    e.originalEvent.stopPropagation();
    if (pathname.split("/").at(-1) === "lighting") {
      navigate(lightBox.boxId!);
    }
  };

  return (
    <Marker
      position={{
        lat: lightBox.lat!,
        lng: lightBox.lng!,
      }}
      eventHandlers={{
        click: handleClick,
        contextmenu: handleContextMenu,
      }}
      icon={lightBoxIconMaker}
    />
  );
};

export default LightBoxMarker;
