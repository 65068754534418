import { PlayCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Image, Modal, Popconfirm } from 'antd';
import * as React from 'react';
import { useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import { CTSNotification } from '../../../../../../components/CTSNotification';
import { AuthContext } from '../../../../../../context/AuthContext';
import { deleteAdVideosData, deleteAdVideosVars, DELETE_AD_VIDEO } from '../../../../../../graphql/schema/adVideo';
import { AdVideo } from '../../../../../../graphql/types/adVideo';
interface IVideoSelectProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    video: AdVideo;
    isExisted: boolean;
    deletedCallback: () => void;
}

const VideoSelect: React.FunctionComponent<IVideoSelectProps> = ({ video, isExisted, deletedCallback, ...props }) => {
    const { userId } = useContext(AuthContext)?.authState!;
    const [deleteAdVideoMutation, { data, loading, error }] = useMutation<deleteAdVideosData, deleteAdVideosVars>(DELETE_AD_VIDEO);
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [playing, setPlaying] = useState<boolean>(true);
    const handleDeleteVideo = async () => {
        try {
            const deleteVideoRs = await deleteAdVideoMutation({
                variables: {
                    ids: [video.id!],
                    customerId: video.customerId!
                }
            });
            if (deleteVideoRs.data) {
                CTSNotification("success", 'Xóa video thành công');
                deletedCallback();
            } else {
                CTSNotification("warning", 'Xóa video thất bại');
            }
        } catch (error) {
            CTSNotification("warning", 'Xóa video thất bại');
        }
    }
    const pause = () => {
        setPlaying(false);
    }
    return <>
        {
            isExisted ? <>
                <label className='relative aspect-[160/200] rounded-[4px] overflow-hidden'>
                    <div className="absolute inset-0 bg-blue-D90 z-10 opacity-50"></div>
                    <div className="leading-[44px] uppercase px-[16px] text-[12px] bg-warning text-neutral-White relative z-20">Đã thêm vào danh sách phát</div>
                    <Image src={video.thumbnailUrl} width={'100%'} height={'100%'} preview={false} />
                    <div className="text-[14px] font-7 bg-neutral-D30 absolute bottom-0 w-full opacity-80 h-[80px] p-[8px]">
                        <p className="line-clamp-3 text-neutral-White">{video.filename}</p>
                    </div>
                </label>
            </> : <label className='relative aspect-[160/200] rounded-[4px] overflow-hidden'>
                <div className='absolute top-0 left-0 w-full z-20 flex justify-end'>
                    <Popconfirm title="Bạn có chắc chắn muốn xóa？" onConfirm={handleDeleteVideo} okText="Đồng ý" cancelText="Hủy">
                        <a className='bg-[#ff1a1a] hover:bg-[#cc0000] hover:text-neutral-White font-7 text-neutral-White px-[16px] py-[8px] rounded-bl-[8px]' href="#">Xóa</a>
                    </Popconfirm>
                </div>
                <div className='absolute transform -translate-y-1/2 left-0 top-1/2 w-full flex justify-center items-center z-20'>
                    <button className='h-[100px] w-[100px] text-neutral-White text-[50px]' onClick={() => setVisibleModal(true)} >
                        <PlayCircleOutlined />
                    </button>
                </div>

                <input type='checkbox' className="absolute left-[20px] top-[20px] z-30 rounded-full overflow-hidden" {...props} />
                <Image src={video.thumbnailUrl} width={'100%'} height={'100%'} preview={false} />
                <div className="text-[14px] font-7 bg-neutral-D30 absolute bottom-0 w-full opacity-80 h-[80px] p-[8px]">
                    <p className="line-clamp-3 text-neutral-White">{video.filename}</p>
                </div>
            </label>
        }
        <Modal
            destroyOnClose
            width={300}
            title={video.filename}
            visible={visibleModal}
            footer={null}
            onCancel={() => setVisibleModal(false)}
            afterClose={pause}
            bodyStyle={{ padding: 0 }}
        >
            <div className='w-[300px] aspect-[256/448]'>
                <ReactPlayer width={'100%'}
                    loop
                    controls
                    height={"100%"}
                    playing={playing}
                    url={video.rawUrl} />
            </div>
        </Modal>
    </>
};

export default VideoSelect;
