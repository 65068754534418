import * as React from 'react';

interface ITwButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "CONTAINED" | "OUTLINED" | "TEXT";
    sizeBtn?: "md" | "lg";
}

const TwButton: React.FunctionComponent<ITwButtonProps> = ({ variant = "CONTAINED", sizeBtn = "md", children, className, ...props }) => {
    const commonClass = 'flex justify-center items-center border rounded';
    const classSize = sizeBtn === 'md' ?
        `h-[38px] px-[22px] tracking-[0.02em] gap-[10px] text-[16px] ${commonClass}` :
        `h-[62px] px-[38px] tracking-[0.02em] gap-[10px] text-[18px] ${commonClass}`;
    return <>
        {variant === "CONTAINED" &&
            <button className={`bg-blue-Secondary hover:bg-blue-Primary active:bg-blue-D30 disabled:bg-neutral-L70 text-neutral-White font-7 border-transparent  ${classSize} ${className}`} {...props}>
                {children}
            </button>
        }
        {variant === "OUTLINED" &&
            <button className={`bg-transparent hover:bg-blue-L30 active:bg-blue-L60 disabled:bg-transparent  text-blue-Secondary disabled:text-neutral-L70 font-7 border-neutral-L70 ${classSize} ${className}`} {...props}>
                {children}
            </button>
        }
        {variant === "TEXT" &&
            <button className={`bg-transparent text-blue-Secondary disabled:text-neutral-L90 border-transparent font-4 hover:font-7 disabled:font-4 active:underline ${classSize} ${className}`} {...props}>
                {children}
            </button>
        }
    </>;
};

export default TwButton;
