import { useLazyQuery } from "@apollo/client";
import * as React from "react";
import { ControlContext } from "../../../context/ControlContext";
import {
  getCameraInRangeData,
  getCameraInRangeVars,
  GET_CAMERA_IN_RANGE
} from "../../../graphql/schema/camera";
import { Camera } from "../../../graphql/types/camera";
import { usePreventExceed } from "../../../hooks/usePreventExceed";
import CameraMarker from "./CameraTabDetail/components/CameraMaker";

const CameraTabMap: React.FC = () => {
  const [cameras, setCameras] = React.useState<Camera[]>([]);
  const { center, distanceMap } = React.useContext(ControlContext)!;
  const [getCameraInRange] = useLazyQuery<
    getCameraInRangeData,
    getCameraInRangeVars
  >(GET_CAMERA_IN_RANGE, {
    variables: {
      distance: distanceMap,
      lat: center?.[0]!,
      lng: center?.[1]!,
    },
    onCompleted(data) {
      setCameras(data.getCameraInRange);
    },
  });
  usePreventExceed(getCameraInRange, [distanceMap, center]);
  return (
    <>
      {cameras.map((camera, idx) => (
        <CameraMarker camera={camera} key={`camera-${idx}`} />
      ))}
    </>
  );
};

export default CameraTabMap;
