import { useLazyQuery } from "@apollo/client";
import * as React from "react";
import { useMap } from "react-leaflet";
import { useParams } from "react-router-dom";
import {
  cameraData,
  cameraVars,
  GET_CAMERA,
} from "../../../../graphql/schema/camera";
import { Camera } from "../../../../graphql/types/camera";
import { usePreventExceed } from "../../../../hooks/usePreventExceed";
import CameraMarker from "./components/CameraMaker";

const CameraDetailMap: React.FC = () => {
  const { id } = useParams();
  const map = useMap();
  const [camera, setCamera] = React.useState<Camera>();
  const [getCameraLazy] = useLazyQuery<cameraData, cameraVars>(GET_CAMERA, {
    variables: { id: Number(id!) },
    onCompleted(data) {
      const { getCamera } = data;
      const { lat, lng } = getCamera;
      setCamera(getCamera);
      if (lat !== undefined && lng !== undefined) {
        map.setView({ lat, lng });
      }
    },
  });
  usePreventExceed(getCameraLazy, [id]);

  return <>{camera && <CameraMarker camera={camera} />}</>;
};

export default CameraDetailMap;
