import { InboxOutlined } from "@ant-design/icons";
import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadFile } from 'antd/lib/upload/interface';
import * as React from 'react';
import { v4 as uuidv4 } from "uuid";
import { PlayVideoItem } from '../..';
import type { UploadProps } from 'antd';

interface IVideoDraggerProps {
    setPlaylist: (list: PlayVideoItem[]) => void;
    playlist: PlayVideoItem[];
    customerId: number | undefined;
    onOffModal: (visible: boolean) => void;
}

const VideoDragger: React.FunctionComponent<IVideoDraggerProps> = ({onOffModal, setPlaylist, playlist, customerId }) => {
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const uploadHandle = (file: RcFile) => {
        let batchId = uuidv4();
        let clientId = uuidv4();
        setPlaylist([...playlist, { status: "PROCESSING", file, customerId: customerId, batchId, clientId }])
        return '';
    }
    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        const { length } = newFileList;
        const batchId = uuidv4();
        newFileList[length - 1].status = "done";
        newFileList[length - 1].uid = batchId;
        setFileList(newFileList);
        onOffModal(false);
    };
    return <>
        <Form.Item label="Dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <Dragger
                action={uploadHandle}
                listType="picture"
                fileList={fileList}
                maxCount={1}
                className="upload-list-inline"
                onChange={onChange}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Dragger>
        </Form.Item>
    </>;
};

export default VideoDragger;
