import { useLazyQuery } from "@apollo/client";
import { Input, Select, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Variant } from "../../../constants/colors";
import { ControlContext } from "../../../context/ControlContext";
import {
  getAllLightBoxData,
  getAllLightBoxVars,
  GetLightBoxInRangeData,
  GetLightBoxInRangeVars,
  GET_ALL_LIGHT_BOX,
  GET_LIGHT_BOX_IN_RANGE,
  searchLightBoxWithFilterData,
  searchLightBoxWithFilterVars,
  SEARCH_LIGHT_BOX_WITH_FILTER,
} from "../../../graphql/schema/lightBox";
import { LightBox, LightBoxDetailState } from "../../../graphql/types/lightBox";
import { useCalcTableScrollHeight } from "../../../hooks/useCalcTableScrollHeight";
import { INITIAL_PAGING, usePagination } from "../../../hooks/usePagination";
import { usePreventExceed } from "../../../hooks/usePreventExceed";
import { mapKey } from "../../../utils/mapKey";
import "./box-list.scss";
const { Option } = Select;

const columns: ColumnsType<LightBox> = [
  {
    title: "Tên tủ",
    dataIndex: "name",
    width: "130px",
  },
  {
    title: "Trạng thái",
    dataIndex: "isConnected",
    width: "130px",
    render: (value) => {
      return (
        <>
          {value === 0 ? (
            <Tag color={Variant.Error}>Không kết nối</Tag>
          ) : (
            <Tag color={Variant.Success}>Kết nối</Tag>
          )}
        </>
      );
    },
  },
  {
    title: "Mã PE",
    dataIndex: "PENumber",
    width: "100px",
  },
  {
    title: "Mã tủ",
    dataIndex: "boxId",
    width: "100px",
  },
  {
    title: "Địa chỉ",
    dataIndex: "address",
  },
];
interface ILightingTabProps {}
const LightingTab: React.FunctionComponent<ILightingTabProps> = (props) => {
  const { tableRef, scrollHeight } = useCalcTableScrollHeight();
  const [distance, setDistance] = React.useState<number>(1000);
  const [name, setName] = React.useState<string>("");
  const [lightBoxs, setLightBoxs] = React.useState<LightBox[]>([]);
  const navigate = useNavigate();
  const [total, setTotal] = React.useState<number>(0);
  const { PaginationElement, paging, setPaging } = usePagination(total);

  const [getLightBoxInRangeLazy, { loading: getLightBoxInRangeLoading }] =
    useLazyQuery<GetLightBoxInRangeData, GetLightBoxInRangeVars>(
      GET_LIGHT_BOX_IN_RANGE,
      {
        onCompleted(data) {
          setLightBoxs(data.getLightBoxInRange || []);
          setTotal(data.countLightBoxInRange || 0);
        },
      }
    );
  const [searchLightBoxWithFilterLazy, { loading: searchLightBoxLoading }] =
    useLazyQuery<searchLightBoxWithFilterData, searchLightBoxWithFilterVars>(
      SEARCH_LIGHT_BOX_WITH_FILTER,
      {
        variables: { input: name, paging },
        onCompleted(data) {
          setLightBoxs(data.searchLightBoxWithFilter || []);
          setTotal(data.countLightBoxWithFilter || 0);
        },
      }
    );
  const [getAllLightBoxLazy, { loading: getAllLightBoxLoading }] = useLazyQuery<
    getAllLightBoxData,
    getAllLightBoxVars
  >(GET_ALL_LIGHT_BOX, {
    variables: { paging },
    onCompleted(data) {
      setLightBoxs(data.getAllLightBox || []);
      setTotal(data.countAllLightBox || 0);
    },
  });
  const { setBreadcrumbText } = React.useContext(ControlContext)!;
  React.useEffect(() => {
    setBreadcrumbText(undefined);
  }, []);

  const handleClickName = (record: LightBox) => {
    const lightBoxDetailState: LightBoxDetailState = {
      lightBoxDetail: record,
    };
    navigate(`${lightBoxDetailState.lightBoxDetail.boxId}`, {
      state: lightBoxDetailState,
    });
  };
  const handleOnRow = (record: LightBox) => ({
    onClick: () => handleClickName(record),
  });
  const [searchBy, setSearchBy] = React.useState<number>(3);
  const handleChangeSearchBy = (value: number) => {
    setPaging(INITIAL_PAGING);
    setSearchBy(value);
  };
  const handleLightBoxInRange = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { coords } = position;
      getLightBoxInRangeLazy({
        variables: {
          paging,
          lat: coords.latitude,
          lng: coords.longitude,
          distance,
        },
      });
    });
  };
  const handleChangeDistance = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setDistance(Number(target.value) * 1000);
  };
  const handleChangeName = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setName(target.value);
    setPaging({
      ...paging,
      skip: 0,
    });
  };

  const getResult = () => {
    switch (searchBy) {
      case 1:
        getAllLightBoxLazy();
        break;
      case 2:
        handleLightBoxInRange();
        break;
      case 3:
        searchLightBoxWithFilterLazy();
        break;
      default:
        break;
    }
  };
  const tableData = (l: LightBox[]) => {
    return mapKey(l);
  };

  usePreventExceed(getResult, [distance, name, paging, searchBy]);
  return (
    <>
      <div className="flex gap-[2rem] items-baseline">
        <span>Tìm theo:</span>
        <Select
          style={{ width: 200 }}
          value={searchBy}
          onChange={handleChangeSearchBy}>
          <Option value={1}>Tất cả</Option>
          <Option value={2}>Tìm theo bán kính</Option>
          <Option value={3}>Tìm theo tên hoặc id</Option>
        </Select>
        {searchBy === 2 && (
          <Input
            step={0.01}
            type="number"
            style={{ width: 200 }}
            value={distance / 1000}
            onChange={handleChangeDistance}
            addonAfter="km"
          />
        )}
        {searchBy === 3 && (
          <Input
            style={{ width: 200 }}
            placeholder="Nhập tên hoặc id"
            onChange={handleChangeName}
          />
        )}
      </div>
      <Table
        ref={tableRef}
        className="flex-auto"
        scroll={{
          x: true,
          y: scrollHeight,
        }}
        loading={
          getAllLightBoxLoading ||
          searchLightBoxLoading ||
          getLightBoxInRangeLoading
        }
        pagination={false}
        columns={columns}
        dataSource={tableData(lightBoxs)}
        onRow={handleOnRow}
      />
      <PaginationElement />
    </>
  );
};

export default LightingTab;
