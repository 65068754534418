import { gql } from "@apollo/client";
import { AdBox } from "../types/adBox";
import { Paging } from "../types/common";

export type adBoxAllData = {
  adBoxAll: AdBox[];
};
export type adBoxAllVars = {
  paging?: Paging;
};
export const AD_BOX_ALL = gql`
  query AD_BOX_ALL($paging: Paging) {
    adBoxAll(paging: $paging) {
      id
      name
      lat
      lng
      adBoxId
      serialNumber
      province {
        name
      }
    }
  }
`;
export const AD_BOX_ALL_COUNT = gql`
  query AD_BOX_ALL_COUNT($paging: Paging) {
    adBoxAllCount(paging: $paging)
  }
`;

export type adBoxData = {
  adBox: AdBox;
};
export type adBoxVars = {
  id: number;
};
export const AD_BOX = gql`
  query AD_BOX($id: Int!) {
    adBox(id: $id) {
      id
    }
  }
`;

export const AD_BOX_FIND_BY_NAME = gql`
  query AD_BOX_FIND_BY_NAME($input: String!, $paging: Paging) {
    adBoxFindByName(input: $input, paging: $paging) {
      id
    }
  }
`;

export const AD_BOX_FIND_BY_CONDITION = gql`
  query AD_BOX_FIND_BY_CONDITION($input: AdBoxFilterInput!, $paging: Paging) {
    adBoxFindByCondition(input: $input, paging: $paging) {
      id
    }
  }
`;
export const AD_BOX_FIND_BY_GROUP = gql`
  query AD_BOX_FIND_BY_GROUP($groupId: Int!, $paging: Paging) {
    adBoxFindByGroup(groupId: $groupId, paging: $paging) {
      id
    }
  }
`;
export type getAdBoxInRangeData = {
  getAdBoxInRange: AdBox[];
};
export type getAdBoxInRangeVars = {
  lat: number;
  lng: number;
  distance: number;
  paging?: Paging;
};
export const GET_AD_BOX_IN_RANGE = gql`
  query AD_BOX_IN_RANGE(
    $lat: Float!
    $lng: Float!
    $distance: Float!
    $paging: Paging
  ) {
    getAdBoxInRange(
      lat: $lat
      lng: $lng
      distance: $distance
      paging: $paging
    ) {
      id
      adBoxId
      serialNumber
      lat
      lng
    }
  }
`;

export const CREATE_AD_BOX = gql`
  mutation CREATE_AD_BOX($input: AdBoxInput!) {
    createAdBox(input: $input) {
      id
    }
  }
`;

export const UPDATE_AD_BOX = gql`
  mutation UPDATE_AD_BOX($id: Int!, $input: AdBoxInput!) {
    updateAdBox(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_AD_BOX = gql`
  mutation DELETE_AD_BOX($id: Int!) {
    deleteAdBox(id: $id)
  }
`;

// type Query {
//   adBoxAll(paging: Paging): [AdBox]
//   adBoxAllCount: Int
//   adBox(id: Int!): AdBox
//   adBoxFindByName(input: String!, paging: Paging): [AdBox]
//   adBoxFindByCondition(input: AdBoxFilterInput!, paging: Paging): [AdBox]
//   adBoxFindByGroup(groupId: Int!, paging: Paging): [AdBox]
//   getAdBoxInRange(lat: Float!, lng: Float!, distance: Float!, paging: Paging): [AdBox]
// }

// type Mutation {
//   createAdBox(input: AdBoxInput!): AdBox
//   updateAdBox(id: Int!, input: AdBoxInput!): AdBox
//   deleteAdBox(id: Int!): Boolean
// }
