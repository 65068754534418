import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Label2 } from "../../../components/Text";
import { Neutral } from "../../../constants/colors";
import {
  AuthPayload,
  LoginUserInputs,
  useAuth,
} from "../../../context/AuthContext";
import AuthenLayout from "../components/AuthenLayout";

type LoginFormValues = {
  username: string;
  password: string;
};
const LoginForm: React.FC = () => {
  const [form] = Form.useForm();
  const auth = useAuth();
  const navigate = useNavigate();

  const loginRest = async (
    input: LoginUserInputs,
    option: Partial<{
      onSuccess: (result: AuthPayload) => void;
      onError: () => void;
      onFinish: () => void;
    }>
  ) => {
    const requestOpts = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(input),
    };
    try {
      const res = await fetch(
        "https://gateway-dev.citisys.vn/login",
        requestOpts
      );
      const resJson: AuthPayload = await res.json();
      const { access_token, userId } = resJson;
      if (access_token && userId) {
        option.onSuccess && option.onSuccess(resJson);
      } else {
        option.onError && option.onError();
      }
    } catch (error) {
      option.onError && option.onError();
    }
    option.onFinish && option.onFinish();
  };

  const [showError, setShowError] = React.useState<boolean>(false);
  const [disabledBtn, setDisabledBtn] = React.useState<boolean>(false);
  const onFinish = (values: LoginFormValues) => {
    setDisabledBtn(true);
    loginRest(values, {
      onError: () => {
        setShowError(true);
      },
      onSuccess: (result) => {
        const { isFirstLogin, access_token, userId, require2FA, phoneNumber } =
          result;
        if (access_token && userId) {
          auth?.setAuthState({
            ...auth.authState,
            accessToken: access_token,
            userId,
            require2FA,
            phoneNumber,
            isFirstLogin,
          });
          if (isFirstLogin || require2FA) {
            navigate("/login/2fa");
          } else {
            localStorage.setItem("CTS_ACCESS_TOKEN", access_token);
            localStorage.setItem("CTS_UID", userId);
            // implement sau web socket error
            navigate("/");
            window.location.reload();
          }
        }
      },
      onFinish: () => {
        setTimeout(() => {
          setDisabledBtn(false);
        }, 500);
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <AuthenLayout title="Đăng nhập vào ứng dụng CitiSYS">
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={() => {
          setShowError(false);
        }}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <div className="flex flex-col gap-[16px]">
          <Form.Item
            name="username"
            rules={[
              { required: true, message: "Vui lòng nhập tên người dùng!" },
            ]}>
            <Input
              style={{ borderRadius: 4 }}
              prefix={
                <UserOutlined
                  style={{
                    color: Neutral.L70,
                  }}
                />
              }
              size="large"
              placeholder="Nhập tên người dùng"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}>
            <Input.Password
              style={{ borderRadius: 4 }}
              prefix={
                <LockOutlined
                  style={{
                    color: Neutral.L70,
                  }}
                />
              }
              size="large"
              placeholder="Nhập mật khẩu"
            />
          </Form.Item>
          <div className="h-[40px]">
            {showError && (
              <Label2 className=" text-error">
                Đăng nhập không thành công
              </Label2>
            )}
          </div>
          <div className="mt-[82px] flex justify-center">
            <Button
              disabled={disabledBtn}
              style={{ borderRadius: 8, padding: "0 20px", lineHeight: "40px" }}
              htmlType="submit"
              size="large"
              type="primary">
              Đăng nhập
            </Button>
          </div>
        </div>
      </Form>
    </AuthenLayout>
  );
};

export default LoginForm;
