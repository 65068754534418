import { Pagination, PaginationProps } from "antd";
import * as React from "react";
import { Paging } from "../graphql/types/common";

export const INITIAL_PAGING: Paging = { count: 10, skip: 0 };
export function usePagination(total: number) {
  const [paging, setPaging] = React.useState<Paging>(INITIAL_PAGING);
  const itemRender: PaginationProps["itemRender"] = (
    page,
    type,
    originalElement
  ) => {
    const prevNextHandle = () => {
      const { count } = paging;
      setPaging({
        ...paging,
        skip: (page - 1) * Number(count),
      });
    };
    let aContent = "";
    switch (type) {
      case "next":
        aContent = "Tiếp theo";
        break;
      case "prev":
        aContent = "Trước";
        break;
      case "page":
        aContent = page.toString();
        break;
      default:
        break;
    }
    if (type === "next" || type === "prev" || type === "page")
      return <a onClick={prevNextHandle}>{aContent}</a>;
    return originalElement;
  };
  const handleChangePaging = (page: number, pageSize: number) => {
    setPaging({ ...paging, count: pageSize, skip: (page - 1) * pageSize });
  };
  const PaginationElement = React.useCallback(() => {
    return (
      <Pagination
        className=" text-right"
        current={Number(paging.skip) / Number(paging.count) + 1}
        pageSize={paging.count}
        total={total}
        itemRender={itemRender}
        showTotal={(t) => `Có ${t} kết quả`}
        showSizeChanger
        onChange={handleChangePaging}
      />
    );
  }, [paging, total]);

  return {
    PaginationElement,
    paging,
    setPaging,
  };
}
