import { useMutation } from "@apollo/client";
import { Modal, ModalProps } from "antd";
import moment from "moment";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { formatTime, getMinuteFromMoment } from "../..";
import { CTSNotification } from "../../../../../../../../components/CTSNotification";
import { Close, Plus, Trash } from "../../../../../../../../components/Icons";
import { Sub3 } from "../../../../../../../../components/Text";
import TwButton from "../../../../../../../../components/TwButton";
import { Neutral } from "../../../../../../../../constants/colors";
import {
  UpdateTimeSlotsLightBoxData,
  UpdateTimeSlotsLightBoxVars,
  UPDATE_TIME_SLOTS_LIGHT_BOX,
} from "../../../../../../../../graphql/schema/lightBox";
import {
  LightBoxDetailState,
  LightBoxTimeSlot,
  TimeSlotLightBoxInput,
} from "../../../../../../../../graphql/types/lightBox";
import OffTimeSelect from "../OffTimeSelect";
import OnTimeSelect from "../OnTimeSelect";
import PowerSelect from "../PowerSelect";
import "./edit-modal.scss";

const validateTimeSlot = (list: TimeSlotAjust[]): boolean => {
  if (list.length === 0) return false;
  for (let i = 0; i < list.length - 1; i++) {
    for (let j = i + 1; j < list.length; j++) {
      if (list[i].startTime === list[j].startTime) {
        return false;
      }
    }
  }
  return true;
};
const sortTimeSlot = (list: TimeSlotAjust[]): TimeSlotAjust[] => {
  const length = list.length;
  let sortedList = list.sort(
    (a, b) =>
      getMinuteFromMoment(moment(a.startTime, formatTime)) -
      getMinuteFromMoment(moment(b.startTime, formatTime))
  );
  sortedList = sortedList.map((item, key) => {
    if (key < length - 1) {
      return { ...item };
    }
    return item;
  });
  for (let i = 0; i < sortedList.length - 1; i++) {
    let a = sortedList[i];
    let b = sortedList[i + 1];
    if (
      getMinuteFromMoment(moment(a.endTime, formatTime)) >
      getMinuteFromMoment(moment(b.endTime, formatTime))
    ) {
      b.endTime = a.endTime;
      a.endTime = b.startTime;
    }
  }
  return sortedList;
};

const getStringFromTime = (value: moment.Moment | null): string => {
  let hour_ = "";
  let minute_ = "";
  if (Number(value?.hours()) < 10) {
    hour_ = `0${value?.hours()}`;
  } else {
    hour_ = `${value?.hours()}`;
  }
  if (Number(value?.minutes()) < 10) {
    minute_ = `0${value?.minutes()}`;
  } else {
    minute_ = `${value?.minutes()}`;
  }
  return `${hour_}:${minute_}`;
};

const compareTime = (time1: string, time2: string): 1 | -1 | 0 => {
  let hour1 = Number(time1.split(":")[0]);
  let min1 = Number(time1.split(":")[1]);
  let hour2 = Number(time2.split(":")[0]);
  let min2 = Number(time2.split(":")[1]);
  hour1 = hour1 < 12 ? hour1 + 24 : hour1;
  hour2 = hour2 < 12 ? hour2 + 24 : hour2;
  let time1F = hour1 * 60 + min1;
  let time2F = hour2 * 60 + min2;
  if (time1F > time2F) {
    return 1;
  }
  if (time1F < time2F) {
    return -1;
  }
  return 0;
};
interface IEditModalProps extends ModalProps {
  toggleVisible: () => void;
  onRefetch?: React.Dispatch<React.SetStateAction<LightBoxTimeSlot[]>>;
  data: LightBoxTimeSlot[];
}
export interface TimeSlotAjust extends TimeSlotLightBoxInput {
  selected?: boolean;
}
const EditModal: React.FunctionComponent<IEditModalProps> = ({
  toggleVisible,
  onRefetch,
  data,
  ...props
}) => {
  let [startTime, setStartTime] = React.useState<string>(data[0].startTime);
  let [endTime, setEndTime] = React.useState<string>(
    data[data.length - 1].endTime
  );
  const [enableTrashBtn, setEnableTrashBtn] = React.useState<boolean>(false);
  const { boxId } = useParams();
  let [timeSlotsAdjust, setTimeSlotsAdjust] = React.useState<TimeSlotAjust[]>(
    []
  );
  let [timeSlotsOriginal, setTimeSlotsOriginal] = React.useState<
    TimeSlotAjust[]
  >([]);
  const handleClose = () => {
    toggleVisible();
  };
  const handleAddNewTimeSlot = () => {
    const newTimeSlot: TimeSlotAjust = {
      startTime: "00:00",
      endTime: timeSlotsAdjust[timeSlotsAdjust.length - 1].endTime,
      power: 100,
    };
    setTimeSlotsAdjust([...timeSlotsAdjust, newTimeSlot]);
  };
  React.useEffect(() => {
    validateDeleteButton();
  }, [timeSlotsAdjust]);
  const validateDeleteButton = () => {
    let fl = false;
    timeSlotsAdjust.forEach((item) => {
      if (item.selected === true) {
        fl = true;
      }
    });
    setEnableTrashBtn(fl);
  };

  React.useEffect(() => {
    if (data) {
      const listLightBox: LightBoxTimeSlot[] = data;
      const adjustStamp: TimeSlotAjust[] = listLightBox.map(
        ({ startTime, endTime, power }) => {
          return {
            startTime,
            endTime,
            power: power || 0,
            selected: false,
          };
        }
      );
      setTimeSlotsOriginal(adjustStamp);
      setTimeSlotsAdjust(adjustStamp);
    }
  }, [data]);

  const handleChangeTime = (id: number, value: moment.Moment | null) => {
    let valueString = getStringFromTime(value);
    if (id === 0) {
      setStartTime(valueString);
    }
    const newData: TimeSlotAjust[] = timeSlotsAdjust.map((item, key) => {
      if (key === id) {
        return {
          ...item,
          startTime: valueString,
        };
      } else {
        return item;
      }
    });
    setTimeSlotsAdjust(sortTimeSlot(newData));
  };

  const handleChangePower = (id: number, value: string) => {
    const newData: TimeSlotAjust[] = timeSlotsAdjust.map((item, key) => {
      if (key === id) {
        return {
          ...item,
          power: Number(value),
        };
      } else {
        return item;
      }
    });
    setTimeSlotsAdjust(newData);
  };
  const handleDelete = () => {
    let newData = timeSlotsAdjust;
    const endTime = newData[newData.length - 1].endTime;
    newData = newData.filter((item) => item.selected !== true);
    newData[newData.length - 1].endTime = endTime;
    timeSlotsAdjust = newData;
    setTimeSlotsAdjust(sortTimeSlot(newData));
  };
  const [updateTimeSlotsLightBoxFunc, { loading }] = useMutation<
    UpdateTimeSlotsLightBoxData,
    UpdateTimeSlotsLightBoxVars
  >(UPDATE_TIME_SLOTS_LIGHT_BOX);

  const handleSubmit = async () => {
    if (validateSubmit()) {
      try {
        const updateTimeSlotResult = await updateTimeSlotsLightBoxFunc({
          variables: {
            boxId: boxId!,
            data: timeSlotsAdjust.map(({ startTime, endTime, power }) => ({
              startTime,
              endTime,
              power,
            })),
          },
        });

        const dataX: LightBoxTimeSlot[] =
          updateTimeSlotResult.data?.updateTimeSlotsLightBox || [];
        onRefetch && onRefetch(dataX);
        toggleVisible();
        CTSNotification("success", "Chỉnh sửa thời đoạn thành công!");
        setTimeSlotsOriginal(timeSlotsAdjust);
      } catch (error) {
        CTSNotification("error", "Chỉnh sửa thời đoạn không thành công!");
        setTimeSlotsAdjust(timeSlotsOriginal);
      }
    }
  };
  const validateSubmit = () => {
    for (let i = 0; i < timeSlotsAdjust.length; i++) {
      const element = timeSlotsAdjust[i];
      if (i === 0) {
        if (
          compareTime(startTime, "17:00") === -1 ||
          compareTime(endTime, "06:00") === 1
        ) {
          CTSNotification(
            "error",
            "Thời gian phải trong khoảng 17h00 đến 06h00"
          );
          return false;
        }
      } else if (
        compareTime(element.startTime, startTime) !== 1 ||
        compareTime(element.startTime, endTime) === 1
      ) {
        CTSNotification("error", "Vùng điều chỉnh không phù hợp");
        return false;
      }
      if (element.power < 0 || element.power > 100) {
        return false;
      }
    }
    return true;
  };
  const handleChangeEndTime = (endTime: moment.Moment | null) => {
    const valueString = endTime?.hours() + ":" + endTime?.minutes();
    setEndTime(valueString);
    const newData: TimeSlotAjust[] = timeSlotsAdjust.map((item, key) => {
      if (key === timeSlotsAdjust.length - 1) {
        return { ...item, endTime: valueString };
      } else {
        return item;
      }
    });
    setTimeSlotsAdjust(newData);
  };

  return (
    <Modal
      destroyOnClose
      width={696}
      className="power-modal"
      footer={false}
      closeIcon={<></>}
      style={{
        padding: 0,
      }}
      {...props}>
      <div className="p-[16px]">
        <ModalHeader>
          <Sub3 className="text-neutral-D90">Chỉnh sửa thời đoạn</Sub3>
          <ModalCloseButton onClick={handleClose}>
            <Close size="md" />
          </ModalCloseButton>
        </ModalHeader>
        <ModalDivider />
        {(() => {
          return (
            <ModalBody>
              <div className="flex gap-[16px]">
                <div className="flex flex-col gap-[10px]">
                  <PowerTitle>Thời gian bật </PowerTitle>
                  <OnTimeSelect
                    enableCheck={false}
                    index={0}
                    item={timeSlotsAdjust[0]}
                    callbackChangeTime={handleChangeTime}
                    callbackChangePower={handleChangePower}
                  />
                </div>
                <div className="flex flex-col gap-[10px]">
                  <PowerTitle>Thời gian tắt </PowerTitle>
                  <OffTimeSelect
                    value={moment(
                      timeSlotsAdjust[timeSlotsAdjust.length - 1]?.endTime,
                      formatTime
                    )}
                    onChange={handleChangeEndTime}
                  />
                </div>
              </div>
              <div className="flex gap-[8px] items-end h-[24px]">
                <PowerTitle>Điểm điều chỉnh</PowerTitle>
                {enableTrashBtn && (
                  <button className="w-[24px] h-[24px]" onClick={handleDelete}>
                    <Trash size="md" />
                  </button>
                )}
              </div>
              <PowerAdjustWrapper>
                {timeSlotsAdjust.map((item, key: number) => {
                  return (
                    <React.Fragment key={`PowerAdjustWrapper${key}`}>
                      {key !== 0 && (
                        <PowerSelect
                          callbackChangeTime={handleChangeTime}
                          callbackChangePower={handleChangePower}
                          callbackSelect={(value: any, index: number) => {
                            setTimeSlotsAdjust(
                              timeSlotsAdjust.map((item, key) => {
                                if (key === index) {
                                  return { ...item, selected: value };
                                } else {
                                  return item;
                                }
                              })
                            );
                          }}
                          item={item}
                          index={key}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
                {timeSlotsAdjust.length < 5 && (
                  <AddButton onClick={handleAddNewTimeSlot} />
                )}
              </PowerAdjustWrapper>
            </ModalBody>
          );
        })()}
        <ModalFooter>
          <TwButton variant="OUTLINED" disabled>
            Hủy
          </TwButton>
          <TwButton disabled={loading} onClick={handleSubmit}>
            {loading ? "Đang xử lí" : "Hoàn tất"}
          </TwButton>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default EditModal;

const PowerTitle: React.FC = ({ children }) => {
  return <Sub3 className="text-neutral-D50">{children}</Sub3>;
};

const ModalCloseButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ className = "", ...props }) => {
  return (
    <button
      className={`w-[40px] h-[40px] rounded flex justify-center transition duration-300 items-center hover:bg-neutral-L30 ${className}`}
      {...props}></button>
  );
};

const ModalHeader: React.FC = ({ children }) => {
  return (
    <div className="flex justify-between h-[32px] items-center">{children}</div>
  );
};
const ModalDivider: React.FC = () => (
  <div className="w-full border-b border-neutral-L50 my-[16px]" />
);

const ModalBody: React.FC = ({ children }) => {
  return <div className="flex flex-col gap-[10px]">{children}</div>;
};

const ModalFooter: React.FC = ({ children }) => {
  return (
    <div className="mt-[16px] flex justify-end gap-[8px] items-end">
      {children}
    </div>
  );
};

const AddButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  ...props
}) => {
  return (
    <button
      className="bg-neutral-L30 w-[40px] h-[40px] flex items-center justify-center rounded"
      {...props}>
      <Plus color={Neutral.D30} size="md" />
    </button>
  );
};

const PowerAdjustWrapper: React.FunctionComponent = ({ children }) => {
  return <div className="flex gap-[16px] flex-wrap">{children}</div>;
};
