import { notification } from 'antd';
type NotificationType = 'success' | 'info' | 'warning' | 'error';
export const CTSNotification = (type: NotificationType, description: string) => {
  const message = {
    success: "Thành công",
    info: "Thông báo",
    warning: "Cảnh báo",
    error: "Có lỗi xảy ra"
  }
  notification[type]({
    message: message[type],
    description,
  });
};

