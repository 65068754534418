import { gql } from "@apollo/client";
import { AdVideo } from "../types/adVideo";

export type getAdVideoData = {
  getAdVideo: AdVideo;
}
export type getAdVideoVars = { id: number; }
export const GET_AD_VIDEO = gql`
    query GET_AD_VIDEO($id: Int!) {
        getAdVideo(id: $id) {
        id
        customerId
        thumbnailUrl
        rawUrl
        convertedUrl
        filename
      }
    }
  `;

export type deleteAdVideosData = {
  deleteAdVideos: boolean;
}
export type deleteAdVideosVars = {
  ids: number[];
  customerId: number;
}
export const DELETE_AD_VIDEO = gql`
    mutation DELETE_AD_VIDEO($ids:[Int!]!, $customerId: Int!) {
        deleteAdVideos(ids:$ids, customerId: $customerId)
    }
  `;