import * as React from "react";
import { createContext, useContext, useState } from "react";

export interface LoginUserInputs {
  username: string;
  password: string;
}
export interface AuthPayload {
  error?: any;
  access_token: string;
  userId: string;
  isFirstLogin: boolean;
  require2FA: boolean;
  message: string;
  phoneNumber?: string;
}

export interface SetPhonePayload {
  addPhoneNumber: boolean;
}

export interface OTPPayload {
  updatePhoneNumber: boolean;
}

export interface ChangePasswordPayload {
  changePassword: boolean;
}

type ErrorResponse = {
  error: {
    title: string;
    message: string;
    userMessage: string;
  };
};
type LoginSuccessCallback = (data: AuthPayload) => void;
// type SetPhonenumberSuccessCallback = (data: SetPhonePayload) => void;
// type SendOTPSuccessCallback = (data: OTPPayload) => void;
// type ChangePasswordSuccessCallback = (data: ChangePasswordPayload) => void;
type ErrorCallback = (data: ErrorResponse) => void;

export const LoginRest = (
  input: LoginUserInputs,
  opts: Partial<{
    onSuccess: LoginSuccessCallback;
    onError: ErrorCallback;
  }>
): void => {
  const requestOpts = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(input),
  };

  fetch("https://gateway-dev.citisys.vn/login", requestOpts)
    .then((response) => {
      response
        .json()
        .then((data) => {
          const payload: AuthPayload = data as AuthPayload;
          if (opts.onSuccess && payload.access_token) {
            opts.onSuccess(payload);
          } else if (payload.access_token == null && opts.onError) {
            const error = data as ErrorResponse;
            opts.onError(error);
          } else {
            // Malformed response
            return;
          }
        })
        .catch((reason) => {
          if (opts.onError) {
            const error = reason as ErrorResponse;
            opts.onError(error);
          }
        });
    })
    .catch((reason) => {
      if (opts.onError) {
        const error = reason as ErrorResponse;
        opts.onError(error);
      }
    });
};

export const LogoutRest = (
  token: string,
  opts: Partial<{
    onSuccess: VoidFunction;
    onError: ErrorCallback;
  }>
): void => {
  const requestOpts = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
  };

  fetch("https://gateway.citisys.vn/logout", requestOpts)
    .then((response) => {
      response
        .json()
        .then((data) => {
          const error = data as ErrorResponse;
          if (error.error && error.error.title && opts.onError) {
            opts.onError(error);
          } else {
            opts.onSuccess && opts.onSuccess();
          }
        })
        .catch((reason) => {
          if (opts.onError) {
            const error = reason as ErrorResponse;
            opts.onError(error);
          }
        });
    })
    .catch((reason) => {
      if (opts.onError) {
        const error = reason as ErrorResponse;
        opts.onError(error);
      }
    });
};

interface IAuthContextState {
  accessToken?: string | null;
  refreshToken?: string;
  userName?: string;
  userId?: string | null;
  isFirstLogin?: boolean;
  phoneNumber?: string;
  require2FA?: boolean;
  isChangedPassword?: boolean;
}
interface IAuthContext {
  authState: IAuthContextState;
  setAuthState: (state: IAuthContextState) => void;
}
export const AuthContext = createContext<IAuthContext | undefined>(undefined);

const AuthProvider: React.FC = ({ children }) => {
  // const location = useLocation();
  // const navigation = useNavigate();
  const access_token = localStorage.getItem("CTS_ACCESS_TOKEN");
  const user_id = localStorage.getItem("CTS_UID");

  const [authState, setAuthState] = useState<IAuthContextState>({
    userId: user_id,
    accessToken: access_token,
    isFirstLogin: false,
  });
  // React.useEffect(() => {
  //   const { accessToken, userId } = authState;
  //   if (accessToken && userId) {
  //     navigation(location.pathname);
  //   }
  // }, [authState, location]);
  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export function useAuth(): IAuthContext | undefined {
  const authContext = useContext(AuthContext);
  return authContext;
}
