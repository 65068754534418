import { useLazyQuery, useMutation } from "@apollo/client";
import { Modal, Popconfirm } from "antd";
import { RcFile } from "antd/lib/upload";
import * as React from "react";
import ReactPlayer from "react-player";
import { CTSNotification } from "../../../../../../components/CTSNotification";
import {
  getUploadResultData,
  getUploadResultVars,
  GET_UPLOAD_RESULT,
  uploadAdVideoData,
  uploadAdVideoVars,
  UPLOAD_VIDEO
} from "../../../../../../graphql/schema/adCustomer";
import {
  getAdVideoData,
  getAdVideoVars,
  GET_AD_VIDEO
} from "../../../../../../graphql/schema/adVideo";
import { AdBox } from "../../../../../../graphql/types/adBox";
import { AdVideo } from "../../../../../../graphql/types/adVideo";

interface IThumbnailComponentProps {
  id?: number;
  thumbnailUrl?: string;
  addAdCallback: (video: AdVideo, clientId: string) => any;
  removeAdCallback: (id: number) => any;
  failCallback: (clientId: string) => void;
  rawUrl?: string;
  status?: "SUCCESSFUL" | "PROCESSING" | "FAILED";
  filename?: string;
  file?: RcFile;
  customerId?: number;
  batchId?: string;
  clientId?: string;
}
export type AdBoxLocationState = {
  ads: AdBox;
};
const ThumbnailComponent: React.FunctionComponent<IThumbnailComponentProps> = ({
  filename,
  status,
  file,
  id,
  rawUrl,
  customerId,
  thumbnailUrl,
  batchId,
  clientId,
  addAdCallback,
  failCallback,
  removeAdCallback,
  ...props
}) => {
  const [playing, setPlaying] = React.useState<boolean>(true);
  const [getUploadResultLazy] = useLazyQuery<
    getUploadResultData,
    getUploadResultVars
  >(GET_UPLOAD_RESULT);
  const [uploadAdVideoMutation] = useMutation<
    uploadAdVideoData,
    uploadAdVideoVars
  >(UPLOAD_VIDEO);
  const [getAdVideoLazy] = useLazyQuery<getAdVideoData, getAdVideoVars>(
    GET_AD_VIDEO
  );

  React.useEffect(() => {
    if (file) {
      uploadHandle(file);
    }
  }, [file]);
  const uploadHandle = async (f: RcFile) => {
    try {
      const uploadVideoResult = await uploadAdVideoMutation({
        variables: {
          customerId: customerId || -1,
          batchId: batchId!,
          clientId: clientId!,
          file: f,
        },
      });
      if (uploadVideoResult.data) {
        const rf = setInterval(async () => {
          const getResult = await getUploadResultLazy({
            variables: {
              batchId: batchId!,
              clientId: clientId!,
            },
          });
          if (getResult.data) {
            let status = getResult.data?.getUploadResult[0].status;
            if (status === "SUCCESSFUL") {
              clearInterval(rf);
              const getAdVideoRs = await getAdVideoLazy({
                variables: {
                  id: uploadVideoResult?.data?.uploadAdVideo.id || -1,
                },
              });
              if (getAdVideoRs.data) {
                const { getAdVideo } = getAdVideoRs.data;
                addAdCallback(getAdVideo, clientId!);
              }
            }
            if (status === "FAILED") {
              failCallback(clientId!);
              clearInterval(rf);
            }
          }
        }, 1000);
      }
    } catch (error) {}
  };
  const handleRemove = async () => {
    try {
      const rs = await removeAdCallback(id!);
      if (rs) {
        CTSNotification("success", "Cập nhật thành công");
      }
    } catch (error) {
      CTSNotification("warning", "Cập nhật thất bại");
    }
  };

  const showModal = () => {
    setVisible(true);
  };
  const pause = () => {
    setPlaying(false);
  };
  const hideModal = () => {
    setVisible(false);
  };
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <>
      {status === "PROCESSING" && (
        <div className="aspect-[256/448] bg-neutral-L70 flex justify-center items-center">
          Đang xử lý ...
        </div>
      )}
      {status === "SUCCESSFUL" && (
        <div className="aspect-[256/448] relative">
          <div className="absolute top-0 left-0 w-full z-20 flex justify-end">
            <Popconfirm
              title="Bạn có chắc chắn muốn xóa？"
              onConfirm={handleRemove}
              okText="Đồng ý"
              cancelText="Hủy">
              <a
                className="bg-[#ff1a1a] hover:bg-[#cc0000] hover:text-neutral-White font-7 text-neutral-White px-[16px] py-[8px] rounded-bl-[8px]"
                href="#">
                Xóa
              </a>
            </Popconfirm>
          </div>
          <img
            width={"100%"}
            height={"100%"}
            src={thumbnailUrl}
            onClick={showModal}
          />
        </div>
      )}
      {status === "FAILED" && (
        <div className="aspect-[256/448] flex justify-center items-center border border-error text-error text-[26px]">
          Upload không thành công
        </div>
      )}
      <Modal
        destroyOnClose
        width={300}
        title={filename}
        visible={visible}
        footer={null}
        onCancel={hideModal}
        afterClose={pause}
        bodyStyle={{ padding: 0 }}>
        <div className="w-[300px] aspect-[256/448]">
          <ReactPlayer
            width={"100%"}
            loop
            controls
            height={"100%"}
            playing={playing}
            url={rawUrl}
          />
        </div>
      </Modal>
    </>
  );
};

export default ThumbnailComponent;
