import { FetchResult } from "@apollo/client";
import * as React from "react";
import { Subscription } from "zen-observable-ts";
import { client } from "../graphql/auth";
import {
  GET_TRAFFIC_SET_TIMESLOTS_RESULT,
  SET_CURRENT_TIME_TRAFFIC_BOX,
  SET_TIME_SLOT_TRAFFIC_BOX,
  SUBSCRIBE_TRAFFIC_BOX_DATA
} from "../graphql/schema/trafficBox";

type TrafficStatus =
  | "COLOR_A"
  | "REMAINING_A"
  | "COLOR_B"
  | "REMAINING_B"
  | "CURRENT_TIME_SLOT"
  | "MODE"
  | "CURRENT_TIME";
type TrafficBoxInfo = {
  status: TrafficStatus;
  value: string;
};

type TrafficBoxSubscriptionData = {
  boxId: string;
  data: TrafficBoxInfo[];
};

type TimeSlotMode = "YELLOW_ONLY" | "AUTO";
type TimeSlot = {
  mode: TimeSlotMode;
  hour: number;
  minute: number;
  green1: number;
  green2: number;
  yellow1: number;
  yellow2: number;
  clearSessionTime: number;
};
type TrafficDataAsRecord = Partial<Record<TrafficStatus, string>>;
interface ProviderActions {
  subscribeTo: (boxId: string) => void;
  fetchNew: (boxId: string) => void;
  setTimeSlot: (
    boxId: string,
    timeSlot: TimeSlot
  ) => Promise<FetchResult<{ setTimeSlotTrafficBox: boolean }>>;
  setCurrentTime: (
    boxId: string
  ) => Promise<FetchResult<{ setCurrentTimeTrafficBox: boolean }>>;
  watchMessage: (
    boxId: string,
    messageId: string,
    options?: {
      onError: VoidFunction;
      onSuccess: VoidFunction;
      onResult: (result: string) => void;
    }
  ) => void;
}

interface DataContextState {
  actions: ProviderActions;
  data: {
    [boxId: string]: TrafficDataAsRecord;
  };
}

const DataContext = React.createContext<DataContextState | undefined>(
  undefined
);

interface SubscriptionList {
  [boxId: string]: Subscription;
}

type ErrorResponse = {
  error: {
    title: string;
    message: string;
    userMessage: string;
  };
};
type ErrorCallback = (data: ErrorResponse) => void;

interface ProviderProps {
  boxIds: string[];
  children?: React.ReactNode;
}
export default class TrafficCabProvider extends React.Component<
  ProviderProps,
  DataContextState
> {
  private subscriptionList: SubscriptionList = {};
  private init: boolean = false;

  constructor(props: ProviderProps) {
    super(props);
    this.setData = this.setData.bind(this);
    this.fetchNew = this.fetchNew.bind(this);
    this.handleData = this.handleData.bind(this);
    this.subscribeTo = this.subscribeTo.bind(this);
    this.setTimeSlot = this.setTimeSlot.bind(this);
    this.setCurrentTime = this.setCurrentTime.bind(this);
    this.watchMessage = this.watchMessage.bind(this);
    this.state = {
      data: {},
      actions: {
        watchMessage: this.watchMessage,
        setCurrentTime: this.setCurrentTime,
        setTimeSlot: this.setTimeSlot,
        fetchNew: this.fetchNew,
        subscribeTo: this.subscribeTo,
      },
    };
  }

  componentDidMount() {
    if (!this.init) {
      this.init = true;
      this.props.boxIds.forEach((boxId) => this.fetchNew(boxId));
    }
  }

  componentWillUnmount() {
    Object.keys(this.subscriptionList).forEach((boxId) => {
      this.subscriptionList[boxId].unsubscribe();
    });
  }

  fetchNew(boxId: string) {}

  setData(boxId: string, data: Partial<Record<TrafficStatus, string>>) {
    this.setState((prev) => ({
      data: {
        ...prev.data,
        [boxId]: {
          ...prev.data[boxId],
          ...data,
        },
      },
    }));
  }

  setTimeSlot(boxId: string, timeSlot: TimeSlot) {
    return client.mutate<
      { setTimeSlotTrafficBox: boolean },
      {
        boxId: string;
        timeSlot: TimeSlot;
      }
    >({
      mutation: SET_TIME_SLOT_TRAFFIC_BOX,
      variables: {
        boxId,
        timeSlot,
      },
    });
  }

  setCurrentTime(boxId: string) {
    return client.mutate<
      { setCurrentTimeTrafficBox: boolean },
      { boxId: string }
    >({
      mutation: SET_CURRENT_TIME_TRAFFIC_BOX,
    });
  }

  handleData(boxId: string, data: TrafficBoxInfo[]) {
    let newData: Partial<Record<TrafficStatus, string>> = {};
    data.forEach((datum) => {
      newData[datum.status] = datum.value;
    });
    this.setData(boxId, newData);
  }

  subscribeTo(boxId: string) {
    if (this.subscriptionList[boxId]) {
      this.subscriptionList[boxId].unsubscribe();
    }
    this.subscriptionList[boxId] = client
      .subscribe<
        { getTrafficBoxInfo: TrafficBoxSubscriptionData },
        { boxId: string }
      >({
        query: SUBSCRIBE_TRAFFIC_BOX_DATA,
        variables: {
          boxId: boxId,
        },
      })
      .subscribe(
        (data) => {
          if (data.data) {
            this.handleData(boxId, data.data.getTrafficBoxInfo.data);
          }
        },
        (err) => {}
      );
  }

  watchMessage(
    boxId: string,
    messageId: string,
    options?: {
      onError: VoidFunction;
      onSuccess: VoidFunction;
      onResult: (message: string) => void;
    }
  ) {
    this.callResult(boxId, messageId, options);
    // const myInterval = setInterval(() => {
    //   client
    //     .query({
    //       query: GET_TRAFFIC_SET_TIMESLOTS_RESULT,
    //       variables: {
    //         boxId: boxId,
    //         messageId: messageId,
    //       },
    //     })
    //     .then((rs) => {
    //       const status = rs.data.getTrafficSetTimeSlotsResult;
    //       options?.onResult(status);
    //       if (status === "SUCCESSFUL" || status === "ERROR") {
    //         clearInterval(myInterval);
    //       }
    //     });
    // }, 1000);
  }
  callResult(boxId: string, messageId: string, options: any) {
    const myInterval = setInterval(() => {
      client
        .query({
          query: GET_TRAFFIC_SET_TIMESLOTS_RESULT,
          variables: {
            boxId: boxId,
            messageId: messageId,
          },
          fetchPolicy: 'network-only',
        })
        .then((rs) => {
          const status = rs.data.getTrafficSetTimeSlotsResult;
          options?.onResult(status);
          if (status === "SUCCESSFUL" || status === "ERROR") {
            clearInterval(myInterval);
          }
        });
    }, 1000);
  }

  render() {
    return (
      <DataContext.Provider value={this.state}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

type RUseTrafficCab = {
  status: Partial<Record<TrafficStatus, string>>;
  setTimeSlot: (
    timeSlot: TimeSlot,
    options: Partial<{
      onSuccess: VoidFunction;
      onError: ErrorCallback;
    }>
  ) => void;
  setCurrentTime: (
    options: Partial<{
      onSuccess: VoidFunction;
      onError: ErrorCallback;
    }>
  ) => void;
  watchMessage: (
    messageId: string,
    options?: {
      onError: VoidFunction;
      onSuccess: VoidFunction;
      onResult: (result: string) => void;
    }
  ) => void;
};
export function useTrafficCab(boxId: string): RUseTrafficCab {
  const context = React.useContext(DataContext);

  React.useEffect(() => {
    if (context?.actions) {
      context.actions.subscribeTo(boxId);
    }
  }, [context?.actions, boxId]);

  const setCurrentTime = React.useCallback(
    (
      options: Partial<{
        onSuccess: VoidFunction;
        onError: ErrorCallback;
      }>
    ) => {
      if (context?.actions) {
        context.actions.setCurrentTime(boxId).then((data) => {
          if (data.data && data.data.setCurrentTimeTrafficBox) {
            if (options && options.onSuccess) {
              options.onSuccess();
            }
          } else {
            options &&
              options.onError &&
              options.onError({
                error: {
                  message: "Cannot set current time",
                  title: "Current time error",
                  userMessage: "Cannot set current time",
                },
              });
          }
        });
      }
    },
    [context?.actions, boxId]
  );
  const setTimeSlot = React.useCallback(
    (
      timeSlot: TimeSlot,
      options: Partial<{
        onSuccess: VoidFunction;
        onError: ErrorCallback;
      }>
    ) => {
      if (context?.actions) {
        context.actions.setTimeSlot(boxId, timeSlot).then(
          (data) => {
            if (options && options.onSuccess) {
              if (data.data && data.data.setTimeSlotTrafficBox) {
                options.onSuccess();
              } else {
                if (options.onError)
                  options.onError({
                    error: {
                      message: "Error setting timeslot",
                      title: "Error",
                      userMessage: "Error setting timeslot",
                    },
                  });
              }
            }
          },
          (error) => {
            if (options && options.onError) {
              options.onError(error);
            }
          }
        );
      }
    },
    [context?.actions, boxId]
  );
  const watchMessage = React.useCallback(
    (
      messageId: string,
      options?: {
        onError: VoidFunction;
        onSuccess: VoidFunction;
        onResult: (result: string) => void;
      }
    ) => {
      if (context?.actions) {
        context.actions.watchMessage(boxId, messageId, options);
      }
    },
    [context?.actions, boxId]
  );
  return {
    status: (context && context.data[boxId]) || {},
    setTimeSlot,
    setCurrentTime,
    watchMessage,
  };
}
