import { InputNumber } from "antd";
import * as React from "react";
import { Body3, Sub3 } from "../../../../../components/Text";

const TrafficTimeSlotParam: React.FC<{
  color: "GREEN" | "RED" | "YELLOW";
  title: string;
  count: number;
  editable?: boolean;
  onChange?: (v: number) => void;
}> = ({ color, title, count, editable = false, onChange }) => {
  return (
    <div className="flex flex-row h-[2.375rem] rounded-[0.5rem] border-[1px] border-neutral-L70">
      <div className="px-[1rem] my-auto flex flex-row items-center">
        <div
          className={`rounded-full ${color === "GREEN"
            ? "bg-success"
            : color === "RED"
              ? "bg-error"
              : "bg-[#E5B40E]"
            } w-[0.875rem] h-[0.875rem]`}
        ></div>
        <Sub3 className="ml-[0.25rem] w-[5.75rem]">{title}</Sub3>
      </div>
      <div className="my-auto flex flex-row gap-x-[0.25rem] pl-[0.75rem] pr-[1rem] border-l-[1px] border-l-neutral-L70">
        <div className="text-center w-[1.75rem] ">
          {editable ? (
            <InputNumber
              className="cts-timeslot-editable"
              controls={false}
              bordered={false}
              onChange={onChange}
              max={color !== "GREEN" ? 10 : 200}
              min={color !== "GREEN" ? 3 : 20}
              precision={0}
              defaultValue={count}
            />
          ) : (
            <Sub3>{count}</Sub3>
          )}
        </div>
        <Body3 className="my-auto">giây</Body3>
      </div>
    </div>
  );
};

export default TrafficTimeSlotParam;
