import * as React from "react";

export function usePreventExceed(func: Function, deps: any[]) {
  React.useEffect(() => {
    let isMounted = true;
    isMounted && func();
    return () => {
      isMounted = false;
    };
  }, deps);
}
