import { Input, InputProps } from 'antd';
import * as React from 'react';
import { Body2 } from '../../../../../../../components/Text';

interface ISettingInputProps extends InputProps {
    label?: string;
    unit?: string;
    isSubmited?: boolean;
  }
  const SettingInput: React.FC<ISettingInputProps> = ({ label = '', unit = '', isSubmited = true, ...props }) => {
    return (
      <div className=''>
        <div className='flex items-center justify-between gap-[16px]'>
          <Body2 className="text-neutral-D90">{label}</Body2>
          <div className={`border ${isSubmited ? ' border-neutral-L70' : 'border-warning'} rounded-sm flex overflow-hidden w-[130px]`}>
            <Input className='flex-auto text-center bg-neutral-White hover:bg-neutral-White focus:bg-neutral-White' size="large" bordered={false} {...props} />
            <div className="bg-neutral-L30 w-[43px] h-[40px] leading-[40px] text-center shrink-0 " >
              {unit}
            </div>
          </div>
        </div>
      </div>
    )
  }

export default SettingInput;
