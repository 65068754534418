import * as React from "react";
import { Outlet } from "react-router-dom";
import LeftPane from "./components/LeftPane";
import "./login.scss";

interface ILoginPageProps {}
const LoginPage: React.FunctionComponent<ILoginPageProps> = ({ children }) => {
  
  return (
    <div className="w-screen h-screen bg-neutral-D70 justify-between flex ">
      <LeftPane />
      <Outlet />
    </div>
  );
};

export default LoginPage;
