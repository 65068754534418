import * as React from 'react';
import { IIconProps } from './IIconProps';

const ArrowUpRight: React.FunctionComponent<IIconProps> = ({ size = 'sm', color = "currentColor" }) => {
    return <>
        {
            size === 'sm' && <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 12L12 4" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4 4H12V12" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
        {
            size === 'md' && <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 17L17 7" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 7H17V17" stroke={color} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
        {
            size === 'lg' && <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 23L23 9" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 9H23V23" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        }
    </>
};

export default ArrowUpRight;
