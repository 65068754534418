import { useLazyQuery } from "@apollo/client";
import * as React from "react";
import { ControlContext } from "../../../context/ControlContext";
import {
  getAdBoxInRangeData,
  getAdBoxInRangeVars,
  GET_AD_BOX_IN_RANGE,
} from "../../../graphql/schema/adBox";
import { AdBox } from "../../../graphql/types/adBox";
import { usePreventExceed } from "../../../hooks/usePreventExceed";
import AdsMarker from "./AdsTabDetail/components/AdsMarker";

const AdsTabMap: React.FC = () => {
  const { center, distanceMap } = React.useContext(ControlContext)!;
  const [adboxes, setAdboxes] = React.useState<AdBox[]>([]);
  const [getAdBoxInRangeLazy] = useLazyQuery<
    getAdBoxInRangeData,
    getAdBoxInRangeVars
  >(GET_AD_BOX_IN_RANGE, {
    variables: {
      distance: distanceMap,
      lat: center?.[0]!,
      lng: center?.[1]!,
    },
    onCompleted(data) {
      const { getAdBoxInRange } = data;
      setAdboxes(getAdBoxInRange || []);
    },
  });
  usePreventExceed(getAdBoxInRangeLazy, [center, distanceMap]);
  return (
    <>
      {adboxes.map((adbox, idx) => (
        <AdsMarker ads={adbox} key={`adsbox-${idx}`} />
      ))}
    </>
  );
};

export default AdsTabMap;
