import { gql } from "@apollo/client";
import { RcFile } from "antd/lib/upload";
import { AdCustomer } from "../types/adCustomer";
import { AdVideo } from "../types/adVideo";
import { Paging } from "../types/common";
import { UploadResult } from "../types/uploadResult";

export type adCustomerAllData = {
  adCustomerAll: AdCustomer[]
}
export type adCustomerAllVars = {
  paging: Paging
}
export const AD_CUSTOMER_ALL = gql`
  query AD_CUSTOMER_ALL($paging: Paging) {
    adCustomerAll(paging: $paging) {
      id
      name
    }
  }
`;
export const AD_CUSTOMER_ALL_COUNT = gql`
  query AD_CUSTOMER_ALL_COUNT {
    adCustomerAllCount
  }
`;

// { adCustomer: AdCustomer }, { id: number }
export type adCustomerData = {
  adCustomer: AdCustomer;
}
export type adCustomerVars = {
  id: number;
}

export const AD_CUSTOMER = gql`
  query AD_CUSTOMER($id: Int!) {
    adCustomer(id: $id) {
      id
      name
      videos {
        id
        thumbnailUrl
        # filename
        createdAt
        customerId
        filename
        # batchId
        # clientId
        rawUrl
        description
        createdAt
        updatedAt
      }
    }
  }
`;

export const AD_CUSTOMER_FIND_BY_NAME = gql`
  query AD_CUSTOMER_FIND_BY_NAME($input: String!, $paging: Paging) {
    adCustomerFindByName(input: $input, paging: $paging) {
      id
    }
  }
`;
export const AD_CUSTOMER_FIND_BY_GROUP = gql`
  query AD_CUSTOMER_FIND_BY_GROUP($input: String!, $paging: Paging) {
    adCustomerFindByGroup(groupId: $input, paging: $paging) {
      id
    }
  }
`;

export const CREATE_AD_CUSTOMER = gql`
  mutation CREATE_AD_CUSTOMER($input: AdCustomerInput!) {
    createAdCustomer(input: $input) {
      id
    }
  }
`;
export const UPDATE_AD_CUSTOMER = gql`
  mutation UPDATE_AD_CUSTOMER($id: Int!, $input: AdCustomerInput!) {
    createAdCustomer(id: $id, input: $input) {
      id
    }
  }
`;
export const DELETE_AD_CUSTOMER = gql`
  mutation DELETE_AD_CUSTOMER($id: Int!) {
    deleteAdCustomer(id: $id)
  }
`;

export type uploadAdVideoData = {
  uploadAdVideo: AdVideo;
}
export type uploadAdVideoVars = {
  customerId: number;
  batchId: string;
  clientId: string;
  file: RcFile;
}
export const UPLOAD_VIDEO = gql`
  mutation UPLOAD_VIDEO(
    $customerId: Int!
    $file: Upload!
    $batchId: String!
    $clientId: String!
  ) { uploadAdVideo( customerId: $customerId file: $file batchId: $batchId clientId: $clientId ) {
      id
      thumbnailUrl
      rawUrl
      # description
      # filename
    }
  }
`;

export type getUploadResultData = {
  getUploadResult: UploadResult[];
}
export type getUploadResultVars = {
  batchId: string;
  clientId: string
}
export const GET_UPLOAD_RESULT = gql`
  query GET_UPLOAD_RESULT($batchId: String!, $clientId: String) {
    getUploadResult(batchId: $batchId, clientId: $clientId) {
      id
      clientId
      batchId
      status
      errorMessage
      errorStacktrace
      createdAt
    }
  }
`;
