export type User = {
  id: string;
  phoneNumber?: string;
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  identityNumber?: string;
  identityReleaseDate?: string;
  firstPassword?: string;
  passportNumber?: string;
  isCheck2FA?: boolean;
  isActived?: boolean;
  isFirstLogin?: boolean;
  role?: any;
  roleId?: number;
  permissions: UserPermission[];
};

export type IUseQuery = {
  error?: any;
  data?: any;
  loading?: boolean;
};
export type UserPermission = {
  id: string;
  field: UserPermissionField;
  actions: UserAction[];
};
export enum UserPermissionField {
  LIGHT = "LIGHT",
  TRAFFIC = "TRAFFIC",
  CAMERA = "CAMERA",
  ADBOARD = "ADBOARD",
  TREE = "TREE",
  USER = "USER",
  DOCUMENT = "DOCUMENT",
}
export enum UserAction {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}
