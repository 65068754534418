import { gql } from "@apollo/client";
import { LightBoxSubcriptionData } from "../types/lightBox";

export type getLightBoxInfoSubscriptionData = {
  getLightBoxInfo: LightBoxSubcriptionData;
}
export type getLightBoxInfoSubscriptionVars = {
  boxId: string;
}
export const GET_LIGHT_BOX_INFO_SUBSCRIPTION = gql`
  subscription OnGetLightBoxInfo($boxId: String!) {
    getLightBoxInfo(boxId: $boxId) {
      boxId
      status
      value
      type
    }
  }
`;