import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

export type ErrorType = {
  errZB?: number;
  errZEN?: number;
  errPM?: number;
  isConnected?: number;
};

interface IDetailContext {
  error?: ErrorType;
  setError: Dispatch<SetStateAction<ErrorType>>;
}
export const DetailContext = createContext<IDetailContext | null>(null);
export const DetailProvider: React.FC = ({ children }) => {
  const [error, setError] = useState<ErrorType>({
    errZB: 1,
    errZEN: 1,
    errPM: 1,
    isConnected: 0,
  });
  const data: IDetailContext = {
    error,
    setError,
  };

  return (
    <DetailContext.Provider value={data}>{children}</DetailContext.Provider>
  );
};
