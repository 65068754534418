import "@fontsource/inter";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import { RouteObject, useRoutes } from "react-router-dom";
import "./App.less";
import CtsLayout from "./components/Layout";
import AuthProvider from "./context/AuthContext";
import { ControlProvider } from "./context/ControlContext";
import { DetailProvider } from "./context/LightBoxDetailContext";
import "./fonts/digital_number_font.css";
import ControlPage from "./pages/ControlPage";
import LoginPage from "./pages/LoginPage";
import AddPhoneNumberForm from "./pages/LoginPage/AddPhoneNumberForm";
import LoginForm from "./pages/LoginPage/LoginForm";
import OTPForm from "./pages/LoginPage/OTPForm";
import TwoFAForm from "./pages/LoginPage/TwoFAForm";

export default function App() {
  let routes: RouteObject[] = [
    {
      path: "/*",
      element: (
        <CtsLayout>
          <ControlProvider>
            <DetailProvider>
              <ControlPage />
            </DetailProvider>
          </ControlProvider>
        </CtsLayout>
      ),
    },
    {
      path: "/login",
      element: <LoginPage />,
      children: [
        {
          index: true,
          element: <LoginForm />,
        },
        {
          path: "/login/2fa",
          element: <TwoFAForm />,
          children: [
            {
              index: true,
              element: <AddPhoneNumberForm />,
            },
            {
              path: "/login/2fa/otp",
              element: <OTPForm />,
            },
          ],
        },
      ],
    },
  ];
  let element = useRoutes(routes);
  return <AuthProvider>{element}</AuthProvider>;
}
