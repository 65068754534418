import * as React from 'react';
import { IIconProps } from './IIconProps';

const Close: React.FunctionComponent<IIconProps> = ({ size = 'sm', color = "currentColor" }) => {
    return <>
        {
            size === 'sm' && <></>
        }
        {
            size === 'md' && <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18" stroke="#35363B" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6L18 18" stroke="#35363B" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>


        }
        {
            size === 'lg' && <></>
        }
    </>
};

export default Close;
