import * as React from "react";
import CameraIcon from "../../../../../components/Icons/CameraIcon.svg";
import { Marker, MarkerProps } from "react-leaflet";
import L, { LeafletMouseEvent } from "leaflet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Camera } from "../../../../../graphql/types/camera";

const cameraIconMaker = new L.Icon({
  iconUrl: CameraIcon,
  iconRetinaUrl: CameraIcon,
  iconAnchor: undefined,
  popupAnchor: undefined,
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(24, 24),
  className: "camera-icon",
});
interface CameraMarkerProps {
  camera: Camera;
}
function CameraMarker(props: CameraMarkerProps) {
  const { camera } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleContextMenu = (e: LeafletMouseEvent) => {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
  };

  const handleClick = (e: LeafletMouseEvent) => {
    e.originalEvent.stopPropagation();
    if (pathname.split("/").at(-1) === "camera") {
      navigate(camera.id.toString());
    }
  };
  return (
    <Marker
      position={[camera.lat, camera.lng]}
      icon={cameraIconMaker}
      eventHandlers={{
        click: handleClick,
        contextmenu: handleContextMenu,
      }}
    />
  );
}

export default CameraMarker;
