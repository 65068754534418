import { Button, ButtonProps } from 'antd';
import * as React from 'react';
import { Neutral } from '../../constants/colors';

interface IAntdButtonCustomProps extends ButtonProps {
}

const AntdButtonCustom: React.FunctionComponent<IAntdButtonCustomProps> = ({ size, children, ...props }) => {
    const styleBySize = React.useMemo(() => {
        switch (size) {
            case "middle":
                return { borderRadius: 8, padding: '0 20px', lineHeight: '40px', height: '40px', color: Neutral.White, fontWeight: 700, letterSpacing: '0.02em' }
            default:
                return {}
        }
    }, [size])

    return <Button style={{ ...styleBySize }} {...props}>
        {children}
    </Button>
};

export default AntdButtonCustom;
