import * as React from "react";
import CtsSider from "../widgets/Sider/CtsSider";

interface ICtsLayoutProps {}

const CtsLayout: React.FunctionComponent<ICtsLayoutProps> = ({
  children,
  ...props
}) => {
  return (
    <div className="flex">
      <CtsSider />
      <div className="flex-auto h-[100vh]">{children}</div>
    </div>
  );
};

export default CtsLayout;
