import { useQuery, useSubscription } from "@apollo/client";
import moment from "moment";
import * as React from "react";
import { useParams } from "react-router-dom";
import { CommonContext } from "../../../../context/CommonContext";
import { ControlContext } from "../../../../context/ControlContext";
import { DetailContext } from "../../../../context/LightBoxDetailContext";
import {
  getLightBoxData,
  GetLightBoxInfoData,
  GetLightBoxInfoVars,
  getLightBoxVars,
  GET_LIGHT_BOX,
  GET_LIGHT_BOX_INFO,
} from "../../../../graphql/schema/lightBox";
import {
  getLightBoxInfoSubscriptionData,
  getLightBoxInfoSubscriptionVars,
  GET_LIGHT_BOX_INFO_SUBSCRIPTION,
} from "../../../../graphql/schema/lightBoxSubscription";
import { LightBoxInfo } from "../../../../graphql/types/lightBox";
import LightBoxControl from "./LightBoxControl";
import LightBoxStatus from "./LightBoxStatus";
import { LightNumChartData } from "./LightBoxStatus/LightNumChart";

interface ILightBoxDetailProps {}
// export interface ILightBoxDetailState {
//   // lightBoxDetail: LightBox;
// }
interface StatusState {
  branch1?: LightBoxInfo;
  branch2?: LightBoxInfo;
  autoControlMode?: LightBoxInfo;
  doorOpening?: LightBoxInfo;
  baterry?: LightBoxInfo;
  numOfLight?: LightBoxInfo;
  errZB?: LightBoxInfo;
  errZEN?: LightBoxInfo;
  errPM?: LightBoxInfo;
  isConnected?: LightBoxInfo;
  totalLight?: LightBoxInfo;
  LightBoxInfo?: LightBoxInfo;
  connectedLight?: LightBoxInfo;
  errorLight?: LightBoxInfo;
  offLight?: LightBoxInfo;
  voltageV1?: LightBoxInfo;
  voltageV2?: LightBoxInfo;
  voltageV3?: LightBoxInfo;
  currentI1?: LightBoxInfo;
  currentI2?: LightBoxInfo;

  currentI3?: LightBoxInfo;
  highVoltage?: LightBoxInfo;
  lowVoltage?: LightBoxInfo;
  delayVoltage?: LightBoxInfo;
  highCurrent?: LightBoxInfo;
  lowCurrent?: LightBoxInfo;
  delayCurrent?: LightBoxInfo;
  noLoad?: LightBoxInfo;
  secPV?: LightBoxInfo;
  minPV?: LightBoxInfo;
  hourPV?: LightBoxInfo;
}

const LightBoxDetail: React.FunctionComponent<ILightBoxDetailProps> = ({
  ...props
}) => {
  const { setBreadcrumbText } = React.useContext(ControlContext)!;
  const { setPLCTime } = React.useContext(CommonContext)!;
  const [currentTime, setCurrentTime] = React.useState<moment.Moment>(moment());
  const [status, setStatus] = React.useState<StatusState>({});
  const [lightNumChartData, setLightNumChartData] = React.useState<
    LightNumChartData[]
  >([]);
  let { boxId } = useParams();
  const { error, setError } = React.useContext(DetailContext)!;
  let timer: any;

  const updateTime = () => {
    timer =
      !timer &&
      setInterval(() => {
        setCurrentTime((prev) => {
          return moment(prev).add("1", "seconds");
        });
      }, 1000);
  };
  useQuery<getLightBoxData, getLightBoxVars>(GET_LIGHT_BOX, {
    variables: { input: { boxId } },
    onCompleted(data) {
      setBreadcrumbText(data.getLightBox.name);
    },
  });
  React.useEffect(() => {
    updateTime();
    return () => {
      clearInterval(timer);
    };
  }, [currentTime, timer]);

  const setAllState = (lightBoxInfo: LightBoxInfo[]) => {
    setStatus({
      ...status,
      branch1: lightBoxInfo.find((item) => item.status === "branch1"),
      branch2: lightBoxInfo.find((item) => item.status === "branch2"),
      autoControlMode: lightBoxInfo.find(
        (item) => item.status === "autoControlMode"
      ),
      doorOpening: lightBoxInfo.find((item) => item.status === "doorOpening"),
      baterry: lightBoxInfo.find((item) => item.status === "baterry"),
      numOfLight: lightBoxInfo.find((item) => item.status === "totalLight"),
      hourPV: lightBoxInfo.find((item) => item.status === "hourPV"),
      minPV: lightBoxInfo.find((item) => item.status === "minPV"),
      secPV: lightBoxInfo.find((item) => item.status === "secPV"),
      // currentTime: moment(`${hourPV}:${minPV}:${secPV}`, "HH:mm:ss"),
    });
    setLightNumChartData([
      {
        label: "connectedLight",
        count:
          lightBoxInfo.find((item) => item.status === "connectedLight")
            ?.value || 0,
      },
      {
        label: "errorLight",
        count:
          lightBoxInfo.find((item) => item.status === "errorLight")?.value || 0,
      },
      {
        label: "offLight",
        count:
          lightBoxInfo.find((item) => item.status === "offLight")?.value || 0,
      },
    ]);
    setError({
      ...error,
      errZB: lightBoxInfo.find((item) => item.status === "errZB")?.value!,
      // ZB: Lỗi kết nối đến đèn(ZB)
      errZEN: lightBoxInfo.find((item) => item.status === "errZEN")?.value!,
      //ZEN: Lỗi kết nối với bộ điều khiển trung tâm (PLC)
      errPM: lightBoxInfo.find((item) => item.status === "errPM")?.value!,
      //PM: Lỗi kết nối đồng hồ đa năng(PM)
      isConnected: lightBoxInfo.find((item) => item.status === "isConnected")
        ?.value!,
      // isConnected: 0
    });
  };

  useQuery<GetLightBoxInfoData, GetLightBoxInfoVars>(GET_LIGHT_BOX_INFO, {
    variables: {
      boxId: boxId!,
    },
    onCompleted: (data) => {
      setAllState(data.getLightBoxInfo);
    },
    fetchPolicy: "network-only",
  });
  useSubscription<
    getLightBoxInfoSubscriptionData,
    getLightBoxInfoSubscriptionVars
  >(GET_LIGHT_BOX_INFO_SUBSCRIPTION, {
    variables: { boxId: boxId! },
    onSubscriptionData({ subscriptionData }) {
      if (subscriptionData?.data) {
        const { data } = subscriptionData;
        const { value, type } = data?.getLightBoxInfo!;
        const statusName = data?.getLightBoxInfo.status!;
        if (type === "ERROR") {
          setError && setError({ ...error, [statusName]: value });
        } else {
          setStatus({ ...status, [statusName]: data?.getLightBoxInfo! });
        }
      }
    },
  });

  React.useEffect(() => {
    if (!status.hourPV || !status.minPV || !status.secPV) return;
    let currentTime = moment(
      `${status.hourPV?.value}:${status.minPV?.value}:${status.secPV?.value}`,
      "HH:mm:ss"
    );
    if (status.hourPV!.value >= 18) {
      currentTime = moment(currentTime).subtract("1", "days");
    }
    setPLCTime(currentTime);
    setCurrentTime(currentTime);
  }, [status.hourPV, status.minPV, status.secPV]);

  return (
    <div className="flex-auto mx-[-1rem] px-[1rem] flex flex-col overflow-hidden">
      {error?.isConnected !== 0 ? (
        <>
          <LightBoxStatus
            status={status}
            lightNumChartData={lightNumChartData || []}
          />
          {error?.errZEN ? (
            <></>
          ) : (
            <>
              {currentTime.isValid() ? (
                <div className="flex gap-[0.5rem] pt-[1rem] items-center">
                  <span className="">Giờ PLC:</span>
                  <span className=" text-blue-Secondary font-8">
                    {currentTime.format("HH:mm:ss")}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          <LightBoxControl />
        </>
      ) : (
        <div className="flex-auto flex justify-center items-center">
          <p className="text-center text-[24px] text-error">
            Chưa kết nối tủ đèn
          </p>
        </div>
      )}
    </div>
  );
};

export default LightBoxDetail;
