import * as React from "react";
import Logo from "../../../components/Icons/Logo";

const LeftPane: React.FC = () => {
  return (
    <div className="w-[804px] h-full pt-[4.5rem] pl-[62px] bg-neutral-D70">
      <Logo />
    </div>
  );
};

export default LeftPane;
